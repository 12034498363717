import React, { useEffect, useState } from "react";
import { Button } from "antd";
// @ts-ignore
import { DigitalFlop } from "@jiaminghi/data-view-react";

function DigitalFlopComponent(props: DigitalFlopComponentProps) {
  const [curCount, setCount] = useState<any>([0]);
  const waitTime = props.wait || 500;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCount(props.number);
    }, waitTime);
    return () => clearTimeout(timeout);
  }, [props.number, waitTime]);

  const {
    content,
    toFixed,
    textAlign,
    rowGap,
    style,
    animationCurve,
    animationFrame,
  } = props;
  const config = {
    number: curCount,
    content,
    toFixed,
    textAlign,
    rowGap,
    style,
    animationCurve,
    animationFrame,
  };
  return (
    <DigitalFlop config={config} style={{ width: "100%px", height: "auto" }} />
  );
}

export interface DigitalFlopComponentProps {
  number: number;
  content: string;
  toFixed: number;
  textAlign: string;
  rowGap: number;
  style: any;
  animationCurve: string;
  animationFrame: number;
  wait: number;
}

export default DigitalFlopComponent;
