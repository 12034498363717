import React from "react";
import { Checkbox, Classes } from "@blueprintjs/core";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { Colors } from "constants/Colors";
import {
  LabelPosition,
  LABEL_MARGIN_OLD_SELECT,
  SELECT_DEFAULT_HEIGHT,
  HeightSizePx,
  LABEL_DEFAULT_GAP,
} from "components/constants";
import { CommonSelectFilterStyle } from "widgets/MultiSelectWidgetV2/component/index.styled";
import {
  Icon,
  labelLayoutStyles,
  LABEL_CONTAINER_CLASS,
  multiSelectInputContainerStyles,
} from "@design-system/widgets-old";
import { lightenColor } from "widgets/WidgetUtils";
import CheckIcon from "assets/icons/widget/checkbox/check-icon.svg";
import _get from "lodash/get";

export const StyledIcon = styled(Icon)<{ expanded: boolean }>`
  transform: rotate(${({ expanded }) => (expanded ? 0 : 270)}deg);

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const menuItemSelectedIcon = (props: { isSelected: boolean }) => {
  return <StyledCheckbox checked={props.isSelected} />;
};

const rcSelectDropdownSlideUpIn = keyframes`
	0% {
		opacity: 0;
		transform-origin: 0% 0%;
	}
	100% {
		opacity: 1;
		transform-origin: 0% 0%;
	}
`;

const rcSelectDropdownSlideUpOut = keyframes`
	0% {
		opacity: 1;
		transform-origin: 0% 0%;
	}
100% {
		opacity: 0;
		transform-origin: 0% 0%;
	}
`;

export const TreeSelectContainer = styled.div<{
  compactMode: boolean;
  allowClear: boolean;
  isValid: boolean;
  labelPosition?: LabelPosition;
  borderRadius: string;
  boxShadow?: string;
  accentColor: string;
}>`
  ${labelLayoutStyles}

  /**
    When the label is on the left it is not center aligned
    here set height to auto and not 100% because the input
    has fixed height and stretch the container.
  */
  ${({ labelPosition }) => {
    if (labelPosition === LabelPosition.Left) {
      return `
      height: auto !important;
      align-items: stretch;
      `;
    }
  }}

  & .${LABEL_CONTAINER_CLASS} {
    label {
      ${({ labelPosition }) => {
        if (!labelPosition) {
          return `margin-bottom: ${LABEL_MARGIN_OLD_SELECT}`;
        }
      }};
    }
  }
  .ant-select-clear-icon {
    // display: none;
  }
  .ant-select-clear {
    width: 26px !important;
    height: 10px !important;
    margin-top: -9.5px !important;
    font-size: 17px !important;
    margin-right: 10px;
  }
  .ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
  }
  .ant-select-selection-item-content {
    // font-weight: 600;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &&&.${Classes.CHECKBOX}.${Classes.CONTROL} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    padding-bottom: 0;
    color: ${Colors.GREY_8};
    display: flex;
    align-items: center;
    &:hover {
      background: ${Colors.GREEN_SOLID_LIGHT_HOVER};
      color: ${Colors.GREY_9};
    }
  }
`;

export const inputIcon = (): JSX.Element => (
  <svg data-icon="chevron-down" height="16" viewBox="0 0 16 16" width="16">
    <desc>chevron-down</desc>
    <path
      d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z"
      fillRule="evenodd"
    />
  </svg>
);

export const InputContainer = styled.div<{
  compactMode: boolean;
  labelPosition?: LabelPosition;
  boxHeight?: string;
}>`
  ${multiSelectInputContainerStyles}
  ${({ boxHeight = "Middle", labelPosition }) =>
    labelPosition &&
    `height: ${_get(HeightSizePx, [boxHeight], SELECT_DEFAULT_HEIGHT)}`};
`;
