import type { ChangeEvent, ReactNode } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { TreeSelectProps as SelectProps } from "rc-tree-select";
import {
  TreeSelectContainer,
  StyledIcon,
  InputContainer,
} from "./index.styled";
import "rc-tree-select/assets/index.less";
import type { DefaultValueType } from "rc-tree-select/lib/interface";
import type { TreeNodeProps } from "rc-tree-select/lib/TreeNode";
import type { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";
import type { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";
import styled from "styled-components";
import type { RenderMode, TextSize } from "constants/WidgetConstants";
import type { Alignment } from "@blueprintjs/core";
import { Button, Classes, InputGroup } from "@blueprintjs/core";
import { labelMargin, WidgetContainerDiff } from "widgets/WidgetUtils";
import { Icon } from "@design-system/widgets-old";
import { Colors } from "constants/Colors";
import type { LabelPosition } from "components/constants";
import useDropdown from "widgets/useDropdown";
import LabelWithTooltip from "widgets/components/LabelWithTooltip";
import { ConfigProvider, TreeSelect } from "antd";
import { isEmpty } from "lodash";
import {
  CloseCircleFilled,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

export interface TreeSelectProps
  extends Required<
    Pick<
      SelectProps,
      "disabled" | "placeholder" | "loading" | "dropdownStyle" | "allowClear"
    >
  > {
  value?: DefaultValueType;
  onChange: (value?: DefaultValueType, labelList?: ReactNode[]) => void;
  onDropdownOpen?: () => void;
  onDropdownClose?: () => void;
  expandAll: boolean;
  mode: CheckedStrategy;
  labelText: string;
  labelAlignment?: Alignment;
  labelPosition?: LabelPosition;
  labelWidth?: number;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  labelTooltip?: string;
  compactMode: boolean;
  dropDownWidth: number;
  width: number;
  isDynamicHeightEnabled?: boolean;
  isValid: boolean;
  borderRadius: string;
  boxShadow?: string;
  boxHeight?: "small" | "middle" | "large" | undefined;
  accentColor: string;
  widgetId: string;
  filterText?: string;
  isFilterable: boolean;
  renderMode?: RenderMode;
  options?: DefaultOptionType[];
  isRequired?: boolean;
  showColon?: boolean;
  onDropdownVisibleChange?: (val: boolean) => void;
}

export const NoDataFoundContainer = styled.div`
  text-align: center;
`;

const getSvg = (expanded: boolean) => (
  <i
    style={{
      cursor: "pointer",
      backgroundColor: "transparent",
      display: "inline-flex",
      width: "14px",
      height: "100%",
    }}
  >
    <StyledIcon
      className="switcher-icon"
      expanded={expanded}
      fillColor={Colors.GREY_10}
      name="dropdown"
    />
  </i>
);

const { SHOW_ALL } = TreeSelect;

function MultiTreeSelectComponent({
  accentColor,
  allowClear,
  borderRadius,
  boxHeight,
  boxShadow,
  compactMode,
  disabled,
  dropdownStyle,
  dropDownWidth,
  expandAll,
  filterText,
  isDynamicHeightEnabled,
  isFilterable,
  isRequired,
  isValid,
  labelAlignment,
  labelPosition,
  labelStyle,
  labelText,
  labelTextColor,
  labelTextSize,
  labelTooltip,
  labelWidth,
  loading,
  mode,
  onChange,
  onDropdownClose,
  onDropdownOpen,
  options,
  placeholder,
  renderMode,
  showColon,
  value,
  widgetId,
  width,
}: TreeSelectProps): JSX.Element {
  const [keys, setKeys] = useState<any>([]);
  const [filter, setFilter] = useState(filterText ?? "");

  const _menu = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [memoDropDownWidth, setMemoDropDownWidth] = useState(0);

  const { BackDrop, getPopupContainer, isOpen, onKeyDown, onOpen, selectRef } =
    useDropdown({
      inputRef,
      renderMode,
      onDropdownClose,
      onDropdownOpen,
    });

  const extractValues = (data, result) => {
    data.forEach((item) => {
      if (item.value) {
        result.push(item.value);
      }
      if (item.children) {
        extractValues(item.children, result);
      }
    });
  };

  const extractValuesInversion = (data, result) => {
    data.forEach((item) => {
      if (!item.children) {
        result.push(item.value);
      }
    });
  };

  let resultArray: any[] = [];

  // treeDefaultExpandAll is uncontrolled after first render,
  // using this to force render to respond to changes in expandAll
  useEffect(() => {
    if (expandAll) {
      resultArray = [];
      extractValues(options, resultArray);
      setKeys(resultArray);
    } else {
      resultArray = [];
      extractValuesInversion(options, resultArray);
      setKeys(resultArray);
    }
  }, [expandAll]);

  const onQueryChange = useCallback((value: string) => {
    setFilter(value);
  }, []);

  useEffect(() => {
    const parentWidth = width - WidgetContainerDiff;
    if (compactMode && labelRef.current) {
      const labelWidth = labelRef.current.getBoundingClientRect().width;
      const widthDiff = parentWidth - labelWidth - labelMargin;
      setMemoDropDownWidth(
        widthDiff > dropDownWidth ? widthDiff : dropDownWidth,
      );
      return;
    }
    setMemoDropDownWidth(
      parentWidth > dropDownWidth ? parentWidth : dropDownWidth,
    );
  }, [compactMode, dropDownWidth, width, labelText]);

  const onClear = useCallback(() => onChange([], []), []);
  const onDropdownVisibleChange = (open: boolean) => {
    onOpen(open);
    // clear the search input on closing the widget
    setFilter("");
  };

  const onTreeExpand = (expandedKeys: any) => {
    setKeys(expandedKeys);
  };
  return (
    <TreeSelectContainer
      accentColor={accentColor}
      allowClear={allowClear}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      compactMode={compactMode}
      isValid={isValid}
      labelPosition={labelPosition}
      ref={_menu as React.RefObject<HTMLDivElement>}
    >
      {labelText && (
        <LabelWithTooltip
          alignment={labelAlignment}
          className={`multitree-select-label`}
          color={labelTextColor}
          compact={compactMode}
          cyHelpTextClassName="multitree-select-tooltip"
          disabled={disabled}
          fontSize={labelTextSize}
          fontStyle={labelStyle}
          helpText={labelTooltip}
          isDynamicHeightEnabled={isDynamicHeightEnabled}
          isRequired={isRequired}
          loading={loading}
          position={labelPosition}
          ref={labelRef}
          showColon={showColon}
          text={labelText}
          width={labelWidth}
        />
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: accentColor,
            borderRadius,
          },
        }}
      >
        <TreeSelect
          allowClear={allowClear}
          style={{ width: "100%" }}
          value={value}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          maxTagTextLength={3}
          status={!isValid ? "error" : ""}
          size={boxHeight}
          treeData={options}
          disabled={disabled}
          placeholder={placeholder}
          treeCheckable={true}
          treeDefaultExpandAll={expandAll}
          showCheckedStrategy={SHOW_ALL}
          onChange={onChange}
          onTreeExpand={onTreeExpand}
          treeExpandedKeys={keys}
          onDropdownVisibleChange={onDropdownVisibleChange}
          showArrow={true}
          showSearch={isFilterable || false}
          treeNodeFilterProp={"label"}
          filterTreeNode={(input: any, treeNode: any) => {
            return treeNode.label.includes(input);
          }}
          inputIcon={
            isFilterable && isEmpty(value) ? (
              <SearchOutlined className="ant-select-suffix" />
            ) : (
              <DownOutlined className="ant-select-suffix" />
            )
          }
          // clearIcon={<CloseCircleFilled />}
          // removeIcon={<CloseCircleFilled className="anticon anticon-close-circle" />}
          clearIcon={
            !isEmpty(value) && (
              <CloseCircleFilled className="anticon anticon-close-circle" />
            )
          }
        />
      </ConfigProvider>
    </TreeSelectContainer>
  );
}

export default MultiTreeSelectComponent;
