import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventData, EventTypeEnum, testCode } from "../constants";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

function sendMessage(iframe: HTMLIFrameElement | null, data: any) {
  iframe?.contentWindow?.postMessage(data, "*");
}

function CustomComponentComponent(props: CustomComponentComponentProps) {
  const { code, model, onExcuteQueryAction, onRunJS, widgetId } = props;
  const hostIdRef = useRef(widgetId);
  const { onModelChange } = props;
  // const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const modelRef = useRef<any>(model);
  const reloadFlagRef = useRef(false);

  const methodsRef = useRef({
    runQuery: async (data: any) => {
      onExcuteQueryAction(data);
    },

    runJS: async (data: any) => {
      onRunJS(data);
    },

    getModel: async (data: any) => {
      return modelRef.current;
    },

    // 调用更新model
    updateModel: async (data: any) => {
      modelRef.current = {
        ...modelRef.current,
        ...data,
      };
      onModelChange(modelRef.current);
      return modelRef.current;
    },

    modelUpdate: async (data: any) => {
      modelRef.current = {
        ...modelRef.current,
        ...data,
      };
      onModelChange(modelRef.current);
      return modelRef.current;
    },
  });

  // model update
  useEffect(() => {
    sendMessage(iframeRef.current, {
      type: EventTypeEnum.Data,
      payload: {
        props: model,
      },
    });
    modelRef.current = model;
  }, [model]);

  useEffect(() => {
    sendMessage(iframeRef.current, {
      type: EventTypeEnum.Data,
      payload: {
        props: {},
      },
    });
  }, []);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }
    const iframe = iframeRef.current;
    const handleMessage = (event: MessageEvent<EventData>) => {
      const { type, payload, componentId } = event.data;
      if (!type || !payload || !componentId) {
        return;
      }
      if (componentId !== hostIdRef.current) {
        return;
      }
      const { method, data, id } = payload;
      if (type === EventTypeEnum.Invoke) {
        const fn = methodsRef.current[method];
        if (!fn || typeof fn !== "function") {
          // TODO: response error
          return;
        }
        fn(data).then((res: any) => {
          // 回调处理@desperated
          // sendMessage(iframe, {
          //   type: EventTypeEnum.Invoke,
          //   payload: {
          //     id: componentId,
          //     method,
          //     response: res,
          //   },
          // });
        });
      }
    };

    const handleIFrameLoad = () => {
      sendMessage(iframe, {
        type: EventTypeEnum.Init,
        payload: {
          componentId: hostIdRef.current,
          iframeCode: `<script crossorigin src="/custom-component/react@18/react.development.js"></script>
          <script crossorigin src="/custom-component/react@18/react-dom.development.js"></script>
          
          ${code}`,
          props: model
        },
      });
    };
    window.addEventListener("message", handleMessage);
    iframe.addEventListener("load", handleIFrameLoad);

    const src = iframe.getAttribute("src");
    if (src && reloadFlagRef) {
      reloadFlagRef.current = false;
      const url = new URL("?_t=" + Date.now(), src);
      url.searchParams.set("v", "3.70.0");
      url.searchParams.set("origin", location.origin)
      iframe.setAttribute("src", url.toString());
    }

    return () => {
      reloadFlagRef.current = true;
      window.removeEventListener("message", handleMessage);
      iframe.removeEventListener("load", handleIFrameLoad);
    };
  }, [code]);

  return (
    <Wrapper>
      <iframe
        ref={iframeRef}
        title="custom-comp"
        src={window.location.origin + "/custom-component"}
        sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </Wrapper>
  );
}

export interface CustomComponentComponentProps {
  code: string;
  model: any;
  onModelChange: (v: any) => void;
  onExcuteQueryAction: (v: any) => void;
  onRunJS: (v: any) => void;
  widgetId: string;
}

export default CustomComponentComponent;
