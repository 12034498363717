import React from "react";
import type { RJSFSchema, RegistryWidgetsType } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { pick } from "lodash";

import type { BoxShadow } from "components/designSystems/appsmith/WidgetStyleContainer";
import WidgetStyleContainer from "components/designSystems/appsmith/WidgetStyleContainer";
import { isSelectScreenTheme } from "selectors/appThemingSelectors";
import type { Color } from "constants/Colors";
import { Colors } from "constants/Colors";
import { BaseButton } from "widgets/ButtonWidget/component";
import type { ButtonStyleProps } from "widgets/ButtonWidget/component";
import { FORM_PADDING_Y, FORM_PADDING_X } from "./styleConstants";
import { FormContextProvider } from "../FormContext";
import CustomUpDownWidget from "./customWidget/customUpDownWidget";

interface StyledContainerProps {
  backgroundColor?: string;
  isScreen?: boolean;
}

const StyledContainer = styled(WidgetStyleContainer) <StyledContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background: ${({ backgroundColor, isScreen }) =>
    isScreen ? `var(--wds-screen-color-bg)` : backgroundColor || "#fff"};
  overflow-y: auto;
  color: ${({ isScreen }) =>
    isScreen ? `var(--wds-screen-color-text)` : `var(--wds-color-text)`};
  
  div.ant-col.ant-col-24.ant-form-item-label>label[for=root__title] {
      font-size: 20px;
      font-weight: 700
  }

  .form-group>.ant-form-item {
      margin-bottom: 5px
  }

  div.ant-col.ant-col-24.ant-form-item-label {
      padding: 0
  }

  .ads-v2-input__input-section-input[name=verificationCode] {
      height: 48px
  }

  div.react-datepicker-wrapper {
      display: block
  }

`;

const BUTTON_WIDTH = 110;
const FOOTER_DEFAULT_BG_COLOR = "#fff";
const FOOTER_PADDING_TOP = FORM_PADDING_Y;
const FOOTER_SCROLL_ACTIVE_CLASS_NAME = "scroll-active";

const StyledFormFooter = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ backgroundColor, isScreen }) =>
    isScreen
      ? "var(--wds-screen-color-bg)"
      : backgroundColor || FOOTER_DEFAULT_BG_COLOR};
  padding: ${FORM_PADDING_Y}px ${FORM_PADDING_X}px;
  padding-top: ${FOOTER_PADDING_TOP}px;
  width: 100%;
  position: sticky;
  bottom: 0;

  &.${FOOTER_SCROLL_ACTIVE_CLASS_NAME} {
    box-shadow: 0px -10px 10px -10px ${Colors.GREY_3};
    border-top: 1px solid ${Colors.GREY_3};
  }

  & > button,
  & > div {
    position: absolute;
    top: 0;
    right: 11px;
    height: 32px;
    width: ${BUTTON_WIDTH}px;
  }
`;

const widgets: RegistryWidgetsType = {
  UpDownWidget: CustomUpDownWidget,
};

function JsonSchemaFormComponent(props: JsonSchemaFormComponentProps) {
  const {
    backgroundColor,
    formData,
    formSchema,
    formUI,
    onSubmit,
    showSubmit,
    submitButtonLabel,
    submitButtonStyles,
    updateFormData,
    updateWidgetMetaProperty,
    updateWidgetProperty,
    ...rest
  } = props;
  const isScreen = useSelector(isSelectScreenTheme);
  const styleProps = pick(rest, [
    "borderColor",
    "borderWidth",
    "borderRadius",
    "boxShadow",
    "boxShadowColor",
    "widgetId",
  ]);

  function transformErrors(errors: any) {
    return errors.map((error: any) => {
      switch (error.name) {
        case "required":
          error.message = "必填项";
          break;
        case "type":
          error.message = `需要${error.params.type}类型`;
          break;
        case "minLength":
          error.message = `长度不能低于${error.params.limit}`;
          break;
        case "maxLength":
          error.message = `长度不能超过${error.params.limit}`;
          break;
        case "min":
          error.message = `值不能小于${error.params.limit}`;
          break;
        case "max":
          error.message = `值不能大于${error.params.limit}`;
          break;
        case "pattern":
          error.message = `格式不正确`;
          break;
        default:
          error.message = "未知错误";
      }
      return error;
    });
  }

  return (
    <FormContextProvider
      updateFormData={updateFormData}
      updateWidgetMetaProperty={updateWidgetMetaProperty}
      updateWidgetProperty={updateWidgetProperty}
    >
      <StyledContainer
        backgroundColor={backgroundColor}
        isScreen={isScreen}
        {...styleProps}
      >
        <Form
          formData={formData}
          onChange={({ formData, schema, uiSchema }) =>
            updateFormData(formData, schema, uiSchema)
          }
          schema={formSchema}
          showErrorList={false}
          transformErrors={transformErrors}
          uiSchema={formUI}
          validator={validator}
          widgets={widgets}
        >
          <StyledFormFooter
            backgroundColor={backgroundColor}
            className="t--jsonform-footer"
            isScreen={isScreen}
          >
            {showSubmit && (
              <BaseButton
                {...submitButtonStyles}
                NotRenderAfter
                // loading={isSubmitting}
                onClick={onSubmit}
                text={submitButtonLabel}
                type="submit"
              />
            )}
          </StyledFormFooter>
        </Form>
      </StyledContainer>
    </FormContextProvider>
  );
}

export interface JsonSchemaFormComponentProps {
  widgetId: string;
  formSchema: RJSFSchema;
  formUI: any;
  formData?: Record<string, unknown>;
  backgroundColor?: string;
  borderColor?: Color;
  borderRadius?: number;
  borderWidth?: number;
  boxShadow?: BoxShadow;
  boxShadowColor?: string;
  showSubmit?: boolean;
  submitButtonStyles?: ButtonStyleProps;
  submitButtonLabel?: string;
  sourceData?: Record<string, unknown>;
  isSubmitting: boolean;
  onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  updateFormData: (formData?: any, formSchema?: any, formUI?: any) => void;
  updateWidgetProperty: (propertyName: string, propertyValue: any) => void;
  updateWidgetMetaProperty: (propertyName: string, propertyValue: any) => void;
}

export default React.memo(React.forwardRef(JsonSchemaFormComponent));
