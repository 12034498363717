import React, { createContext, useMemo } from "react";

type FormContextProps = React.PropsWithChildren<{
  updateFormData: (formData?: any, formSchema?: any, formUI?: any) => void;
  updateWidgetProperty: (propertyName: string, propertyValues: any) => void;
  updateWidgetMetaProperty: (propertyName: string, propertyValues: any) => void;
}>;

type FormContextValueProps = Omit<FormContextProps, "children">;

const FormContext = createContext<FormContextValueProps>(
  {} as FormContextValueProps,
);

export function FormContextProvider({
  children,
  updateFormData,
  updateWidgetMetaProperty,
  updateWidgetProperty,
}: FormContextProps) {
  const value = useMemo(
    () => ({
      updateFormData,
      updateWidgetProperty,
      updateWidgetMetaProperty,
    }),
    [],
  );
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export default FormContext;
