import styled from "styled-components";
import {
  labelLayoutStyles,
  LABEL_CONTAINER_CLASS,
} from "@design-system/widgets-old";

import { LABEL_MARGIN_OLD_SELECT, LabelPosition } from "components/constants";

export const TreeContainer = styled.div<{
  compactMode: boolean;
  labelPosition?: LabelPosition;
  borderRadius: string;
  boxShadow?: string;
  accentColor?: string;
  componentWidth?: number;
  componentHeight: number;
  dynamicHeight: string;
}>`
  ${labelLayoutStyles}
  /**
    When the label is on the left it is not center aligned
    here set height to auto and not 100% because the input
    has fixed height and stretch the container.
  */
  ${({ labelPosition }) => {
    if (labelPosition === LabelPosition.Left) {
      return `
      height: auto !important;
      align-items: stretch;
      `;
    }
  }}

  & .${LABEL_CONTAINER_CLASS} {
    label {
      ${({ labelPosition }) => {
        if (!labelPosition) {
          return `margin-bottom: ${LABEL_MARGIN_OLD_SELECT}`;
        }
      }};
    }
  }

  .my-tree {
    width: 99%;
    margin: 0 1px 10px 1px;
    border-radius: ${({ borderRadius }) => borderRadius};
    box-shadow: ${({ boxShadow }) => boxShadow};
    background-color: transparent;
    & .ant-tree-checkbox .ant-tree-checkbox-inner {
      background-color: #ffffff;
      border: 1px solid #d9d9d9;
    }

    & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
      background-color: #fff;
      border-color: #d9d9d9;
    }

    & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
      top: 50%;
      inset-inline-start: 50%;
      width: 8px;
      height: 8px;
      background-color: ${({ accentColor }) =>
        accentColor ? accentColor : "#27b7b7"} !important;
      border: 0;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: "";
    }

    & .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: ${({ accentColor }) =>
        accentColor ? accentColor : "#27b7b7"} !important;
      border-color: ${({ accentColor }) =>
        accentColor ? accentColor : "#27b7b7"} !important;
    }

    & .ant-tree-list {
      max-height: ${({ componentHeight, dynamicHeight }) =>
        dynamicHeight === "FIXED"
          ? `${componentHeight - 5 - 30}px`
          : "none"} !important;
      overflow: scroll;
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar {
        height: 0px;
      }
    }
  }
`;
