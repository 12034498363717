import React from "react";
import type { ComponentProps } from "widgets/BaseComponent";
import type { Alignment } from "@blueprintjs/core";
import type { DropdownOption } from "../constants";
import type {
  IItemListRendererProps,
  IItemRendererProps,
} from "@blueprintjs/select";
import { debounce, findIndex, isEmpty, isNil, isNumber } from "lodash";
import equal from "fast-deep-equal/es6";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { FixedSizeList } from "react-window";
import type { TextSize } from "constants/WidgetConstants";
// import { DropdownContainer, MenuItem, RTLStyleContainer } from "./index.styled";
import { WidgetContainerDiff } from "widgets/WidgetUtils";
import type { LabelPosition } from "components/constants";
import { labelMargin } from "../../WidgetUtils";
import LabelWithTooltip from "widgets/components/LabelWithTooltip";
import { ConfigProvider, Select, SelectProps } from "antd";
import { DraftValueType } from "rc-tree-select/lib/TreeSelect";
import AntdIcon from "components/common/AntdIcon";
import { DropdownContainer } from "./index.styled";
import {
  CloseCircleFilled,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const DEBOUNCE_TIMEOUT = 800;
const ITEM_SIZE = 40;
const MAX_RENDER_MENU_ITEMS_HEIGHT = 300;

interface AntdSelectComponentState {
  activeItemIndex: number | undefined;
  isOpen?: boolean;
}

const options: SelectProps["options"] = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

class AntdSelectComponent extends React.Component<
  AntdSelectComponentProps,
  AntdSelectComponentState
> {
  listRef: any = React.createRef();
  labelRef = React.createRef<HTMLDivElement>();
  spanRef = React.createRef<HTMLSpanElement>();

  componentDidMount = () => {
    const newState: AntdSelectComponentState = {
      activeItemIndex: this.props.selectedIndex,
    };

    if (this.props.isOpen) {
      newState.isOpen = this.props.isOpen;
    }

    // set default selectedIndex as focused index
    this.setState(newState);
  };

  componentDidUpdate = (prevProps: AntdSelectComponentProps) => {
    if (
      prevProps.selectedIndex !== this.props.selectedIndex &&
      this.state.activeItemIndex !== this.props.selectedIndex
    ) {
      // update focus index if selectedIndex changed by property pane
      this.setState({ activeItemIndex: this.props.selectedIndex });
    }
  };

  onQueryChange = debounce((filterValue: string) => {
    if (equal(filterValue, this.props.filterText)) return;
    this.props.onFilterChange(filterValue);
    this.listRef?.current?.scrollTo(0);
  }, DEBOUNCE_TIMEOUT);

  handleOnDropdownClose = () => {
    if (this.state.isOpen && this.props.onDropdownClose) {
      this.props.onDropdownClose();
    }
  };

  getDropdownWidth = () => {
    const parentWidth = this.props.width - WidgetContainerDiff;
    if (this.props.compactMode && this.labelRef.current) {
      const labelWidth = this.labelRef.current.getBoundingClientRect().width;
      const widthDiff = parentWidth - labelWidth - labelMargin;
      return widthDiff > this.props.dropDownWidth
        ? widthDiff
        : this.props.dropDownWidth;
    }
    return parentWidth > this.props.dropDownWidth
      ? parentWidth
      : this.props.dropDownWidth;
  };

  render() {
    const {
      accentColor,
      borderRadius,
      boxHeight = "middle",
      boxShadow,
      compactMode,
      disabled,
      isDynamicHeightEnabled,
      isLoading,
      isRequired,
      labelAlignment,
      labelPosition,
      labelStyle,
      labelText,
      labelTextColor,
      labelTextSize,
      labelTooltip,
      labelWidth,
      widgetId,
      showColon,
      placeholder,
      value,
      isFilterable,
      onChange,
      allowClear,
    } = this.props;
    const filterOption = (
      input: string,
      option?: { label: string; value: string },
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    return (
      <DropdownContainer
        className={this.props.className}
        compactMode={compactMode}
        data-testid="select-container"
        labelPosition={labelPosition}
        rtl={this.props.rtl}
      >
        <>
          {/* {this.props.rtl ? (
          <RTLStyleContainer
            dropdownPopoverContainer={`select-popover-wrapper-${this.props.widgetId}`}
          />
        ) : null} */}
          {labelText && (
            <LabelWithTooltip
              alignment={labelAlignment}
              className={`select-label`}
              color={labelTextColor}
              compact={compactMode}
              cyHelpTextClassName="select-tooltip"
              disabled={disabled}
              fontSize={labelTextSize}
              fontStyle={labelStyle}
              helpText={labelTooltip}
              isDynamicHeightEnabled={isDynamicHeightEnabled}
              isRequired={isRequired}
              loading={isLoading}
              position={labelPosition}
              ref={this.labelRef}
              showColon={showColon}
              text={labelText}
              width={labelWidth}
            />
          )}
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: accentColor,
                borderRadius,
              },
            }}
          >
            <Select
              showSearch={isFilterable}
              allowClear={allowClear}
              placeholder={placeholder}
              optionFilterProp="children"
              disabled={disabled}
              size={boxHeight}
              value={value || undefined}
              status={this.props.hasError ? "error" : ""}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: `${borderRadius}`,
                boxShadow: `${boxShadow}`,
              }}
              showArrow={true}
              inputIcon={
                isFilterable && isEmpty(value) ? (
                  <SearchOutlined className="ant-select-suffix" />
                ) : (
                  <DownOutlined className="ant-select-suffix" />
                )
              }
              clearIcon={!isEmpty(value) && <CloseCircleFilled />}
              onChange={this.props.onSelect}
              onSelect={this.props.onSelect}
              onSearch={this.onQueryChange}
              onDropdownVisibleChange={this.props.onDropdownVisibleChange}
              filterOption={filterOption}
              options={this.props.options}
            />
          </ConfigProvider>
        </>
      </DropdownContainer>
    );
  }
}

export interface AntdSelectComponentProps extends ComponentProps {
  className?: string;
  disabled?: boolean;
  onOptionSelected?: (optionSelected: DropdownOption) => void;
  placeholder?: string;
  labelAlignment?: Alignment;
  labelPosition?: LabelPosition;
  labelText: string;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  labelWidth?: number;
  labelTooltip?: string;
  compactMode: boolean;
  selectedIndex?: number;
  options: DropdownOption[];
  isDynamicHeightEnabled?: boolean;
  isLoading: boolean;
  isFilterable: boolean;
  isValid: boolean;
  width: number;
  dropDownWidth: number;
  height: number;
  serverSideFiltering: boolean;
  hasError?: boolean;
  onFilterChange: (text: string) => void;
  onDropdownVisibleChange?: (val: boolean) => void;
  onDropdownClose?: () => void;
  onSelect?: (val: string) => void;
  onChange?: () => void;
  value?: string;
  label?: string | number;
  filterText?: string;
  borderRadius: string;
  boxShadow?: string;
  boxHeight?: "small" | "middle" | "large" | undefined;
  accentColor?: string;
  isOpen?: boolean;
  onClose?: () => void;
  hideCancelIcon?: boolean;
  resetFilterTextOnClose?: boolean;
  rtl?: boolean;
  showColon?: boolean;
  allowClear?: boolean;
}

export default React.memo(AntdSelectComponent);
