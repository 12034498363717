// This file contains common constants which can be used across the widget configuration file (index.ts), widget and
// component folders.

export interface CustomComponentDataProps {
  [key: string]: any;
}

export const CUSTOMCOMPONENT_WIDGET_CONSTANT = "";

export enum EventTypeEnum {
  Invoke = "invoke",
  Data = "data",
  Init = "init",
}

export enum MethodNameEnum {
  RunJS = "runJS",
  RunQuery = "runQuery",
  GetModel = "getModel",
  UpdateModel = "updateModel",
}

export interface InvokePayload {
  id: string;
  method: MethodNameEnum;
  data: any;
}

export interface InvokeEventData {
  type: EventTypeEnum.Invoke;
  componentId: string;
  payload: InvokePayload;
}

export type EventData = InvokeEventData;

export const testCode = `
<style>
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }

  * {
    font-family: 'Inter', sans-serif;
  }

  body {
    margin: 0;
  }

  #react {
    height: 100%;
    width: 100%;
  }

  .card {
    min-width: 0;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--background-surface);
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .docs-link {
    font-size: 12px;
    font-weight: 500;
    color: #b062bc;
    text-decoration: none;
  }

  .docs-link:hover {
    color: #9846a4;
  }

  .content {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #777777;
  }

  .button-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
  }

  .button {
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    appearance: none;
    user-select: auto;
  }

  .button--main {
    background-color: #b062bc;
    color: #ffffff;
  }

  .button--main:hover {
    background-color: #9846a4;
  }

  .button--secondary {
    color: #b062bc;
    background-color: var(--background-surface);
    border: 1px solid #b062bc;
  }

  .button--secondary:hover {
    color: #9846a4;
    border: 1px solid #9846a4;
  }
</style>

<!-- You can add any HTML/CSS/JS here. UMD versions are required.
Keep in mind that Custom Components are sensitive to bundle sizes, so try using a
custom implementation when possible. -->
<script crossorigin src="https://unpkg.com/react@18/umd/react.development.js"></script>
<script crossorigin src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>

<div id="react"></div>

<script type="text/babel">
  const MyCustomComponent = ({ runQuery, runJS, model, modelUpdate }) => (
    <div className="card">
      <div className="title-container">
        <div className="title">Custom component</div>
        <a href="https://docs.retool.com/apps/web/guides/components/develop-custom-components/custom" target="_blank" className="docs-link">View docs</a>
      </div>
      <div className="content">
        {/* The text below is dynamic and specified by the model. */}
        {model.name}-{model.other}
      </div>
      <div className="button-container">
        {/* This button fires a dynamic query (specified in the model) when clicked. */}
        <button
          className="button button--main"
          onClick={() => runJS("JSObject1.showTip")}
        >
          Trigger JS
        </button>

        {/* This button updates the model when clicked. */}
        <button
          className="button button--secondary"
          onClick={() => modelUpdate({ name: 'xiaomei' })}
        >
          Update model
        </button>
      </div>
    </div>
  )
  // This is the entrypoint for the React component.
  const TestComp = ({model}) => (
    <div>aaaa{model.name}</div>
  )
  console.log(window, PP)
  const ConnectedComponent = PP.connect(MyCustomComponent)

  const container = document.getElementById('react')

  const root = ReactDOM.createRoot(container)
  root.render(<ConnectedComponent />)
</script>
`;

export const newTestCode = `
<link rel="stylesheet" type="text/css" href="https://unpkg.com/antd@4.21.4/dist/antd.min.css"/>
<script type="text/javascript" src="https://unpkg.com/antd@4.21.4/dist/antd.min.js" ></script>
<div id="root"></div>
<script type="text/babel">

  const { Button, Card, Input, Space } = antd;
  const MyCustomComponent = ({ runQuery, model, updateModel, runJS}) => (
    <Card title={"Hello, " + model.name}>
        <p>{model.text}</p>
        <Space>
          <Button
            type="primary"
            onClick={() => runJS(model.jsFunName)}
        >
            触发查询RunJS
          </Button>
          <Button
            type="primary"
            onClick={() => runQuery(model.query)}
          >
            触发查询RunQuery
        </Button>
          <Button
            onClick={() => updateModel({ text: "开始使用自定义组件！" })}
          >
          更新数据
          </Button>
      </Space>
    </Card>
  );

  const ConnectedComponent = PP.connect(MyCustomComponent);

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<ConnectedComponent />);

</script>
`;
