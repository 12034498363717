import React from "react";
import { PercentPond } from "@jiaminghi/data-view-react";

function PercentPondComponent(props: PercentPondComponentProps) {
  const config = {
    value: props.value,
    textColor: props.textColor,
    lineDash: props.lineDash,
    borderWidth: props.borderWidth,
    borderRadius: props.borderRadius,
    borderGap: props.borderGap,
    localGradient: props.localGradient,
    formatter: props.formatter,
  };
  return (
    <PercentPond
      className="percentpond"
      config={config}
      style={{ width: "100%", height: "100%" }}
    />
  );
}

export interface PercentPondComponentProps {
  value: number;
  colors?: string;
  borderWidth: number;
  borderGap: number;
  lineDash: number[];
  textColor?: string;
  borderRadius: number;
  localGradient: boolean;
  formatter: string;
}

export default PercentPondComponent;
