import React, { useRef, useState } from "react";
import { ScrollBoard } from "@jiaminghi/data-view-react";
import useResizeObserver from "utils/hooks/useResizeObserver";

function ScrollBoardComponent(props: ScrollBoardComponentProps) {
  const scrollboradRef = useRef<any>();
  const [, setSymbol] = useState<any>();

  useResizeObserver(scrollboradRef.current?.current, () => {
    setSymbol(Symbol("refresh"));
  });

  const {
    data,
    header,
    rowNum,
    headerBGC,
    oddRowBGC,
    evenRowBGC,
    waitTime,
    headerHeight,
    index,
    indexHeader,
    carousel,
    hoverPause,
  } = props;
  const config = {
    data,
    header,
    rowNum,
    headerBGC,
    oddRowBGC,
    evenRowBGC,
    waitTime,
    headerHeight,
    index,
    indexHeader,
    carousel,
    hoverPause,
  };
  return (
    <div ref={scrollboradRef} style={{ width: "100%", height: "100%" }}>
      <ScrollBoard
        ref={scrollboradRef}
        config={config}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}

export interface ScrollBoardComponentProps {
  header: any;
  data: any;
  rowNum: number;
  headerBGC: string;
  oddRowBGC: string;
  evenRowBGC: string;
  waitTime: number;
  headerHeight: number;
  index?: boolean;
  indexHeader?: string;
  carousel: string;
  hoverPause: boolean;
}

export default ScrollBoardComponent;
