import React, { useRef, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import Interweave from "interweave";
import { IButtonProps, Icon, MaybeElement } from "@blueprintjs/core";
import { Alignment, Position, Classes } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { IconName } from "@blueprintjs/icons";
import { useSelector } from "react-redux";
import { isSelectScreenTheme } from "selectors/appThemingSelectors";

import type { ComponentProps } from "widgets/BaseComponent";

import { useScript, ScriptStatus, AddScriptTo } from "utils/hooks/useScript";
import {
  GOOGLE_RECAPTCHA_KEY_ERROR,
  GOOGLE_RECAPTCHA_DOMAIN_ERROR,
  createMessage,
} from "@appsmith/constants/messages";

import ReCAPTCHA from "react-google-recaptcha";
import { Colors } from "constants/Colors";
import _, { isEmpty } from "lodash";
import type {
  ButtonPlacement,
  ButtonVariant,
  RecaptchaType,
} from "components/constants";
import { ButtonVariantTypes, RecaptchaTypes } from "components/constants";
import {
  getCustomBackgroundColor,
  getCustomBorderColor,
  getCustomJustifyContent,
  getAlignText,
  getComplementaryGrayscaleColor,
  calculateHoverColor,
} from "widgets/WidgetUtils";
import { DragContainer } from "./DragContainer";
import { buttonHoverActiveStyles } from "./utils";
import type { ThemeProp } from "WidgetProvider/constants";
import { toast } from "design-system";

import { Button, ConfigProvider, Popover } from "antd";
import AntdIcon from "components/common/AntdIcon";
import tinycolor from "tinycolor2";
import { addOverlayEffect } from "pages/utils";
import { renderVantIcon } from "pages/Editor/ViewerLayoutEditor/IconSelector";
import { getIconParams } from "utils/widgetIcon";

const RecaptchaWrapper = styled.div`
  position: relative;
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const buttonBaseStyle = css<ThemeProp & ButtonStyleProps>`
  height: 100%;
  background-image: none !important;
  font-weight: ${(props) => props.theme.fontWeights[2]};
  outline: none;
  padding: 0px 10px;
  gap: 8px;

  &:hover,
  &:active,
  &:focus {
    ${buttonHoverActiveStyles}
    background: ${(props) =>
      props.buttonBackgroundImage
        ? `url("${props.buttonBackgroundImage}") no-repeat center top / 100% 100% !important`
        : ""};
  }

  ${({
    buttonBackgroundImage,
    buttonColor,
    buttonVariant,
    isScreen,
    theme,
  }) => `
    background: ${
      getCustomBackgroundColor(buttonVariant, buttonColor) !== "none"
        ? getCustomBackgroundColor(buttonVariant, buttonColor)
        : buttonVariant === ButtonVariantTypes.PRIMARY
          ? theme.colors.button.primary.primary.bgColor
          : "none"
    } !important;
    &:disabled, &.${Classes.DISABLED} {
    cursor: not-allowed;
    background-color: ${
      buttonVariant !== ButtonVariantTypes.TERTIARY &&
      "var(--wds-color-bg-disabled)"
    } !important;
    color: var(--wds-color-text-disabled) !important;
    box-shadow: none !important;
    pointer-events: none;
    border-color: var(--wds-color-border-disabled) !important;

    > span {
      color: var(--wds-color-text-disabled) !important;
    }
  }

  border: ${
    getCustomBorderColor(buttonVariant, buttonColor) !== "none"
      ? `1px solid ${getCustomBorderColor(
          buttonVariant,
          buttonColor,
          isScreen && !buttonBackgroundImage,
        )}`
      : buttonVariant === ButtonVariantTypes.SECONDARY
        ? `1px solid ${theme.colors.button.primary.secondary.borderColor}`
        : "none"
  } !important;

  & > * {
    margin-right: 0;
  }

  & > span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: normal;

    color: ${
      buttonVariant === ButtonVariantTypes.PRIMARY
        ? getComplementaryGrayscaleColor(buttonColor)
        : getCustomBackgroundColor(ButtonVariantTypes.PRIMARY, buttonColor)
    } !important;
  }
`}

  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => `${boxShadow ?? "none"}`} !important;

  ${({ placement }) =>
    placement
      ? `justify-content: ${getCustomJustifyContent(placement)};
    & > span.bp3-button-text {
      flex: unset !important;
    }
  `
      : ""}
`;

// export const StyledButton = styled((props) => (
//   <Button
//     {..._.omit(props, [
//       "borderRadius",
//       "boxShadow",
//       "boxShadowColor",
//       "buttonColor",
//       "buttonVariant",
//     ])}
//   />
// ))<ThemeProp & ButtonStyleProps>`
//   ${buttonBaseStyle}
// `;

const StyledButton = styled(
  ({
    width,
    height,
    borderRadius,
    boxShadow,
    boxShadowColor,
    buttonBackgroundImage,
    buttonColor,
    buttonVariant,
    ...props
  }) => (
    <Button
      style={{
        width: `${width}`,
        height: `${height}`,
        borderRadius: `${buttonVariant !== ButtonVariantTypes.LINK ? borderRadius : "none"}`,
        boxShadow: `${buttonVariant !== ButtonVariantTypes.LINK ? boxShadow : "none"}`,
        background: buttonBackgroundImage
          ? `url(${buttonBackgroundImage})`
          : "",
      }}
      {...props}
    />
  ),
)`
  border: none;
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
    color: ${(props) => props.buttonColor} !important;
    background: ${(props) => props.buttonColor} !important;
  }
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => props.buttonColor} !important;
    background: ${(props) => props.buttonColor} !important;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  .ant-btn-icon {
    display: inline-block;
    width: 100%;
  }
`;

const StyledIconBox = styled.div`
  border-radius: 2px;
  height: 20px;
  border: 1px solid transparent;
  border-top: 0px;
  border-bottom: 0px;
  padding: 0px 2px;
  transition: background 0.3s;
  &:hover {
    background-color: rgba(16, 22, 26, 0.06);
  }
  .van-icon.taroify-icon {
    font-size: 16px;
  }

  > span.bp3-icon-caret-down {
    color: rgb(169, 167, 167);
  }
`;

export interface ButtonStyleProps {
  buttonColor?: string;
  buttonBackgroundImage?: string;
  buttonVariant?: ButtonVariant;
  boxShadow?: string;
  boxShadowColor?: string;
  borderRadius?: string;
  iconName?: any;
  iconAlign?: Alignment;
  shouldFitContent?: boolean;
  placement?: ButtonPlacement;
  isScreen?: boolean;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
  NotRenderAfter?: boolean;
  tooltip?: string;
}

// To be used in any other part of the app
export function BaseButton(props: IButtonProps & ButtonStyleProps) {
  const isScreen = useSelector(isSelectScreenTheme);
  const {
    borderRadius,
    boxShadow,
    boxShadowColor,
    buttonBackgroundImage,
    buttonColor,
    buttonVariant,
    className,
    disabled,
    icon,
    iconAlign,
    iconName,
    loading,
    maxWidth,
    minHeight,
    minWidth,
    NotRenderAfter,
    onClick,
    placement,
    rightIcon,
    text,
    type,
    tooltip,
  } = props;

  const isRightAlign = iconAlign === Alignment.RIGHT;
  const iconObj = getIconParams(iconName);
    // iconName === "" || iconName === undefined
    //   ? { iconName: "", type: "antd", category: "direction" }
    //   : _.isString(iconName)
    //     ? JSON.parse(iconName)
    //     : iconName;
  const AntdButtonStyleTypes: Record<string, string> = {
    PRIMARY: "primary",
    SECONDARY: "default",
    TERTIARY: "text",
    LINK: "link",
  };

  const getJustifyContent = () => {
    switch (placement) {
      case "START":
        return "start";
      case "BETWEEN":
        return "space-between";
      default:
        return "center";
    }
  };

  // 渲染文本和图标
  const renderContent = () => {
    const iconStyle = {
      fontSize: "20px",
    };
    const renderStyle = {
      display: "flex",
      justifyContent: getJustifyContent(),
      alignItems: "center",
      width: "100%",
    };
    if (!isRightAlign) {
      return (
        <div style={renderStyle}>
          {iconObj.type === "appsmith" ? (
            <Icon icon={iconObj.iconName} />
          ) : (
            <AntdIcon type={iconObj.iconName ?? ""} style={iconStyle} />
          )}
          <pre style={{ marginLeft: iconObj.iconName ? "8px" : 0 }}>{text}</pre>
        </div>
      );
    } else {
      return (
        <div style={renderStyle}>
          <pre style={{ marginRight: iconObj.iconName ? "8px" : 0 }}>
            {text}
          </pre>
          {iconObj.type === "appsmith" ? (
            <Icon icon={iconObj.iconName} />
          ) : (
            <AntdIcon type={iconObj.iconName ?? ""} style={iconStyle} />
          )}
        </div>
      );
    }
  };

  const tooltipContent = () => {
    return !isEmpty(tooltip) ? (
      <div className="text-[#000]">{tooltip}</div>
    ) : (
      ""
    );
  };

  return (
    <ButtonContainer>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: buttonColor,
            colorLink: buttonColor,
            colorBgTextActive: addOverlayEffect(
              calculateHoverColor(buttonColor),
              0.5,
            ),
            colorTextLightSolid:
              buttonVariant === ButtonVariantTypes.PRIMARY
                ? getComplementaryGrayscaleColor(buttonColor)
                : addOverlayEffect(calculateHoverColor(buttonColor), 0.5),
            colorText:
              buttonVariant === ButtonVariantTypes.TERTIARY
                ? addOverlayEffect(calculateHoverColor(buttonColor), 0.2)
                : buttonVariant === ButtonVariantTypes.LINK
                  ? buttonColor
                  : "initial",
          },
          components: {
            Button: {
              fontWeight: 600,
              textHoverBg: addOverlayEffect(
                calculateHoverColor(buttonColor),
                0.8,
              ),
              contentFontSize: 15,
            },
          },
        }}
      >
        <Popover content={tooltipContent()}>
          <StyledButton
            width="100%"
            height="100%"
            type={
              AntdButtonStyleTypes[
                buttonVariant === undefined ? "PRIMARY" : buttonVariant
              ]
            }
            onClick={onClick}
            autoInsertSpace={false}
            disabled={disabled}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
            boxShadowColor={boxShadowColor}
            buttonBackgroundImage={buttonBackgroundImage}
            buttonColor={buttonColor}
            buttonVariant={buttonVariant}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: getJustifyContent(),
                alignItems: "center",
              }}
            > */}
            {renderContent()}
            {/* </div> */}
          </StyledButton>
        </Popover>

        {/* <Popover content={tooltip}>
        <Button
          className={className}
          disabled={disabled}
          type={
            AntdButtonStyleTypes[
              buttonVariant === undefined ? "PRIMARY" : buttonVariant
            ]
          }
          onClick={onClick}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: `${borderRadius}`,
            boxShadow: `${boxShadow ?? "none"}`,
            background: buttonBackgroundImage
              ? `url(${buttonBackgroundImage})`
              : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: getJustifyContent(),
              alignItems: "center",
            }}
          >
            {renderContent()}
          </div>
        </Button>
      </Popover> */}
      </ConfigProvider>
    </ButtonContainer>
  );
}

BaseButton.defaultProps = {
  buttonColor: Colors.GREEN,
  buttonVariant: ButtonVariantTypes.PRIMARY,
  disabled: false,
  text: "Button Text",
  minimal: true,
};

export enum ButtonType {
  SUBMIT = "submit",
  RESET = "reset",
  BUTTON = "button",
}

interface RecaptchaProps {
  googleRecaptchaKey?: string;
  clickWithRecaptcha: (token: string) => void;
  handleRecaptchaV2Loading?: (isLoading: boolean) => void;
  recaptchaType?: RecaptchaType;
}

interface ButtonComponentProps extends ComponentProps {
  text?: string;
  icon?: IconName | MaybeElement;
  tooltip?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  isLoading: boolean;
  shouldFitContent: boolean;
  rightIcon?: IconName | MaybeElement;
  type: ButtonType;
  buttonColor?: string;
  buttonBackgroundImage?: string;
  buttonVariant?: ButtonVariant;
  borderRadius?: string;
  boxShadow?: string;
  boxShadowColor?: string;
  iconName?: any;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  className?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
}

interface RecaptchaV2ComponentPropType {
  children: any;
  className?: string;
  isDisabled?: boolean;
  recaptchaType?: RecaptchaType;
  isLoading: boolean;
  handleError: (event: React.MouseEvent<HTMLElement>, error: string) => void;
}

function RecaptchaV2Component(
  props: RecaptchaV2ComponentPropType & RecaptchaProps,
) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isInvalidKey, setInvalidKey] = useState(false);
  const handleRecaptchaLoading = (isloading: boolean) => {
    props.handleRecaptchaV2Loading && props.handleRecaptchaV2Loading(isloading);
  };
  const handleBtnClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (props.isDisabled) return;
    if (props.isLoading) return;
    if (isInvalidKey) {
      // Handle incorrent google recaptcha site key
      props.handleError(event, createMessage(GOOGLE_RECAPTCHA_KEY_ERROR));
    } else {
      handleRecaptchaLoading(true);
      try {
        await recaptchaRef?.current?.reset();
        const token = await recaptchaRef?.current?.executeAsync();
        if (token) {
          props.clickWithRecaptcha(token);
        } else {
          // Handle incorrent google recaptcha site key
          props.handleError(event, createMessage(GOOGLE_RECAPTCHA_KEY_ERROR));
        }
        handleRecaptchaLoading(false);
      } catch (err) {
        handleRecaptchaLoading(false);
        // Handle error due to google recaptcha key of different domain
        props.handleError(event, createMessage(GOOGLE_RECAPTCHA_DOMAIN_ERROR));
      }
    }
  };
  return (
    <RecaptchaWrapper className={props.className} onClick={handleBtnClick}>
      {props.children}
      <ReCAPTCHA
        onErrored={() => setInvalidKey(true)}
        ref={recaptchaRef}
        sitekey={props.googleRecaptchaKey || ""}
        size="invisible"
      />
    </RecaptchaWrapper>
  );
}

interface RecaptchaV3ComponentPropType {
  children: any;
  className?: string;
  isDisabled?: boolean;
  recaptchaType?: RecaptchaType;
  isLoading: boolean;
  handleError: (event: React.MouseEvent<HTMLElement>, error: string) => void;
}

function RecaptchaV3Component(
  props: RecaptchaV3ComponentPropType & RecaptchaProps,
) {
  // Check if a string is a valid JSON string
  const checkValidJson = (inputString: string): boolean => {
    return !inputString.includes("");
  };

  const handleBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.isDisabled) return;
    if (props.isLoading) return;
    if (status === ScriptStatus.READY) {
      (window as any).grecaptcha.ready(() => {
        try {
          (window as any).grecaptcha
            .execute(props.googleRecaptchaKey, {
              action: "submit",
            })
            .then((token: any) => {
              props.clickWithRecaptcha(token);
            })
            .catch(() => {
              // Handle incorrent google recaptcha site key
              props.handleError(
                event,
                createMessage(GOOGLE_RECAPTCHA_KEY_ERROR),
              );
            });
        } catch (err) {
          // Handle error due to google recaptcha key of different domain
          props.handleError(
            event,
            createMessage(GOOGLE_RECAPTCHA_DOMAIN_ERROR),
          );
        }
      });
    }
  };

  let validGoogleRecaptchaKey = props.googleRecaptchaKey;
  if (validGoogleRecaptchaKey && !checkValidJson(validGoogleRecaptchaKey)) {
    validGoogleRecaptchaKey = undefined;
  }
  const status = useScript(
    `https://www.google.com/recaptcha/api.js?render=${validGoogleRecaptchaKey}`,
    AddScriptTo.HEAD,
  );
  return (
    <div className={props.className} onClick={handleBtnClick}>
      {props.children}
    </div>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;

function BtnWrapper(
  props: {
    children: any;
    className?: string;
    isDisabled?: boolean;
    isLoading: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  } & RecaptchaProps,
) {
  const hasOnClick = Boolean(
    props.onClick && !props.isLoading && !props.isDisabled,
  );
  if (!props.googleRecaptchaKey) {
    return (
      <Wrapper
        className={props.className}
        onClick={hasOnClick ? props.onClick : undefined}
      >
        {props.children}
      </Wrapper>
    );
  } else {
    const handleError = (
      event: React.MouseEvent<HTMLElement>,
      error: string,
    ) => {
      toast.show(error, {
        kind: "error",
      });
      props.onClick && !props.isLoading && props.onClick(event);
    };
    if (props.recaptchaType === RecaptchaTypes.V2) {
      return <RecaptchaV2Component {...props} handleError={handleError} />;
    } else {
      return <RecaptchaV3Component {...props} handleError={handleError} />;
    }
  }
}

// To be used with the canvas
function ButtonComponent(props: ButtonComponentProps & RecaptchaProps) {
  const btnWrapper = (
    <BtnWrapper
      className={props.className}
      clickWithRecaptcha={props.clickWithRecaptcha}
      googleRecaptchaKey={props.googleRecaptchaKey}
      handleRecaptchaV2Loading={props.handleRecaptchaV2Loading}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      onClick={props.onClick}
      recaptchaType={props.recaptchaType}
    >
      <BaseButton
        borderRadius={props.borderRadius}
        boxShadow={props.boxShadow}
        boxShadowColor={props.boxShadowColor}
        buttonBackgroundImage={props.buttonBackgroundImage}
        buttonColor={props.buttonColor}
        buttonVariant={props.buttonVariant}
        disabled={props.isDisabled}
        icon={props.icon}
        iconAlign={props.iconAlign}
        iconName={props.iconName}
        loading={props.isLoading}
        maxWidth={props.maxWidth}
        minHeight={props.minHeight}
        minWidth={props.minWidth}
        placement={props.placement}
        rightIcon={props.rightIcon}
        shouldFitContent={props.shouldFitContent}
        text={props.text}
        type={props.type}
        tooltip={props.tooltip}
      />
    </BtnWrapper>
  );
  return btnWrapper;
}

export default ButtonComponent;
