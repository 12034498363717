import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import { ValidationTypes } from "constants/WidgetValidation";

import PercentPondComponent from "../component";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";

class PercentPondWidget extends BaseWidget<
  PercentPondWidgetProps,
  WidgetState
> {
  static type = "PERCENTPOND_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "进度池", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "PercentPond",
      rows: 6,
      columns: 10,
      version: 1,
      value: 66,
      textColor: "#fff",
      lineDash: [5, 1],
      borderWidth: 5,
      borderRadius: 10,
      borderGap: 5,
      localGradient: false,
      formatter: "{value}%",
      colors: ["#00BAFF", "#3DE7C9"],
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "进度条数值",
            propertyName: "value",
            label: "数值",
            controlType: "INPUT_TEXT",
            placeholderText: "55",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "水位图配色",
            propertyName: "colors",
            label: "配色",
            controlType: "INPUT_TEXT",
            placeholderText: `["#00BAFF", "#3DE7C9"]`,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.TEXT,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },

          {
            helpText: "文字颜色",
            propertyName: "textColor",
            label: "文字颜色",
            controlType: "COLOR_PICKER",
            fullWidth: true,
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText: "边框圆角",
            propertyName: "borderRadius",
            label: "边框圆角",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入边框圆角",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "格式化",
            propertyName: "formatter",
            label: "信息格式化	",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入信息格式",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   helpText: "边框宽度",
          //   propertyName: "borderWidth",
          //   label: "边框宽度",
          //   controlType: "INPUT_TEXT",
          //   placeholderText: "请输入边框宽度",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.NUMBER },
          // },
          {
            helpText: "边框间隙",
            propertyName: "borderGap",
            label: "边框间隙",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入边框间隙",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "线条间隙",
            propertyName: "lineDash",
            label: "线条间隙",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入线条间隙",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.NUMBER,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
          {
            helpText: "局部渐变",
            propertyName: "localGradient",
            label: "局部渐变	",
            controlType: "SWITCH",
            placeholderText: "请输入边框半径",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const {
      borderGap,
      borderRadius,
      borderWidth,
      formatter,
      lineDash,
      localGradient,
      textColor,
      value,
    } = this.props;
    const childProps = {
      value,
      textColor,
      lineDash,
      borderWidth,
      borderRadius,
      borderGap,
      localGradient,
      formatter,
    };
    return <PercentPondComponent {...childProps} />;
  }
}

export interface PercentPondWidgetProps extends WidgetProps {
  value: number;
  colors?: string;
  borderWidth: number;
  borderGap: number;
  lineDash: number[];
  textColor?: string;
  borderRadius: number;
  localGradient: boolean;
  formatter: string;
}

export default PercentPondWidget;
