import React, { useEffect, useRef } from "react";
import { InputNumber } from "antd";

const CustomUpDownWidget = (props: any) => {
  const { id, onBlur, onChange, onFocus, value } = props;
  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (props.rawErrors && props.rawErrors.length > 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.rawErrors]);

  return (
    <InputNumber
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      ref={inputRef}
      style={{ width: "100%" }}
      value={value}
    />
  );
};

export default CustomUpDownWidget;
