import React from "react";

import {
  CONTAINER_GRID_PADDING,
  GridDefaults,
  MAIN_CONTAINER_WIDGET_ID,
  RenderModes,
  WIDGET_PADDING,
  WIDGET_TAGS,
} from "constants/WidgetConstants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { ContainerStyle } from "../component";
import ContainerComponent from "../component";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import { ValidationTypes } from "constants/WidgetValidation";

import { compact, map, sortBy } from "lodash";
import { CanvasSelectionArena } from "layoutSystems/fixedlayout/editor/FixedLayoutCanvasArenas/CanvasSelectionArena";
import WidgetsMultiSelectBox from "layoutSystems/fixedlayout/common/widgetGrouping/WidgetsMultiSelectBox";

import { FixedCanvasDraggingArena } from "layoutSystems/fixedlayout/editor/FixedLayoutCanvasArenas/FixedCanvasDraggingArena";
import { getCanvasSnapRows } from "utils/WidgetPropsUtils";
import type { Stylesheet } from "entities/AppTheming";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import { isAutoHeightEnabledForWidget } from "widgets/WidgetUtils";
import { THEME_SETS } from "../constants";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { RegisteredWidgetFeatures } from "utils/WidgetFeatures";
import { Colors } from "constants/Colors";
import { ButtonBoxShadowTypes } from "components/constants";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";

function updateBgColor(
  props: any,
  propertyPath: string,
  propertyValue: string,
) {
  const propertiesToUpdate = [{ propertyPath, propertyValue }];
  if (propertyPath === "isVideo" && propertyValue) {
    propertiesToUpdate.push({
      propertyPath: "backgroundColor",
      propertyValue: "transparent",
    });
  }
  if (propertyPath === "isVideo" && !propertyValue) {
    propertiesToUpdate.push({
      propertyPath: "backgroundColor",
      propertyValue: "rgba(0, 0, 0)",
    });
  }
  if (propertyPath === "containerTheme") {
    // propertiesToUpdate.push({
    //   propertyPath: "backgroundColor",
    //   propertyValue: "transparent",
    // });
  }
  return propertiesToUpdate;
}

class ScreenContainerComWidget extends BaseWidget<
  ScreenContainerWidgetProps<WidgetProps>,
  WidgetState
> {
  constructor(props: ScreenContainerWidgetProps<WidgetProps>) {
    super(props);
    this.renderChildWidget = this.renderChildWidget.bind(this);
  }

  static type = "SCREEN_COMP_CONTAINER_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "组件容器",
      iconSVG: IconSVG,
      isCanvas: true,
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
      searchTags: ["div", "parent", "group", "container"],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0,
        active: true,
      },
      [RegisteredWidgetFeatures.FLOAT_LAYOUT]: {
        sectionIndex: 1,
        active: true,
      },
    };
  }

  static getMethods() {
    return {
      getCanvasHeightOffset: (props: WidgetProps): number => {
        const offset =
          props.borderWidth && props.borderWidth > 1
            ? Math.ceil(
                (2 * parseInt(props.borderWidth, 10) || 0) /
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              )
            : 0;

        return offset;
      },
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      backgroundColor: "#0a2732",
      rows: 24,
      minDynamicHeight: 24,
      columns: 24,
      widgetName: "Comp_Container",
      containerStyle: "card",
      borderColor: Colors.GREY_5,
      borderWidth: "0",
      boxShadow: ButtonBoxShadowTypes.NONE,
      animateLoading: true,
      children: [],
      isVideo: false,
      containerTheme: "set1",
      video:
        "https://app.fusiontech.cn/ot180uzvlb_1650917140489_r9761ae1ud.mp4",
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { top: 0, left: 0 },
            props: {
              containerStyle: "none",
              canExtend: false,
              detachFromLayout: true,
              children: [],
            },
          },
        ],
      },
      version: 1,
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "主题配置",
        children: [
          {
            propertyName: "containerTheme",
            label: "容器边框",
            helpText: "整体，头部，底部背景",
            controlType: "IMAGE_CARD_OPTIONS",
            controlSize: "lg",
            defaultValue: "set1",
            updateHook: updateBgColor,
            fullWidth: true,
            options: THEME_SETS,
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "视频背景",
        children: [
          {
            // helpText: "控制组件的显示/隐藏",
            propertyName: "isVideo",
            label: "是否显示",
            controlType: "SWITCH",
            defaultValue: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
            updateHook: updateBgColor,
          },
          {
            propertyName: "video",
            label: "视频地址",
            controlType: "INPUT_TEXT",
            defaultValue:
              "https://app.fusiontech.cn/ot180uzvlb_1650917140489_r9761ae1ud.mp4",
            isTriggerProperty: true,
            isBindProperty: true,
            isJSConvertible: true,
            hidden: (props: any) => !props.isVideo,
            dependencies: ["isVideo"],
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "控制组件的显示/隐藏",
            propertyName: "isVisible",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "允许组件内部内容滚动",
            propertyName: "shouldScrollContents",
            label: "允许内容滚动",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "颜色配置",
        children: [
          {
            helpText: "使用 html 颜色名称，HEX，RGB 或者 RGBA 值",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "backgroundColor",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "使用图片 URL 或 Base64 数据",
            placeholderText: "使用图片 URL 或 Base64 数据",
            propertyName: "backgroundImage",
            label: "背景图片",
            controlType: "INPUT_TEXT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "使用 html 颜色名称，HEX，RGB 或者 RGBA 值",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "轮廓样式",
        children: [
          {
            helpText: "输入边框宽度",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "以 px 为单位",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
            postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "边框圆角样式",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText: "组件轮廓投影",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }
  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  getSnapSpaces = () => {
    const { componentWidth } = this.props;

    // For all widgets inside a container, we remove both container padding as well as widget padding from component width
    let padding = (CONTAINER_GRID_PADDING + WIDGET_PADDING) * 2;
    if (
      this.props.widgetId === MAIN_CONTAINER_WIDGET_ID ||
      this.props.type === "CONTAINER_WIDGET"
    ) {
      //For MainContainer and any Container Widget padding doesn't exist coz there is already container padding.
      padding = CONTAINER_GRID_PADDING * 2;
    }
    if (this.props.noPad) {
      // Widgets like ListWidget choose to have no container padding so will only have widget padding
      padding = WIDGET_PADDING * 2;
    }
    let width = componentWidth;
    width -= padding;
    return {
      snapRowSpace: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      snapColumnSpace: componentWidth
        ? width / GridDefaults.DEFAULT_GRID_COLUMNS
        : 0,
    };
  };

  renderChildWidget(childWidgetData: WidgetProps): React.ReactNode {
    const childWidget = { ...childWidgetData };

    const { componentHeight, componentWidth } = this.props;

    childWidget.rightColumn = componentWidth;
    childWidget.bottomRow = this.props.shouldScrollContents
      ? childWidget.bottomRow
      : componentHeight;
    childWidget.minHeight = componentHeight;
    childWidget.shouldScrollContents = false;
    childWidget.canExtend = this.props.shouldScrollContents;

    childWidget.parentId = this.props.widgetId;

    return renderAppsmithCanvas(childWidget as WidgetProps);
  }

  renderChildren = () => {
    return map(
      // sort by row so stacking context is correct
      // TODO(abhinav): This is hacky. The stacking context should increase for widgets rendered top to bottom, always.
      // Figure out a way in which the stacking context is consistent.
      // click event
      sortBy(compact(this.props.children), (child) => {
        const ifshouldSkipContainerReflow: boolean =
          child.type === "MAPBACKDROP_WIDGET";
        if (ifshouldSkipContainerReflow) {
          return -1;
        } else {
          return child.topRow;
        }
      }),
      this.renderChildWidget,
    );
  };

  renderAsContainerComponent(props: ScreenContainerWidgetProps<WidgetProps>) {
    const snapRows = getCanvasSnapRows(props.bottomRow);
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidget(this.props);
    return (
      <ContainerComponent {...props} noScroll={isAutoHeightEnabled}>
        {props.type === "CANVAS_WIDGET" &&
          props.renderMode === RenderModes.CANVAS && (
            <>
              <FixedCanvasDraggingArena
                {...this.getSnapSpaces()}
                canExtend={props.canExtend}
                dropDisabled={!!props.dropDisabled}
                noPad={this.props.noPad}
                parentId={props.parentId}
                snapRows={snapRows}
                widgetId={props.widgetId}
                // widgetType={props.type}
              />
              <CanvasSelectionArena
                {...this.getSnapSpaces()}
                canExtend={props.canExtend}
                dropDisabled={!!props.dropDisabled}
                parentId={props.parentId}
                snapRows={snapRows}
                widgetId={props.widgetId}
              />

              <WidgetsMultiSelectBox
                {...this.getSnapSpaces()}
                noContainerOffset={!!props.noContainerOffset}
                widgetId={this.props.widgetId}
                widgetType={this.props.type}
              />
            </>
          )}

        {/* without the wrapping div onClick events are triggered twice */}
        <>{this.renderChildren()}</>
      </ContainerComponent>
    );
  }

  getWidgetView() {
    return this.renderAsContainerComponent(this.props);
  }
}

interface ScreenProps {
  isScreen?: boolean;
}

export interface ScreenContainerWidgetProps<T extends WidgetProps>
  extends ScreenProps,
    WidgetProps {
  children?: T[];
  containerStyle?: ContainerStyle;
  shouldScrollContents?: boolean;
  noPad?: boolean;
  // 视频相关props
  isVideo?: boolean;
  video?: string;
}

export default ScreenContainerComWidget;
