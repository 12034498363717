import PropertyControlRegistry from "../PropertyControlRegistry";
import momento from "moment";
// import WidgetFactory from "WidgetProvider/factory";
import { registerWidgets } from "WidgetProvider/factory/registrationHelper";
import { registerLayoutComponents } from "layoutSystems/anvil/utils/layouts/layoutUtils";
import widgets from "widgets";

export const registerEditorWidgets = () => {
  registerWidgets(widgets);
};

export const editorInitializer = async () => {
  registerEditorWidgets();
  PropertyControlRegistry.registerPropertyControlBuilders();

  const { default: moment } = await import("moment-timezone");
  moment.defineLocale("zh-cn", momento.localeData()._config);
  moment.locale("zh-cn");
  moment.tz.setDefault(moment.tz.guess());
  // TODO: do this only for anvil.
  registerLayoutComponents();
};
