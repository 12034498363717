import React from "react";
import { CapsuleChart } from "@jiaminghi/data-view-react";
import styled from "styled-components"

const ChartWrapper = styled(CapsuleChart)`
  color: ${props => props.fontColor} !important;
  .unit-label {
    color: ${props => props.xAxisFontColor} !important;
  }
  .unit-text {
    color: ${props => props.xAxisFontColor} !important;
  }
  .label-column {
    color: ${props => props.yAxisFontColor} !important;
  }
  ${props => !props.showXAxisLabel ? `
  .unit-label, .unit-text {
    visibility: hidden !important;
  }
  ` : ""}
`
function CapsuleChartBarComponent(props: CapsuleChartBarComponentProps) {
  const config = {
    data: props.data,
    showValue: props.showValue,
    unit: props.unit,
    colors: props.colors,
  };
  return (
    <ChartWrapper
      config={config}
      xAxisFontColor={props.xAxisFontColor}
      yAxisFontColor={props.yAxisFontColor}
      fontColor={props.fontColor}
      fontSize={props.fontSize}
      showXAxisLabel={props.showXAxisLabel}
      style={{ width: "100%", height: "100%" }} />
  );
}

export interface CapsuleChartBarComponentProps {
  data: any;
  showValue: boolean;
  showXAxisLabel: boolean;
  unit: any;
  colors: any;
  fontColor: string;
  fontSize: string;
  xAxisFontColor: string;
  yAxisFontColor: string
}

export default CapsuleChartBarComponent;
