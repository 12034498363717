import type { RefObject } from "react";
import React, { createRef } from "react";
import { sortBy } from "lodash";
import {
  Alignment,
  Icon,
  // Menu,
  // MenuItem,
  Classes as CoreClass,
  Spinner,
} from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import type { IconName } from "@blueprintjs/icons";
import tinycolor from "tinycolor2";
import { darkenActive, darkenHover } from "constants/DefaultTheme";
import type {
  ButtonStyleType,
  ButtonVariant,
  ButtonPlacement,
} from "components/constants";
import { ButtonVariantTypes } from "components/constants";
import styled, { createGlobalStyle } from "styled-components";
import {
  getCustomBackgroundColor,
  getCustomBorderColor,
  getCustomJustifyContent,
  getComplementaryGrayscaleColor,
  calculateHoverColor,
} from "widgets/WidgetUtils";
import type { RenderMode } from "constants/WidgetConstants";
import { RenderModes } from "constants/WidgetConstants";
import { DragContainer } from "widgets/ButtonWidget/component/DragContainer";
import { buttonHoverActiveStyles } from "../../ButtonWidget/component/utils";
import { THEMEING_TEXT_SIZES } from "constants/ThemeConstants";
import type { ThemeProp } from "WidgetProvider/constants";
import {
  ConfigProvider,
  Button as AntdButton,
  Dropdown,
  MenuProps,
  Button,
  DropdownProps,
  theme,
  Menu,
} from "antd";
import AntdIcon from "components/common/AntdIcon";
import { addOverlayEffect } from "pages/utils";
import _ from "lodash";
import { getIconParams } from "utils/widgetIcon";

// Utility functions
interface ButtonData {
  id?: string;
  type?: string;
  label?: string;
  iconName?: any;
}
// Extract props influencing to width change
const getButtonData = (
  groupButtons: Record<string, GroupButtonProps>,
): ButtonData[] => {
  const buttonData = Object.keys(groupButtons).reduce(
    (acc: ButtonData[], id) => {
      return [
        ...acc,
        {
          id,
          type: groupButtons[id].buttonType,
          label: groupButtons[id].label,
          iconName: groupButtons[id].iconName,
        },
      ];
    },
    [],
  );

  return buttonData as ButtonData[];
};

interface WrapperStyleProps {
  isHorizontal: boolean;
  borderRadius?: string;
  boxShadow?: string;
  buttonVariant: ButtonVariant;
}

const ButtonGroupWrapper = styled.div<ThemeProp & WrapperStyleProps>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  cursor: not-allowed;
  gap: ${({ buttonVariant }) =>
    `${buttonVariant === ButtonVariantTypes.PRIMARY ? "1px" : "0px"}`};

  ${(props) =>
    props.isHorizontal ? "flex-direction: row" : "flex-direction: column"};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ borderRadius }) => borderRadius};

  & > *:first-child,
  & > *:first-child button {
    border-radius: ${({ borderRadius, isHorizontal }) =>
      isHorizontal
        ? `${borderRadius} 0px 0px ${borderRadius}`
        : `${borderRadius} ${borderRadius} 0px 0px`};
  }

  & > *:last-child,
  & > *:last-child button {
    border-radius: ${({ borderRadius, isHorizontal }) =>
      isHorizontal
        ? `0px ${borderRadius} ${borderRadius} 0`
        : `0px 0px ${borderRadius} ${borderRadius}`};
  }
`;

const MenuButtonWrapper = styled.div<{ renderMode: RenderMode }>`
  flex: 1 1 auto;
  cursor: pointer;
  position: relative;

  ${({ renderMode }) => renderMode === RenderModes.CANVAS && `height: 100%`};

  & > .${Classes.POPOVER2_TARGET} > button {
    width: 100%;
    height: 100%;
  }

  & > .${Classes.POPOVER2_TARGET} {
    height: 100%;
  }
`;

const PopoverStyles = createGlobalStyle<{
  minPopoverWidth: number;
  popoverTargetWidth?: number;
  id: string;
  borderRadius?: string;
}>`
  ${({ borderRadius, id, minPopoverWidth, popoverTargetWidth }) => `
    .${id}.${Classes.POPOVER2} {
      background: none;
      box-shadow: 0 6px 20px 0px rgba(0, 0, 0, 0.15) !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      border-radius: ${
        borderRadius === THEMEING_TEXT_SIZES.lg ? `0.375rem` : borderRadius
      };
      box-shadow: none;
      overflow: hidden;
      ${popoverTargetWidth && `width: ${popoverTargetWidth}px`};
      min-width: ${minPopoverWidth}px;
    }

    .button-group-menu-popover > .${Classes.POPOVER2_CONTENT} {
      background: none;
    }
  `}
`;

interface ButtonStyleProps {
  isHorizontal: boolean;
  borderRadius?: string;
  buttonVariant?: ButtonVariant; // solid | outline | ghost
  buttonColor?: string;
  iconAlign?: string;
  placement?: ButtonPlacement;
  isLabel: boolean;
  minWidth?: number;
  minHeight?: number;
}

const StyledButtonContent = styled.div<{
  iconAlign: string;
  placement?: ButtonPlacement;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ placement }) => getCustomJustifyContent(placement)};
  flex-direction: ${({ iconAlign }) =>
    iconAlign === Alignment.RIGHT ? "row-reverse" : "row"};
  padding: 8px;
`;

export interface BaseStyleProps {
  backgroundColor?: string;
  borderRadius?: string;
  boxShadow?: string;
  buttonColor?: string;
  buttonStyle?: ButtonStyleType;
  buttonVariant?: ButtonVariant;
  textColor?: string;
}

const BaseMenuItem = styled(Menu.Item)<ThemeProp & BaseStyleProps>`
  padding: 8px 10px !important;
  // border-radius: 0px;
  ${({ backgroundColor, theme }) =>
    backgroundColor
      ? `
      background-color: ${backgroundColor} !important;
      &:hover, &:focus {
        background-color: ${darkenHover(backgroundColor)} !important;
      }
      &:active {
        background-color: ${darkenActive(backgroundColor)} !important;
      }
  `
      : `
    background: none !important
      &:hover, &:focus {
        background-color: ${tinycolor(
          theme.colors.button.primary.primary.textColor,
        )
          .darken()
          .toString()} !important;
      }
      &:active {
        background-color: ${tinycolor(
          theme.colors.button.primary.primary.textColor,
        )
          .darken()
          .toString()} !important;
      }
    `}
  ${({ textColor }) =>
    textColor &&
    `
      color: ${textColor} !important;
  `}
`;

const StyledMenu = styled(Menu)`
  padding: 0;
  min-width: 0px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  .ant-btn-icon {
    display: inline-block;
    width: 100%;
  }
`;

interface PopoverContentProps {
  buttonId: string;
  menuItems: Record<
    string,
    {
      widgetId: string;
      id: string;
      index: number;
      isVisible?: boolean;
      isDisabled?: boolean;
      label?: string;
      backgroundColor?: string;
      textColor?: string;
      iconName?: any;
      iconColor?: string;
      iconAlign?: Alignment;
      onClick?: string;
    }
  >;
  onItemClicked: (onClick: string | undefined, buttonId: string) => void;
}

class ButtonGroupComponent extends React.Component<
  ButtonGroupComponentProps,
  ButtonGroupComponentState
> {
  private timer?: number;

  constructor(props: ButtonGroupComponentProps) {
    super(props);
    this.state = {
      itemRefs: {},
      itemWidths: {},
      loadedBtnId: "",
    };
  }

  componentDidMount() {
    this.setState(() => {
      return {
        ...this.state,
        itemRefs: this.createMenuButtonRefs(),
      };
    });

    this.timer = setTimeout(() => {
      this.setState(() => {
        return {
          ...this.state,
          itemWidths: this.getMenuButtonWidths(),
        };
      });
    }, 0);
  }

  componentDidUpdate(
    prevProps: ButtonGroupComponentProps,
    prevState: ButtonGroupComponentState,
  ) {
    if (
      this.state.itemRefs !== prevState.itemRefs ||
      this.props.width !== prevProps.width ||
      this.props.orientation !== prevProps.orientation
    ) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.setState(() => {
          return {
            ...this.state,
            itemWidths: this.getMenuButtonWidths(),
          };
        });
      });
    } else {
      // Reset refs array if
      // * A button is added/removed or changed into a menu button
      // * A label is changed or icon is newly added or removed
      let isWidthChanged = false;
      const buttons = getButtonData(this.props.groupButtons);
      const menuButtons = buttons.filter((button) => button.type === "MENU");
      const prevButtons = getButtonData(prevProps.groupButtons);
      const prevMenuButtons = prevButtons.filter(
        (button) => button.type === "MENU",
      );

      if (buttons.length !== prevButtons.length) {
        isWidthChanged = true;
      } else if (menuButtons.length > prevMenuButtons.length) {
        isWidthChanged = true;
      } else {
        isWidthChanged = buttons.some((button) => {
          const prevButton = prevButtons.find((btn) => btn.id === button.id);

          return (
            button.label !== prevButton?.label ||
            (button.iconName && !prevButton?.iconName) ||
            (!button.iconName && prevButton?.iconName)
          );
        });
      }

      if (isWidthChanged) {
        this.setState(() => {
          return {
            ...this.state,
            itemRefs: this.createMenuButtonRefs(),
          };
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  // Get widths of menu buttons
  getMenuButtonWidths = () =>
    Object.keys(this.props.groupButtons).reduce((acc, id) => {
      if (this.props.groupButtons[id].buttonType === "MENU") {
        return {
          ...acc,
          [id]: this.state.itemRefs[id].current?.getBoundingClientRect().width,
        };
      }
      return acc;
    }, {});

  // Create refs of menu buttons
  createMenuButtonRefs = () =>
    Object.keys(this.props.groupButtons).reduce((acc, id) => {
      if (this.props.groupButtons[id].buttonType === "MENU") {
        return {
          ...acc,
          [id]: createRef(),
        };
      }
      return acc;
    }, {});

  // Start Loading
  handleActionStart = (id: string) => {
    this.setState({
      loadedBtnId: id,
    });
  };

  // Stop Loading
  handleActionComplete = () => {
    this.setState({
      loadedBtnId: "",
    });
  };

  onButtonClick = (onClick: string | undefined, buttonId: string) => {
    if (onClick) {
      this.handleActionStart(buttonId);
      this.props.buttonClickHandler(onClick, () => this.handleActionComplete());
    }
  };

  render = () => {
    const {
      buttonVariant,
      groupButtons,
      isDisabled,
      minPopoverWidth,
      orientation,
      widgetId,
    } = this.props;
    const { loadedBtnId } = this.state;
    const isHorizontal = orientation === "horizontal";

    let buttonsItems = Object.keys(groupButtons)
      .map((itemKey) => groupButtons[itemKey])
      .filter((item) => item.isVisible === true);
    // sort btns by index
    buttonsItems = sortBy(buttonsItems, ["index"]);
    const popoverId = `button-group-${widgetId}`;

    const getOnClick = (button: GroupButtonProps) => {
      if (!button.onClick) return;
      return () => {
        this.onButtonClick(button.onClick, button.id);
      };
    };

    const AntdButtonStyleTypes: Record<any, string> = {
      PRIMARY: "primary",
      SECONDARY: "default",
      TERTIARY: "text",
    };

    const contentStyle: React.CSSProperties = {};

    const darkenBackgroundColor = (color: string, amount: number) => {
      const darken = (c: number) => Math.max(c - amount * 255, 0);
      const colorValue = color.slice(1); // Remove '#'
      const num = parseInt(colorValue, 16);
      const r = darken((num >> 16) & 255);
      const g = darken((num >> 8) & 255);
      const b = darken(num & 255);
      return `rgb(${r}, ${g}, ${b})`;
    };

    // const CustomMenuItem = styled.div<{
    //   backgroundColor?: string;
    //   textColor?: string;
    //   key: string;
    //   disabled?: boolean;
    //   isVisible?: boolean;
    // }>`
    //   background-color: ${(props) => props.backgroundColor};
    //   padding: 8px 10px !important;
    //   border-radius: 0px;
    //   transition: background-color 0.3s;
    //   cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    //   &:hover {
    //     background-color: ${(props) =>
    //       props.backgroundColor
    //         ? darkenBackgroundColor(props.backgroundColor, 0.1)
    //         : "rgba(0, 0, 0, 0.06)"};
    //   }
    // `;

    const StyledText = styled.pre<{
      disabled?: boolean;
      iconName?: any;
      iconAlign?: string;
      textColor?: string;
      hoverTextColor?: string;
    }>`
      color: ${({ disabled, textColor }) =>
        disabled ? "rgba(0, 0, 0, 0.25)" : textColor};
      margin-left: ${({ iconName, iconAlign }) =>
        iconName && iconAlign === "left" ? "8px" : 0};
      margin-right: ${({ iconName, iconAlign }) =>
        iconName && iconAlign === "right" ? "8px" : 0};
      &:hover {
        // color: ${(props) => props.hoverTextColor} !important;
      }
    `;

    const CustomMenuItem = styled.div<{
      backgroundColor?: string;
      textColor?: string;
      key: string;
      disabled?: boolean;
      isVisible?: boolean;
      hoverBackgroundColor?: string;
      hoverTextColor?: string;
    }>`
      background-color: ${(props) =>
        !props.disabled ? props.backgroundColor : "transparent"};
      padding: 8px 10px !important;
      border-radius: 4px;
      transition: background-color 0.3s;
      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
      &:hover {
        background-color: ${(props) =>
          !props.disabled
            ? (props.hoverBackgroundColor && props.backgroundColor) ||
              props.hoverBackgroundColor
              ? props.hoverBackgroundColor
              : props.backgroundColor
                ? darkenBackgroundColor(props.backgroundColor, 0.1)
                : "rgba(0, 0, 0, 0.04)"
            : "transparent"};
        border-radius: 4px;
        ${StyledText} {
          color: ${(props) =>
            !props.disabled
              ? props.hoverTextColor
              : "rgba(0, 0, 0, 0.25)"} !important;
        }
      }
    `;

    const renderLabelWithIcon = (
      label?: any,
      iconName?: any,
      iconColor?: string,
      iconAlign?: string,
      onClick?: string | undefined,
      key?: string,
      disabled?: boolean,
      textColor?: string,
      hoverTextColor?: string,
    ) => {
      const iconObj = getIconParams(iconName);
        // iconName === "" || iconName === undefined
        //   ? { iconName: "", type: "antd", category: "direction" }
        //   : _.isString(iconName)
        //     ? JSON.parse(iconName)
        //     : iconName;
      const iconElement =
        // <AntdIcon
        //   style={{
        // color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
        // fontSize: "18px",
        //   }}
        //   type={iconName || ""}
        // />
        iconObj.type === "appsmith" ? (
          <Icon
            icon={iconObj.iconName}
            style={{
              color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
            }}
          />
        ) : (
          <AntdIcon
            type={iconObj.iconName ?? ""}
            style={{
              color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
              fontSize: "18px",
            }}
          />
        );
      if (iconObj.iconName) {
        return iconAlign === Alignment.RIGHT ? (
          <div
            className="flex items-center justify-between"
            onClick={() => {
              if (disabled) {
                return;
              }
              this.onButtonClick(onClick, key as string);
            }}
          >
            <StyledText
              disabled={disabled}
              iconName={iconObj.iconName}
              iconAlign={iconAlign || "left"}
              textColor={textColor}
              hoverTextColor={hoverTextColor}
            >
              {label}
            </StyledText>
            {iconElement}
          </div>
        ) : (
          <div
            className="flex items-center"
            onClick={() => {
              if (disabled) {
                return;
              }
              this.onButtonClick(onClick, key as string);
            }}
          >
            {iconElement}
            <StyledText
              disabled={disabled}
              iconName={iconObj.iconName}
              iconAlign={iconAlign || "left"}
              textColor={textColor}
              hoverTextColor={hoverTextColor}
            >
              {label}
            </StyledText>
          </div>
        );
      }

      return (
        <div
          onClick={() => {
            if (disabled) {
              return;
            }
            this.onButtonClick(onClick, key as string);
          }}
        >
          <StyledText
            disabled={disabled}
            iconName={iconObj.iconName}
            iconAlign={iconAlign || "left"}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
          >
            {label}
          </StyledText>
        </div>
      );
    };

    return (
      <ButtonGroupWrapper
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        buttonVariant={this.props.buttonVariant}
        className="t--buttongroup-widget"
        isHorizontal={isHorizontal}
      >
        {buttonsItems.map((button) => {
          const isLoading = button.id === loadedBtnId;
          const isButtonDisabled =
            button.isDisabled || isDisabled || !!loadedBtnId || isLoading;
          if (button.buttonType === "MENU" && !isButtonDisabled) {
            const { menuItems, menuPlacement } = button;
            const items = Object.values(menuItems).map((it) => {
              return {
                key: it.id,
                label: <div>{it.label}</div>,
                backgroundColor: it.backgroundColor,
                disabled: it.isDisabled,
                isVisible: it.isVisible,
                iconName: it.iconName,
                textColor: it.textColor,
                iconColor: it.iconColor,
                iconAlign: it.iconAlign,
                onClick: it.onClick,
                hoverBackgroundColor: it.hoverBackgroundColor,
                hoverTextColor: it.hoverTextColor,
              };
            });
            const iconObj = getIconParams(button.iconName);
              // button.iconName === "" || button.iconName === undefined
              //   ? { iconName: "", type: "antd", category: "direction" }
              //   : _.isString(button.iconName)
              //     ? JSON.parse(button.iconName)
              //     : button.iconName;
            return (
              <ButtonContainer key={button.id}>
                <ConfigProvider
                  key={button.id}
                  theme={{
                    token: {
                      colorPrimary: button.buttonColor,
                      colorBgTextActive: addOverlayEffect(
                        calculateHoverColor(button.buttonColor),
                        0.5,
                      ),
                      colorTextLightSolid:
                        buttonVariant === ButtonVariantTypes.PRIMARY
                          ? getComplementaryGrayscaleColor(button.buttonColor)
                          : addOverlayEffect(
                              calculateHoverColor(button.buttonColor),
                              0.5,
                            ),
                      colorText:
                        buttonVariant === ButtonVariantTypes.TERTIARY
                          ? addOverlayEffect(
                              calculateHoverColor(button.buttonColor),
                              0.2,
                            )
                          : buttonVariant !== ButtonVariantTypes.SECONDARY
                            ? button.buttonColor
                            : "initial",
                      // controlItemBgHover: "rgba(0, 0, 0, 0.04)",
                    },
                    components: {
                      Button: {
                        fontWeight: 600,
                        textHoverBg: addOverlayEffect(
                          calculateHoverColor(button.buttonColor),
                          0.8,
                        ),
                        contentFontSize: 15,
                        paddingBlock: 10,
                      },
                    },
                  }}
                >
                  <Dropdown
                    key={button.id}
                    menu={{ items }}
                    placement={menuPlacement}
                    trigger={["click"]}
                    // overlayStyle={{ backgroundColor: "#fff" }}
                    dropdownRender={(menu) => {
                      return (
                        <div className="ant-dropdown-menu" style={contentStyle}>
                          {items.map((i) => {
                            if (i.isVisible) {
                              return (
                                <CustomMenuItem
                                  key={i.key}
                                  disabled={i.disabled}
                                  backgroundColor={i.backgroundColor}
                                  textColor={i.textColor}
                                  isVisible={i.isVisible}
                                >
                                  {renderLabelWithIcon(
                                    i.label,
                                    i.iconName,
                                    i.iconColor,
                                    i.iconAlign,
                                    i.onClick,
                                    i.key,
                                    i.disabled,
                                    i.textColor,
                                    i.hoverTextColor,
                                  )}
                                </CustomMenuItem>
                              );
                            }
                          })}
                        </div>
                      );
                    }}
                  >
                    <AntdButton
                      disabled={isDisabled}
                      type={
                        AntdButtonStyleTypes[
                          buttonVariant === undefined
                            ? "PRIMARY"
                            : buttonVariant
                        ] as
                          | "link"
                          | "dashed"
                          | "text"
                          | "default"
                          | "primary"
                          | undefined
                      }
                      onClick={getOnClick(button)}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: `${this.props.borderRadius}`,
                        boxShadow: `${this.props.boxShadow}`,
                      }}
                      autoInsertSpace={false}
                      icon={
                        <StyledButtonContent
                          iconAlign={button.iconAlign || "left"}
                          placement={button.placement}
                        >
                          {isLoading ? (
                            <Spinner size={18} />
                          ) : (
                            <>
                              {/* {button.iconName && (
                                <AntdIcon type={button.iconName} />
                              )} */}
                              {iconObj.iconName &&
                                // <AntdIcon
                                //   type={iconObj.iconName}
                                //   style={{ fontSize: "18px" }}
                                // />
                                (iconObj.type === "appsmith" ? (
                                  <Icon icon={iconObj.iconName} />
                                ) : (
                                  <AntdIcon
                                    type={iconObj.iconName ?? ""}
                                    style={{ fontSize: `18px` }}
                                  />
                                ))}
                              {!!button.label && (
                                <pre
                                  style={{
                                    marginLeft:
                                      iconObj.iconName &&
                                      (button.iconAlign || "left") === "left"
                                        ? "8px"
                                        : 0,
                                    marginRight:
                                      iconObj.iconName &&
                                      (button.iconAlign || "left") === "right"
                                        ? "8px"
                                        : 0,
                                  }}
                                >
                                  {button.label}
                                </pre>
                              )}
                            </>
                          )}
                        </StyledButtonContent>
                      }
                    ></AntdButton>
                  </Dropdown>
                </ConfigProvider>
              </ButtonContainer>
            );
          }
          const iconObj = getIconParams(button.iconName);
            // button.iconName === "" || button.iconName === undefined
            //   ? { iconName: "", type: "antd", category: "direction" }
            //   : _.isString(button.iconName)
            //     ? JSON.parse(button.iconName)
            //     : button.iconName;
          return (
            <ButtonContainer key={button.id}>
              <ConfigProvider
                key={button.id}
                theme={{
                  token: {
                    colorPrimary: button.buttonColor,
                    colorBgTextActive: addOverlayEffect(
                      calculateHoverColor(button.buttonColor),
                      0.5,
                    ),
                    colorTextLightSolid:
                      buttonVariant === ButtonVariantTypes.PRIMARY
                        ? getComplementaryGrayscaleColor(button.buttonColor)
                        : addOverlayEffect(
                            calculateHoverColor(button.buttonColor),
                            0.5,
                          ),
                    colorText:
                      buttonVariant === ButtonVariantTypes.TERTIARY
                        ? addOverlayEffect(
                            calculateHoverColor(button.buttonColor),
                            0.2,
                          )
                        : buttonVariant !== ButtonVariantTypes.SECONDARY
                          ? button.buttonColor
                          : "initial",
                  },
                  components: {
                    Button: {
                      // fontWeight: 600,
                      textHoverBg: addOverlayEffect(
                        calculateHoverColor(button.buttonColor),
                        0.8,
                      ),
                      contentFontSize: 15,
                    },
                  },
                }}
              >
                <AntdButton
                  disabled={isButtonDisabled}
                  type={
                    AntdButtonStyleTypes[
                      buttonVariant === undefined ? "PRIMARY" : buttonVariant
                    ] as
                      | "link"
                      | "dashed"
                      | "text"
                      | "default"
                      | "primary"
                      | undefined
                  }
                  onClick={getOnClick(button)}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: `${this.props.borderRadius}`,
                    boxShadow: `${this.props.boxShadow}`,
                  }}
                  icon={
                    <StyledButtonContent
                      iconAlign={button.iconAlign || "left"}
                      placement={button.placement}
                    >
                      {isLoading ? (
                        <Spinner size={18} />
                      ) : (
                        <>
                          {iconObj.iconName &&
                            // <AntdIcon
                            //   type={iconObj.iconName}
                            //   style={{ fontSize: "18px" }}
                            // />
                            (iconObj.type === "appsmith" ? (
                              <Icon icon={iconObj.iconName} />
                            ) : (
                              <AntdIcon
                                type={iconObj.iconName ?? ""}
                                style={{ fontSize: `18px` }}
                              />
                            ))}
                          {!!button.label && (
                            <pre
                              style={{
                                marginLeft:
                                  button.iconName &&
                                  (button.iconAlign || "left") === "left"
                                    ? "8px"
                                    : 0,
                                marginRight:
                                  button.iconName &&
                                  (button.iconAlign || "left") === "right"
                                    ? "8px"
                                    : 0,
                              }}
                            >
                              {button.label}
                            </pre>
                          )}
                        </>
                      )}
                    </StyledButtonContent>
                  }
                ></AntdButton>
              </ConfigProvider>
            </ButtonContainer>
          );
        })}
      </ButtonGroupWrapper>
    );
  };
}

interface GroupButtonProps {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  label?: string;
  buttonType?: string;
  buttonColor?: string;
  iconName?: any;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  onClick?: string;
  menuPlacement?:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | "top"
    | "bottom"
    | undefined;
  menuItems: Record<
    string,
    {
      widgetId: string;
      id: string;
      index: number;
      isVisible?: boolean;
      isDisabled?: boolean;
      label?: string;
      backgroundColor?: string;
      textColor?: string;
      iconName?: any;
      iconColor?: string;
      iconAlign?: Alignment;
      onClick?: string;
      hoverTextColor?: string;
      hoverBackgroundColor?: string;
      menuPlacement?: string;
    }
  >;
}

export interface ButtonGroupComponentProps {
  borderRadius?: string;
  boxShadow?: string;
  buttonVariant: ButtonVariant;
  buttonClickHandler: (
    onClick: string | undefined,
    callback: () => void,
  ) => void;
  groupButtons: Record<string, GroupButtonProps>;
  isDisabled: boolean;
  orientation: string;
  renderMode: RenderMode;
  width: number;
  minPopoverWidth: number;
  widgetId: string;
  buttonMinWidth?: number;
  minHeight?: number;
}

export interface ButtonGroupComponentState {
  itemRefs: Record<string, RefObject<HTMLButtonElement>>;
  itemWidths: Record<string, number>;
  loadedBtnId: string;
}

export default ButtonGroupComponent;
