import type { ChangeEvent, ReactNode } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { TreeSelectProps as SelectProps } from "rc-tree-select";
// import TreeSelect from "rc-tree-select";
import {
  TreeSelectContainer,
  StyledIcon,
  InputContainer,
} from "./index.styled";
import "rc-tree-select/assets/index.less";
import type { DefaultValueType } from "rc-tree-select/lib/interface";
import type { TreeNodeProps } from "rc-tree-select/lib/TreeNode";
import type { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";
import styled from "styled-components";
import type { RenderMode, TextSize } from "constants/WidgetConstants";
import type { Alignment } from "@blueprintjs/core";
import { Button, Classes, InputGroup } from "@blueprintjs/core";
import { labelMargin, WidgetContainerDiff } from "widgets/WidgetUtils";
import { Icon } from "@design-system/widgets-old";
import { Colors } from "constants/Colors";
import type { LabelPosition } from "components/constants";
import useDropdown from "widgets/useDropdown";
import LabelWithTooltip from "widgets/components/LabelWithTooltip";
import { isEmpty, isNil } from "lodash";
import { ConfigProvider, TreeSelect } from "antd";
import {
  CloseCircleFilled,
  createFromIconfontCN,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

export interface TreeSelectProps
  extends Required<
    Pick<
      SelectProps,
      "disabled" | "placeholder" | "loading" | "dropdownStyle" | "allowClear"
    >
  > {
  value?: DefaultValueType;
  onChange: (value?: DefaultValueType, labelList?: ReactNode[]) => void;
  expandAll: boolean;
  labelText: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  onDropdownVisibleChange?: (val: boolean) => void;
  onDropdownClose?: () => void;
  labelStyle?: string;
  labelTooltip?: string;
  compactMode: boolean;
  dropDownWidth: number;
  width: number;
  isValid: boolean;
  isDynamicHeightEnabled: boolean;
  borderRadius: string;
  boxShadow?: string;
  boxHeight?: "small" | "middle" | "large" | undefined;
  accentColor: string;
  widgetId: string;
  filterText?: string;
  isFilterable: boolean;
  renderMode?: RenderMode;
  options?: DefaultOptionType[];
  isRequired?: boolean;
  showColon?: boolean;
  onSelect?: () => void;
}

export const NoDataFoundContainer = styled.div`
  text-align: center;
`;

const getSvg = (expanded: boolean) => (
  <i
    style={{
      cursor: "pointer",
      backgroundColor: "transparent",
      display: "inline-flex",
      width: "14px",
      height: "100%",
    }}
  >
    <StyledIcon
      className="switcher-icon"
      expanded={expanded}
      fillColor={Colors.GREY_10}
      name="dropdown"
    />
  </i>
);

const switcherIcon = (treeNode: TreeNodeProps) => {
  if (treeNode.isLeaf) {
    return (
      <i
        style={{
          cursor: "pointer",
          backgroundColor: "white",
          display: "inline-flex",
          width: "14px",
        }}
      />
    );
  }
  return getSvg(treeNode.expanded);
};

function AntdSingleSelectTreeComponent({
  accentColor,
  allowClear,
  borderRadius,
  boxHeight,
  boxShadow,
  compactMode,
  disabled,
  dropdownStyle,
  dropDownWidth,
  expandAll,
  filterText,
  isDynamicHeightEnabled,
  isFilterable,
  isRequired,
  isValid,
  labelAlignment,
  labelPosition,
  labelStyle,
  labelText,
  labelTextColor,
  labelTextSize,
  labelTooltip,
  labelWidth,
  loading,
  onChange,
  onDropdownClose,
  onDropdownVisibleChange,
  options,
  placeholder,
  renderMode,
  showColon,
  value,
  widgetId,
  width,
}: TreeSelectProps): JSX.Element {
  const [keys, setKeys] = useState<any>([]);
  const [filter, setFilter] = useState(filterText ?? "");

  const labelRef = useRef<HTMLDivElement>(null);
  const _menu = useRef<HTMLElement | null>(null);
  const [memoDropDownWidth, setMemoDropDownWidth] = useState(0);

  const extractValues = (data, result) => {
    data.forEach((item) => {
      if (item.value) {
        result.push(item.value);
      }
      if (item.children) {
        extractValues(item.children, result);
      }
    });
  };

  const extractValuesInversion = (data, result) => {
    data.forEach((item) => {
      if (!item.children) {
        result.push(item.value);
      }
    });
  };

  let resultArray: any[] = [];

  // treeDefaultExpandAll is uncontrolled after first render,
  // using this to force render to respond to changes in expandAll
  useEffect(() => {
    if (expandAll) {
      resultArray = [];
      extractValues(options, resultArray);
      setKeys(resultArray);
    } else {
      resultArray = [];
      extractValuesInversion(options, resultArray);
      setKeys(resultArray);
    }
  }, [expandAll]);

  const onSelectionChange = useCallback(
    (value?: DefaultValueType, labelList?: ReactNode[]) => {
      if (value !== undefined) {
        setFilter("");
        onChange(value, labelList);
      } else {
        onChange("", []);
      }
    },
    [],
  );

  useEffect(() => {
    const parentWidth = width - WidgetContainerDiff;
    if (compactMode && labelRef.current) {
      const labelWidth = labelRef.current.getBoundingClientRect().width;
      const widthDiff = parentWidth - labelWidth - labelMargin;
      setMemoDropDownWidth(
        widthDiff > dropDownWidth ? widthDiff : dropDownWidth,
      );
      return;
    }
    setMemoDropDownWidth(
      parentWidth > dropDownWidth ? parentWidth : dropDownWidth,
    );
  }, [compactMode, dropDownWidth, width, labelText]);

  const onTreeExpand = (expandedKeys: any) => {
    setKeys(expandedKeys);
  };

  const allowClearMemo = useMemo(
    () => allowClear && !isNil(value) && value !== "",
    [allowClear, value],
  );

  const onQueryChange = useCallback((value: string) => {
    setFilter(value);
  }, []);

  const memoValue = useMemo(() => (value !== "" ? value : undefined), [value]);

  return (
    <TreeSelectContainer
      accentColor={accentColor}
      allowClear={allowClear}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      compactMode={compactMode}
      data-testid="treeselect-container"
      isValid={isValid}
      labelPosition={labelPosition}
      ref={_menu as React.RefObject<HTMLDivElement>}
    >
      {labelText && (
        <LabelWithTooltip
          alignment={labelAlignment}
          className={`tree-select-label`}
          color={labelTextColor}
          compact={compactMode}
          cyHelpTextClassName="tree-select-tooltip"
          disabled={disabled}
          fontSize={labelTextSize}
          fontStyle={labelStyle}
          helpText={labelTooltip}
          isDynamicHeightEnabled={isDynamicHeightEnabled}
          isRequired={isRequired}
          loading={loading}
          position={labelPosition}
          ref={labelRef}
          showColon={showColon}
          text={labelText}
          width={labelWidth}
        />
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: accentColor,
            borderRadius,
          },
        }}
      >
        <TreeSelect
          allowClear={allowClear}
          style={{ width: "100%" }}
          value={memoValue}
          status={!isValid ? "error" : ""}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          size={boxHeight}
          treeData={options}
          disabled={disabled}
          placeholder={placeholder}
          treeDefaultExpandAll={expandAll}
          onChange={onSelectionChange}
          onTreeExpand={onTreeExpand}
          treeExpandedKeys={keys}
          onDropdownVisibleChange={onDropdownVisibleChange}
          showArrow={true}
          showSearch={isFilterable}
          treeNodeFilterProp={"label"}
          filterTreeNode={(input: any, treeNode: any) => {
            return treeNode.label.includes(input);
          }}
          inputIcon={
            isFilterable && isEmpty(memoValue) ? (
              <SearchOutlined className="ant-select-suffix" />
            ) : (
              <DownOutlined className="ant-select-suffix" />
            )
          }
          onSearch={onQueryChange}
          clearIcon={<CloseCircleFilled />}
        />
      </ConfigProvider>
    </TreeSelectContainer>
  );
}

export default AntdSingleSelectTreeComponent;
