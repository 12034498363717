import React, { useEffect, useState, useRef } from "react";
import * as _ from "lodash";
import "echarts-liquidfill";

function LiquildBallComponent(props: LiquildBallComponentProps) {
  const {
    height: CANVAS_HEIGHT,
    sineColor,
    innerText,
    outterCircle,
    bottomCircle,
    percentCircle,
    lineWidth,
    range,
    data,
    waveupsp,
  } = props;
  const canvasRef = useRef<any>(null);
  const ctxRef = useRef<any>();
  const liquildRef = useRef<any>();
  const animationRef = useRef<any>();

  const CANVAS_WIDTH = CANVAS_HEIGHT; //

  // const oRange = document.getElementsByName("range")[0]; // 进度
  const M = Math;
  const Sin = M.sin;
  const Cos = M.cos;
  const PI = M.PI;
  const oW = CANVAS_WIDTH;
  const oH = CANVAS_HEIGHT;
  // 线宽
  // const lineWidth = 2; //@
  // 大半径
  const r = oW / 2; // @ wrap-R
  const cR = r - 10 * lineWidth;

  // 水波动画初始参数
  const axisLength = 2 * r - 16 * lineWidth; // Sin 图形长度
  const unit = axisLength / 9; // 波浪宽
  // const range = 0.4; // 浪幅 // @range
  let nowrange = range;
  const xoffset = 8 * lineWidth; // x 轴偏移量
  // const data: any = 0.5; // 数据量 @data
  let sp = 0; // 周期偏移量
  let nowdata = 0;
  // const waveupsp = 0.006; // 水波上涨速度 //@speed
  // 圆动画初始参数
  const arcStack = []; // 圆栈
  const bR = r - 8 * lineWidth;
  const soffset = -(PI / 2); // 圆动画起始位置
  // circleLock = true; // 起始动画锁

  // 获取圆动画轨迹点集
  for (let i = soffset; i < soffset + 2 * PI; i += 1 / (8 * PI)) {
    arcStack.push([r + bR * Cos(i), r + bR * Sin(i)]);
  }
  // 圆起始点
  const cStartPoint: any = arcStack.shift();

  function drawSine() {
    ctxRef.current.beginPath();
    ctxRef.current.save();
    const Stack = []; // 记录起始点和终点坐标
    for (let i = xoffset; i <= xoffset + axisLength; i += 20 / axisLength) {
      const x = sp + (xoffset + i) / unit;
      const y = Sin(x) * nowrange;
      const dx = i;
      const dy = 2 * cR * (1 - nowdata) + (r - cR) - unit * y;
      ctxRef.current.lineTo(dx, dy);
      Stack.push([dx, dy]);
    }
    // 获取初始点和结束点
    const startP = Stack[0];
    const endP = Stack[Stack.length - 1];
    ctxRef.current.lineTo(xoffset + axisLength, oW);
    ctxRef.current.lineTo(xoffset, oW);
    ctxRef.current.lineTo(startP[0], startP[1]);
    ctxRef.current.fillStyle = sineColor;
    ctxRef.current.fill();
    ctxRef.current.restore();
  }
  // 圈内文字
  function drawText() {
    ctxRef.current.globalCompositeOperation = "source-over";
    const size = 0.4 * cR;
    ctxRef.current.font = "bold " + size + "px Microsoft Yahei";
    const txt = (nowdata.toFixed(2) * 100).toFixed(0) + "%";
    // const fonty = r + size / 2;
    // const fontx = r - size * 0.8;
    ctxRef.current.fillStyle = innerText;
    ctxRef.current.textAlign = "center";
    ctxRef.current.fillText(txt, r + 5, r + 20);
  }
  //最外面淡黄色圈
  function drawCircle() {
    ctxRef.current.beginPath();
    ctxRef.current.lineWidth = 15;
    ctxRef.current.strokeStyle = outterCircle;
    ctxRef.current.arc(r, r, cR + 7, 0, 2 * Math.PI);
    ctxRef.current.stroke();
    ctxRef.current.restore();
  }

  //灰色圆圈
  function grayCircle() {
    ctxRef.current.beginPath();
    ctxRef.current.lineWidth = 10;
    ctxRef.current.strokeStyle = bottomCircle;
    ctxRef.current.arc(r, r, cR - 5, 0, 2 * Math.PI);
    ctxRef.current.stroke();
    ctxRef.current.restore();
    ctxRef.current.beginPath();
  }

  //橘黄色进度圈
  function orangeCircle() {
    ctxRef.current.beginPath();
    ctxRef.current.strokeStyle = percentCircle;
    //使用这个使圆环两端是圆弧形状
    ctxRef.current.lineCap = "round";
    ctxRef.current.arc(
      r,
      r,
      cR - 5,
      0 * (Math.PI / 180.0) - Math.PI / 2,
      nowdata * 360 * (Math.PI / 180.0) - Math.PI / 2,
    );
    ctxRef.current.stroke();
    ctxRef.current.save();
  }
  //裁剪中间水圈
  function clipCircle() {
    ctxRef.current.beginPath();
    ctxRef.current.arc(r, r, cR - 10, 0, 2 * Math.PI, false);
    ctxRef.current.clip();
  }
  //渲染canvas
  function render() {
    ctxRef.current.clearRect(0, 0, oW, oH);
    //最外面淡黄色圈
    drawCircle();
    //灰色圆圈
    grayCircle();
    //橘黄色进度圈
    orangeCircle();
    //裁剪中间水圈
    clipCircle();
    // 控制波幅
    if (data >= 0.85) {
      if (nowrange > range / 4) {
        const t = range * 0.01;
        nowrange -= t;
      }
    } else if (data <= 0.1) {
      if (nowrange < range * 1.5) {
        const t = range * 0.01;
        nowrange += t;
      }
    } else {
      if (nowrange <= range) {
        const t = range * 0.01;
        nowrange += t;
      }
      if (nowrange >= range) {
        const t = range * 0.01;
        nowrange -= t;
      }
    }
    if (data - nowdata > 0) {
      nowdata += waveupsp;
    }
    if (data - nowdata < 0) {
      nowdata -= waveupsp;
    }
    sp += 0.07;
    // 开始水波动画
    drawSine();
    // 写字
    drawText();
    animationRef.current = requestAnimationFrame(render);
  }

  useEffect(() => {
    if (ctxRef.current) {
      clearCanvas();
    }
    if (canvasRef.current) {
      new Promise((resolve) => {
        if (ctxRef.current) {
          clearCanvas();
        }
        resolve(1);
      })
        .then(() => {
          canvasRef.current.width = CANVAS_HEIGHT;
          canvasRef.current.height = CANVAS_HEIGHT;
          ctxRef.current = canvasRef.current.getContext("2d"); // 笔刷
          ctxRef.current.strokeStyle = "#1c86d1";
          ctxRef.current.moveTo(cStartPoint[0], cStartPoint[1]);
          initBrush();
        })
        .then((re) => {
          render();
          return re;
        });
    }
  }, [
    CANVAS_HEIGHT,
    sineColor,
    innerText,
    outterCircle,
    bottomCircle,
    percentCircle,
    lineWidth,
    range,
    data,
    waveupsp,
  ]);

  const initBrush = () => {
    ctxRef.current.beginPath();
    ctxRef.current.lineWidth = lineWidth;
  };

  const clearCanvas = () => {
    ctxRef.current.clearRect(0, 0, 500, 500);
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  };

  return (
    <div id="liquid-chart" ref={liquildRef} className="h-full w-full">
      <canvas ref={canvasRef}>当前浏览器不支持canvas 请升级！</canvas>
    </div>
  );
}

export interface LiquildBallComponentProps {
  height: any; // 高度
  width: any; // 宽度
  lineWidth: number; // 线宽
  range: number; // 浪幅
  data: number; // 比例数值
  waveupsp: number; // 水波上涨速度
  sineColor: string; // 水波颜色
  innerText: string; // 圈内文字
  outterCircle: string; // 外圈
  bottomCircle: string; // 外底圈颜色
  percentCircle: string; // 进度圈颜色
}

export default LiquildBallComponent;
