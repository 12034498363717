import React, { useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button, Icon, Position } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";

import type { ComponentProps } from "widgets/BaseComponent";
import type { RenderMode } from "constants/WidgetConstants";
import { RenderModes, WIDGET_PADDING } from "constants/WidgetConstants";
import _, { isEmpty } from "lodash";
import type { ButtonBorderRadius, ButtonVariant } from "components/constants";
import { ButtonVariantTypes } from "components/constants";
import {
  getCustomBackgroundColor,
  getCustomBorderColor,
  getCustomHoverColor,
  getComplementaryGrayscaleColor,
  calculateHoverColor,
} from "widgets/WidgetUtils";
import Interweave from "interweave";
import { Popover2 } from "@blueprintjs/popover2";
import type { ThemeProp } from "WidgetProvider/constants";
import { Button as AntdButton, ConfigProvider, Popover } from "antd";
import AntdIcon from "components/common/AntdIcon";
import { SearchOutlined } from "@ant-design/icons";
import { addOverlayEffect } from "pages/utils";
import { getIconParams } from "utils/widgetIcon";
const ToolTipWrapper = styled.div`
  height: 100%;
  && .bp3-popover2-target {
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
    }
  }
`;

const TooltipStyles = createGlobalStyle`
  .iconBtnTooltipContainer {
    .bp3-popover2-content {
      max-width: 350px;
      overflow-wrap: anywhere;
      padding: 10px 12px;
      border-radius: 0px;
    }
  }
`;

interface IconButtonContainerProps {
  disabled?: boolean;
  buttonColor?: string;
  buttonVariant?: ButtonVariant;
  hasOnClickAction?: boolean;
  renderMode: RenderMode;
}

const IconButtonContainer = styled.div<IconButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${({ renderMode }) =>
    renderMode === RenderModes.CANVAS &&
    `
  position: relative;
  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  `}

  ${({ buttonColor, buttonVariant, hasOnClickAction, renderMode, theme }) => `

  ${
    hasOnClickAction && renderMode === RenderModes.CANVAS
      ? `&:hover > button, &:active > button {
      background: ${
        getCustomHoverColor(theme, buttonVariant, buttonColor) !== "none"
          ? getCustomHoverColor(theme, buttonVariant, buttonColor)
          : buttonVariant === ButtonVariantTypes.SECONDARY
            ? theme.colors.button.primary.secondary.hoverColor
            : buttonVariant === ButtonVariantTypes.TERTIARY
              ? theme.colors.button.primary.tertiary.hoverColor
              : theme.colors.button.primary.primary.hoverColor
      } !important;
    }`
      : ""
  }
`}

  ${({ disabled }) => disabled && "cursor: not-allowed;"}
`;

export interface ButtonStyleProps {
  borderRadius?: ButtonBorderRadius;
  boxShadow?: string;
  buttonColor: string;
  buttonVariant?: ButtonVariant;
  dimension?: number;
  hasOnClickAction?: boolean;
  compactMode?: string;
  minWidth?: number;
  minHeight?: number;
}

export const StyledButton = styled((props) => (
  <Button
    {..._.omit(props, [
      "buttonVariant",
      "buttonStyle",
      "borderRadius",
      "boxShadow",
      "dimension",
      "hasOnClickAction",
      "compactMode",
    ])}
  />
))<ThemeProp & ButtonStyleProps>`
  background-image: none !important;
  height: ${({ dimension }) => (dimension ? `${dimension}px` : "auto")};
  width: ${({ dimension }) => (dimension ? `${dimension}px` : "auto")};
  min-height: ${({ compactMode }) =>
    compactMode === "SHORT" ? "24px" : "30px"};
  min-width: ${({ compactMode }) =>
    compactMode === "SHORT" ? "24px" : "30px"};
  font-size: ${({ compactMode }) =>
    compactMode === "SHORT" ? "12px" : "14px"};
  line-height: ${({ compactMode }) =>
    compactMode === "SHORT" ? "24px" : "28px"};

  ${({ minHeight, minWidth }) =>
    `&& {
      ${minWidth ? `min-width: ${minWidth}px;` : ""}
      ${minHeight ? `min-height: ${minHeight}px;` : ""}
    }
  `}

  ${({ buttonColor, buttonVariant, compactMode, hasOnClickAction, theme }) => `
    &:enabled {
      background: ${
        getCustomBackgroundColor(buttonVariant, buttonColor) !== "none"
          ? getCustomBackgroundColor(buttonVariant, buttonColor)
          : buttonVariant === ButtonVariantTypes.PRIMARY
            ? theme.colors.button.primary.primary.bgColor
            : "none"
      } !important;
    }

    ${
      hasOnClickAction
        ? `&:hover:enabled, &:active:enabled, &:focus:enabled {
        background: ${
          getCustomHoverColor(theme, buttonVariant, buttonColor) !== "none"
            ? getCustomHoverColor(theme, buttonVariant, buttonColor)
            : buttonVariant === ButtonVariantTypes.SECONDARY
              ? theme.colors.button.primary.secondary.hoverColor
              : buttonVariant === ButtonVariantTypes.TERTIARY
                ? theme.colors.button.primary.tertiary.hoverColor
                : theme.colors.button.primary.primary.hoverColor
        } !important;
      }`
        : ""
    }

    &:disabled {
      background: ${
        buttonVariant !== ButtonVariantTypes.TERTIARY
          ? "var(--wds-color-bg-disabled)"
          : "transparent"
      } !important;
      color: var(--wds-color-text-disabled) !important;
      pointer-events: none;
    }

    &&:disabled {
      border: ${
        buttonVariant === ButtonVariantTypes.SECONDARY
          ? "1px solid var(--wds-color-border-disabled)"
          : "none"
      } !important;
      background: ${
        buttonVariant !== ButtonVariantTypes.TERTIARY
          ? "var(--wds-color-bg-disabled)"
          : "transparent"
      } !important;
      color: var(--wds-color-text-disabled) !important;

      span {
        color: var(--wds-color-text-disabled) !important;
      }
    }

    border: ${
      getCustomBorderColor(buttonVariant, buttonColor) !== "none"
        ? `1px solid ${getCustomBorderColor(buttonVariant, buttonColor)}`
        : buttonVariant === ButtonVariantTypes.SECONDARY
          ? `1px solid ${theme.colors.button.primary.secondary.borderColor}`
          : "none"
    } !important;

    & > span {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      color: ${
        buttonVariant === ButtonVariantTypes.PRIMARY
          ? getComplementaryGrayscaleColor(buttonColor)
          : getCustomBackgroundColor(
                ButtonVariantTypes.PRIMARY,
                buttonColor,
              ) !== "none"
            ? getCustomBackgroundColor(ButtonVariantTypes.PRIMARY, buttonColor)
            : `${theme.colors.button.primary.secondary.textColor}`
      } !important;
    }

    & > span > svg {
      height: 100%;
      width: 100%;
      min-height:
        ${compactMode === "SHORT" ? "14px" : "16px"};
      min-width:
        ${compactMode === "SHORT" ? "14px" : "16px"};
    }
  `}

  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"} !important;
`;

const StyleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:hover {
    background-color: var(--ads-v2-color-bg-subtle) !important;
    border-radius: var(--ads-v2-border-radius) !important;
  }

  /* 添加选中效果 */
  &.active {
    background-color: var(--ads-v2-color-bg-muted) !important;
    border-radius: var(--ads-v2-border-radius) !important;
  }
`;

export interface IconButtonComponentProps extends ComponentProps {
  iconName?: any;
  buttonColor?: string;
  buttonVariant: ButtonVariant;
  borderRadius: string;
  boxShadow: string;
  isDisabled: boolean;
  isVisible: boolean;
  hasOnClickAction: boolean;
  onClick: () => void;
  renderMode: RenderMode;
  height: number;
  tooltip?: string;
  width: number;
  minHeight?: number;
  minWidth?: number;
}

const AntdButtonStyleTypes: Record<any, string> = {
  PRIMARY: "primary",
  SECONDARY: "default",
  TERTIARY: "text",
};

function IconButtonComponent(props: IconButtonComponentProps) {
  const {
    borderRadius,
    boxShadow,
    buttonColor,
    buttonVariant,
    hasOnClickAction,
    height,
    isDisabled,
    minHeight,
    minWidth,
    onClick,
    renderMode,
    tooltip,
    width,
    iconName,
  } = props;
  const iconObj = getIconParams(iconName);
  // iconName === "" || iconName === undefined
  //   ? { iconName: "", type: "antd", category: "tips" }
  //   : _.isString(iconName)
  //     ? JSON.parse(iconName)
  //     : iconName;

  const IconWithPadding = ({ iconName, dimension, padding }) => {
    const adjustedDimension = dimension - 2 * parseInt(padding, 10);
    return (
      <div style={{ padding }}>
        <Icon icon={iconName} iconSize={adjustedDimension} />
      </div>
    );
  };
  /**
   * returns the dimension to be used for widget
   * whatever is the minimum between width and height,
   * we will use that for the dimension of the widget
   */
  const dimension = useMemo(() => {
    if (width > height) {
      return height - WIDGET_PADDING * 2;
    }

    return width - WIDGET_PADDING * 2;
  }, [width, height]);

  const hasOnClick = !isDisabled && hasOnClickAction;
  const tooltipContent = () => {
    return !isEmpty(tooltip) ? (
      <div className="text-[#000]">{tooltip}</div>
    ) : (
      ""
    );
  };
  const iconBtnWrapper = (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: buttonColor,
          colorBgTextActive: addOverlayEffect(
            calculateHoverColor(buttonColor),
            0.5,
          ),
          colorTextLightSolid:
            buttonVariant === ButtonVariantTypes.PRIMARY
              ? getComplementaryGrayscaleColor(buttonColor)
              : addOverlayEffect(calculateHoverColor(buttonColor), 0.5),
          colorText:
            buttonVariant === ButtonVariantTypes.TERTIARY
              ? addOverlayEffect(calculateHoverColor(buttonColor), 0.2)
              : buttonColor,
        },
        components: {
          Button: {
            fontWeight: 600,
            textHoverBg: addOverlayEffect(
              calculateHoverColor(buttonColor),
              0.8,
            ),
            // contentFontSize: 15,
          },
        },
      }}
    >
      <Popover content={tooltipContent()}>
        <AntdButton
          disabled={isDisabled}
          type={
            AntdButtonStyleTypes[
              buttonVariant === undefined ? "PRIMARY" : buttonVariant
            ] as "link" | "dashed" | "text" | "default" | "primary" | undefined
          }
          onClick={onClick}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: `${borderRadius}`,
            boxShadow: `${boxShadow ?? "none"}`,
          }}
          icon={
            iconObj.type === "appsmith" ? (
              <IconWithPadding
                iconName={iconObj.iconName}
                dimension={dimension as number}
                padding="40px"
              />
            ) : (
              <AntdIcon
                type={iconObj.iconName ?? ""}
                style={{ fontSize: `${dimension}px` }}
              />
            )
          }
        ></AntdButton>
      </Popover>
    </ConfigProvider>
  );

  return iconBtnWrapper;
}

export default IconButtonComponent;
