import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import { ValidationTypes } from "constants/WidgetValidation";
import ScrollBoardComponent from "../component";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import IconSVG from "../icon.svg";

class ScrollBoardWidget extends BaseWidget<
  ScrollBoardWidgetProps,
  WidgetState
> {
  static type = "SCROLLBOARD_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "轮播表", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "ScrollBoard",
      rows: 25,
      columns: 15,
      version: 1,
      // config
      header: ["列1", "列2", "列3"],
      data: [
        ['<span style="color:#37a2da;">行1列1</span>', "行1列2", "行1列3"],
        ["行2列1", '<span style="color:#32c5e9;">行2列2</span>', "行2列3"],
        ["行3列1", "行3列2", '<span style="color:#67e0e3;">行3列3</span>'],
        ["行4列1", '<span style="color:#9fe6b8;">行4列2</span>', "行4列3"],
        ['<span style="color:#ffdb5c;">行5列1</span>', "行5列2", "行5列3"],
        ["行6列1", '<span style="color:#ff9f7f;">行6列2</span>', "行6列3"],
        ["行7列1", "行7列2", '<span style="color:#fb7293;">行7列3</span>'],
        ["行8列1", '<span style="color:#e062ae;">行8列2</span>', "行8列3"],
        ['<span style="color:#e690d1;">行9列1</span>', "行9列2", "行9列3"],
        ["行10列1", '<span style="color:#e7bcf3;">行10列2</span>', "行10列3"],
      ],
      index: true,
      columnWidth: [50],
      align: ["center"],
      rowNum: 5,
      headerBGC: "#00BAFF",
      oddRowBGC: "#003B51",
      evenRowBGC: "#0A2732",
      waitTime: 2000,
      headerHeight: 35,
      // columnWidth: [],
      // align: [],
      indexHeader: "#",
      carousel: "single",
      hoverPause: true,
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig(): any[] {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "表数据",
            propertyName: "data",
            label: "表数据",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.ARRAY,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
          {
            helpText: "表头数据",
            propertyName: "header",
            label: "表头",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.TEXT,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "表行数",
            propertyName: "rowNum",
            label: "表行数",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入表行数",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "轮播时间间隔(ms)",
            propertyName: "waitTime",
            label: "轮播时间间隔(ms)",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入轮播时间间隔(ms)",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "轮播方式",
            propertyName: "carousel",
            label: "轮播方式",
            placeholderText: "轮播方式",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                label: "单条",
                value: "single",
              },
              {
                label: "整页",
                value: "page",
              },
            ],
          },
          {
            helpText: "显示行号",
            propertyName: "index",
            label: "显示行号",
            controlType: "SWITCH",
            placeholderText: "是否显示行号",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "行号表头",
            propertyName: "indexHeader",
            label: "行号表头",
            controlType: "INPUT_TEXT",
            placeholderText: "行号表头",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "表头背景色",
            propertyName: "headerBGC",
            label: "表头背景色",
            controlType: "COLOR_PICKER",
            placeholderText: "表头背景色",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          // 不知道是不是插件问题 oddRowBGC和evenRowBGC反了
          {
            helpText: "奇数行背景色",
            propertyName: "evenRowBGC",
            label: "奇数行背景色",
            controlType: "COLOR_PICKER",
            placeholderText: "奇数行背景色",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            helpText: "偶数行背景色",
            propertyName: "oddRowBGC",
            label: "偶数行背景色",
            controlType: "COLOR_PICKER",
            placeholderText: "偶数行背景色",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            helpText: "表头高度",
            propertyName: "headerHeight",
            label: "表头高度",
            controlType: "INPUT_TEXT",
            placeholderText: "偶数行背景色",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          // {
          //   helpText: "列宽度",
          //   propertyName: "columnWidth",
          //   label: "列宽度",
          //   controlType: "INPUT_TEXT",
          //   placeholderText: "列宽度",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.NUMBER },
          // },
          {
            helpText: "悬浮暂停轮播	",
            propertyName: "hoverPause",
            label: "悬浮暂停轮播	",
            controlType: "SWITCH",
            placeholderText: "列宽度",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const {
      carousel,
      data,
      evenRowBGC,
      header,
      headerBGC,
      headerHeight,
      hoverPause,
      index,
      indexHeader,
      oddRowBGC,
      rowNum,
      waitTime,
    } = this.props;

    const childProps = {
      data,
      header,
      rowNum,
      headerBGC,
      oddRowBGC,
      evenRowBGC,
      waitTime,
      headerHeight,
      index,
      indexHeader,
      carousel,
      hoverPause,
    };

    return <ScrollBoardComponent {...childProps} />;
  }
}

export interface ScrollBoardWidgetProps extends WidgetProps {
  header: any;
  data: any;
  rowNum: number;
  headerBGC: string;
  oddRowBGC: string;
  evenRowBGC: string;
  waitTime: number;
  headerHeight: number;
  index?: boolean;
  indexHeader?: string;
  carousel: string;
  hoverPause: boolean;
}

export default ScrollBoardWidget;
