import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
// import { Decoration9 } from "@jiaminghi/data-view-react";
import fastdom from "fastdom";
import * as _ from "lodash";
import { generateClassName, getCanvasClassName } from "utils/generators";
import WidgetStyleContainer, {
  WidgetStyleContainerProps,
} from "components/designSystems/appsmith/WidgetStyleContainer";
import { WidgetType } from "utils/WidgetFactory";
import { scrollCSS } from "widgets/WidgetUtils";
import {
  getContainerTheme,
  getHeaderFooterStyle,
  THEME_SETS,
} from "../constants";

const StyledContainerComponent = styled.div<
  Omit<ContainerWrapperProps, "widgetId">
>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  ${(props) => (!!props.dropDisabled ? `position: relative;` : ``)}

  ${(props) =>
    props.shouldScrollContents && !props.$noScroll ? scrollCSS : ``}
  opacity: ${(props) => (props.resizeDisabled ? "0.8" : "1")};
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: 100% 100%;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.containerTheme}
`;

const StyledBgContainer = styled.div<any>`
  ${(props) => props.bgAttr}
`;

interface ContainerWrapperProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClickCapture?: MouseEventHandler<HTMLDivElement>;
  resizeDisabled?: boolean;
  shouldScrollContents?: boolean;
  backgroundColor?: string;
  backgroundImage?: string;
  widgetId: string;
  type: WidgetType;
  dropDisabled?: boolean;
  $noScroll: boolean;
  containerTheme?: string;
}
function ContainerComponentWrapper(
  props: PropsWithChildren<ContainerWrapperProps>,
) {
  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!props.shouldScrollContents) {
      const supportsNativeSmoothScroll =
        "scrollBehavior" in document.documentElement.style;

      fastdom.mutate(() => {
        if (supportsNativeSmoothScroll) {
          containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          if (containerRef.current) {
            containerRef.current.scrollTop = 0;
          }
        }
      });
    }
  }, [props.shouldScrollContents]);

  /**
   * This is for all the container widgets that have the onClickCapture method.
   * The mouse over event makes sure to add the class `hover-styles` so that a
   * darker shade of the background color takes effect to induce the hover effect.
   *
   * Why not use the :hover css selector?
   * For cases like List widget, it can have inner list widgets; so there can be
   * containers inside containers. When the inner container is hovered, the parent container's
   * :hover selector is also triggered making the outer and inner container both having this
   * hover effect.
   */
  const onMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const el = e.currentTarget;
      const widgetType = el.getAttribute("type");
      const widgetId = el.dataset.widgetid;
      const isMainContainer = widgetId === "0";

      if (
        (widgetType === "CONTAINER_WIDGET" && props.onClickCapture) ||
        isMainContainer
      ) {
        const elementsHovered = document.getElementsByClassName(
          "hover-styles",
        ) as HTMLCollectionOf<HTMLDivElement>;

        fastdom.mutate(() => {
          for (const elHovered of elementsHovered) {
            elHovered.classList.remove("hover-styles");
          }

          if (!isMainContainer) {
            el.classList.add("hover-styles");
          }
        });
      }
    },
    [props.onClickCapture],
  );
  // const theme = getContainerTheme(props.containerTheme)?.bgStr;
  // const bgAttrs = getContainerTheme(props.containerTheme)?.bgAttr || [];

  const headerComponent = () => {
    const headerS = props.containerTheme
      ? getHeaderFooterStyle(props.containerTheme)?.header
      : {};
    const isWholeBg = _.get(
      THEME_SETS.find((t) => t.value === props.containerTheme),
      "isWholeBg",
      false,
    );

    return (
      <div className={`absolute top-0 w-full ${isWholeBg ? "h-full" : "h-10"}`}>
        {headerS?.left && (
          <img
            className={headerS?.left?.className}
            src={headerS?.left?.bgUrl}
          />
        )}
        {headerS.center && (
          <img
            className={headerS?.center?.className}
            src={headerS?.center?.bgUrl}
          />
        )}
        {headerS.right && (
          <img
            className={headerS?.right?.className}
            src={headerS?.right?.bgUrl}
          />
        )}
      </div>
    );
  };
  return (
    <StyledContainerComponent
      // Before you remove: generateClassName is used for bounding the resizables within this canvas
      // getCanvasClassName is used to add a scrollable parent.
      $noScroll={props.$noScroll}
      backgroundColor={props.backgroundColor}
      backgroundImage={props.backgroundImage}
      className={`${
        props.shouldScrollContents ? getCanvasClassName() : ""
      } ${generateClassName(props.widgetId)} container-with-scrollbar relative`}
      data-widgetId={props.widgetId}
      dropDisabled={props.dropDisabled}
      onClick={props.onClick}
      onClickCapture={props.onClickCapture}
      onMouseOver={onMouseOver}
      ref={containerRef}
      resizeDisabled={props.resizeDisabled}
      shouldScrollContents={!!props.shouldScrollContents}
      tabIndex={props.shouldScrollContents ? undefined : 0}
      type={props.type}
      // containerTheme={theme}
    >
      {headerComponent()}
      {props.children}
    </StyledContainerComponent>
  );
}

function ContainerComponent(props: ContainerComponentProps) {
  const [, setSymbol] = useState<any>();
  useEffect(() => {
    setSymbol(Symbol("container"));
  }, [props.isVideo, props.containerTheme]);
  if (props.detachFromLayout) {
    return (
      <div className="relative overflow-hidden">
        {props.isVideo && props.video ? (
          <ReactPlayer
            className="absolute h-full w-full scale-[1.5] top-0 left-0"
            config={{
              file: {
                attributes: {
                  controlsList:
                    "nofullscreen nodownload noremoteplayback noplaybackrate",
                },
              },
            }}
            controls={false}
            height="100%"
            pip={false}
            loop
            playing
            ref={props.player}
            url={
              "https://app.fusiontech.cn/ot180uzvlb_1650917140489_r9761ae1ud.mp4"
            }
            width="100%"
          />
        ) : null}
        <ContainerComponentWrapper
          $noScroll={!!props.noScroll}
          dropDisabled={props.dropDisabled}
          onClick={props.onClick}
          onClickCapture={props.onClickCapture}
          resizeDisabled={props.resizeDisabled}
          shouldScrollContents={props.shouldScrollContents}
          type={props.type}
          widgetId={props.widgetId}
        >
          {props.children}
        </ContainerComponentWrapper>
      </div>
    );
  }
  return (
    <WidgetStyleContainer
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      boxShadow={props.boxShadow}
      className="style-container relative overflow-hidden"
      containerStyle={props.containerStyle}
      selected={props.selected}
      widgetId={props.widgetId}
    >
      {props.isVideo && props.video ? (
        <ReactPlayer
          className="absolute h-full w-full scale-[1.5] top-0 left-0"
          config={{
            file: {
              attributes: {
                controlsList:
                  "nofullscreen nodownload noremoteplayback noplaybackrate",
              },
            },
          }}
          controls={false}
          height="100%"
          pip={false}
          loop
          playing
          ref={props.player}
          url={
            "https://app.fusiontech.cn/ot180uzvlb_1650917140489_r9761ae1ud.mp4"
          }
          width="100%"
        />
      ) : null}
      <ContainerComponentWrapper
        containerTheme={props.containerTheme}
        $noScroll={!!props.noScroll}
        backgroundColor={props.backgroundColor}
        backgroundImage={props.backgroundImage}
        dropDisabled={props.dropDisabled}
        onClick={props.onClick}
        onClickCapture={props.onClickCapture}
        resizeDisabled={props.resizeDisabled}
        shouldScrollContents={props.shouldScrollContents}
        type={props.type}
        widgetId={props.widgetId}
      >
        {props.children}
      </ContainerComponentWrapper>
    </WidgetStyleContainer>
  );
}

export type ContainerStyle = "border" | "card" | "rounded-border" | "none";

export interface ContainerComponentProps extends WidgetStyleContainerProps {
  children?: ReactNode;
  backgroundImage?: string;
  shouldScrollContents?: boolean;
  resizeDisabled?: boolean;
  selected?: boolean;
  focused?: boolean;
  detachFromLayout?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClickCapture?: MouseEventHandler<HTMLDivElement>;
  backgroundColor?: string;
  containerTheme?: string;
  type: WidgetType;
  noScroll?: boolean;
  dropDisabled?: boolean;
  // 视频相关props
  video?: string;
  isVideo?: boolean;
}

export default ContainerComponent;
