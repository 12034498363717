import React from "react";
import { ScrollRankingBoard, DigitalFlop } from "@jiaminghi/data-view-react";

function ScrollRankingBoardComponent(props: ScrollRankingBoardComponentProps) {
  const { data, rowNum, waitTime, carousel, unit, sort } = props;
  const config = {
    data,
    rowNum,
    waitTime,
    carousel,
    unit,
    sort,
  };
  return (
    <ScrollRankingBoard
      config={config}
      style={{ width: "100%", height: "90%" }}
    />
  );
}

export interface ScrollRankingBoardComponentProps {
  data: any;
  rowNum: number;
  waitTime: number;
  carousel: string;
  unit: string;
  sort: boolean;
}

export default ScrollRankingBoardComponent;
