import * as _ from "lodash";
import CompBg1Svg from "assets/images/screen/combg1.svg";
import CompBg2Svg from "assets/images/screen/combg2.svg";
import CompBg3Svg from "assets/images/screen/combg3.svg";
import CompBg4Svg from "assets/images/screen/combg4.svg";
import CompBg5Svg from "assets/images/screen/combg5.svg";
import CompBg6Svg from "assets/images/screen/combg6.svg";
import CompBg7Svg from "assets/images/screen/combg7.svg";
import CompBg8Svg from "assets/images/screen/titlebg1.svg";

export const THEME_SETS = [
  {
    value: "set1",
    url: "https://s3.bmp.ovh/imgs/2023/06/21/f29e6e99d84be5f6.png",
  },
  {
    value: "set2",
    url: "https://s3.bmp.ovh/imgs/2023/06/21/12cfa5f6668d8c8b.png",
  },
  {
    value: "set3",
    url: "https://s3.bmp.ovh/imgs/2023/06/21/f5f1b6164d4b3763.png",
  },
  {
    value: "set4",
    url: "https://s3.bmp.ovh/imgs/2023/06/19/107fde55dfa236c9.png",
  },
  {
    value: "set6",
    url: CompBg1Svg,
    isWholeBg: true,
  },
  { value: "setp2", url: CompBg2Svg, isWholeBg: true },
  { value: "setp3", url: CompBg3Svg, isWholeBg: true },
  { value: "setp4", url: CompBg4Svg, isWholeBg: true },
  { value: "setp5", url: CompBg5Svg, isWholeBg: true },
  { value: "setp6", url: CompBg6Svg, isWholeBg: true },
  { value: "setp7", url: CompBg7Svg, isWholeBg: true },
  { value: "setp8", url: CompBg8Svg },
];
const SET_THEMES = {};
THEME_SETS.map((it) => {
  const footerContainerStyle: any = {
    width: "100%",
    height: "10%",
    backgroundColor: "rgb(4, 4, 6)",
    position: "absolute",
    bottom: "0px",
    backgroundRepeat: "no-repeat",
  };
  let leftStyle: any;
  let rightStyle: any;
  if (it.value === "set1") {
    leftStyle = {
      background: `url(https://s3.bmp.ovh/imgs/2023/06/16/9d749c27e500718a.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "100%",
      width: "30%",
      float: "left",
    };
    rightStyle = {
      background: `url(https://s3.bmp.ovh/imgs/2023/06/16/613656954ab7fd0c.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "100%",
      width: "30%",
      float: "right",
    };
  } else if (it.value === "set4") {
    footerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/107fde55dfa236c9.png)`;
    footerContainerStyle.backgroundPosition = "center";
    footerContainerStyle.backgroundSize = "60% 70%";
    footerContainerStyle.transform = `rotate(0deg) scaleY(-1)`;
  } else if (it.value === "set5") {
    footerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/6580995b20ef2a6a.gif)`;
    footerContainerStyle.backgroundSize = "60% 25px";
    footerContainerStyle.backgroundPosition = "center";
  }
  _.set(
    SET_THEMES,
    [it.value, "footer", "containerStyle"],
    footerContainerStyle,
  );
  _.set(SET_THEMES, [it.value, "footer", "leftStyle"], leftStyle);
  _.set(SET_THEMES, [it.value, "footer", "rightStyle"], rightStyle);

  const headerContainerStyle: any = {
    width: "100%",
    height: "80px",
    // border: "1px solid green",
    backgroundColor: "rgb(4, 4, 6)",
    backgroundImage: `url('https://s3.bmp.ovh/imgs/2023/06/16/dac2354261be8768.webp')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  };
  let leftHeader: any;
  let rightHeader: any;
  if (it.value === "set2") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/16/a472d72c338df51e.png)`;
  }
  if (it.value === "set3") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/4dda0c9456b5b613.png)`;
    headerContainerStyle.height = "30%";
    headerContainerStyle.position = "relative";
    leftHeader = {
      position: "absolute",
      left: "0",
      top: "12%",
      width: "33%",
      height: "auto",
      dataSrc: "https://s3.bmp.ovh/imgs/2023/06/19/1d0673bb1dd2e174.gif",
    };
    rightHeader = {
      position: "absolute",
      right: "0",
      width: "25%",
      top: "9%",
      height: "auto",
      dataSrc: "https://s3.bmp.ovh/imgs/2023/06/19/1d0673bb1dd2e174.gif",
    };
  }
  if (it.value === "set4") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/107fde55dfa236c9.png)`;
  }
  if (it.value === "set5") {
    headerContainerStyle.height = "14%";
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/6580995b20ef2a6a.gif)`;
    headerContainerStyle.backgroundSize = "40% auto";
    headerContainerStyle.backgroundPosition = "center";
  }
  _.set(
    SET_THEMES,
    [it.value, "header", "containerStyle"],
    headerContainerStyle,
  );
  _.set(SET_THEMES, [it.value, "header", "leftStyle"], leftHeader);
  _.set(SET_THEMES, [it.value, "header", "rightStyle"], rightHeader);
});

export const getContainerTheme = (theme?: string) => {
  const result: any = {};
  result.bgAttr = [];
  switch (theme) {
    case "set4":
      result.bgAttr.push(`
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgb(255, 255, 255) 47%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.1;
      position: absolute;
      background-position: center;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 2.2rem;
      `);
      result.bgAttr.push(`
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgb(255, 255, 255) 47%, rgba(255, 255, 255, 0) 100%);
        opacity: 1;
        position: absolute;
        top: 2.5rem;
        left: 0px;
        width: 100%;
        height: 1px;
        `);
      // 白底+白横线
      result.header = {
        left: {},
        center: {
          bgUrl: "https://s3.bmp.ovh/imgs/2023/06/21/f5f1b6164d4b3763.png",
          className: "h-10 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      break;
    case "set3":
    case "set5":
    default:
      result.bgStr = `
        background-image:url(https://s3.bmp.ovh/imgs/2023/06/21/f5f1b6164d4b3763.png);
        background-repeat: no-repeat;
        background-size: 100% 2.2rem;
        background-position: center top;
      `;
      result.bgAttr?.push(`
        background-image: linear-gradient(
          0deg,
          rgb(255, 255, 255) 0%,
          rgba(96, 201, 201, 0.7) 8%,
          rgba(85, 93, 97, 0.53) 99%
        );
        opacity: 0.15;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      `);
      result.header = {
        left: {},
        center: {
          bgUrl: "https://s3.bmp.ovh/imgs/2023/06/21/f5f1b6164d4b3763.png",
          className: "h-10 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      break;
    case "set2":
      result.bgStr = `
        background-image:url(https://s3.bmp.ovh/imgs/2023/06/21/12cfa5f6668d8c8b.png);
        background-repeat: no-repeat;
        background-size: 100% 2.2rem;
        background-position: center top;
      `;
      result.header = {
        left: {},
        center: {
          bgUrl: "https://s3.bmp.ovh/imgs/2023/06/21/12cfa5f6668d8c8b.png",
          className: "h-10 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      break;
    case "set1":
      result.bgStr = `
        background-image:url(https://s3.bmp.ovh/imgs/2023/06/21/82c8d391984ccd86.png), url(https://s3.bmp.ovh/imgs/2023/06/21/f29e6e99d84be5f6.png);
        background-repeat: no-repeat, no-repeat;
        background-size: 100% 100%, 80% 2.2rem;
        background-position: center center, 15% 10%;
      `;
      result.header = {
        left: {},
        center: {
          bgUrl: "https://s3.bmp.ovh/imgs/2023/06/21/82c8d391984ccd86.png",
          className: "h-10 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      break;
    case "set6":
      result.bgStr = `
      background-image:url(${
        THEME_SETS.find((item: any) => item.value === "set6")?.url
      });
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    `;
      break;
    case "set7":
      const bgurl = THEME_SETS.find((item: any) => item.value === "set7")?.url;
      result.bgAttr?.push(`
        background-image: url(${bgurl});
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        background-size: 100% 100%;
        width: 4rem;
        height: 4rem;
      `);
      result.bgAttr?.push(`
        background-image: url(${bgurl});
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0px;
        right: 0px;
        background-size: 100% 100%;
        width: 4rem;
        height: 4rem;
        transform: rotateY(180deg);
      `);
      result.bgAttr?.push(`
        background-image: url(${bgurl});
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-size: 100% 100%;
        width: 4rem;
        height: 4rem;
        transform: rotate(180deg);
      `);
      result.bgAttr?.push(`
        background-image: url(${bgurl});
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-size: 100% 100%;
        width: 4rem;
        height: 4rem;
        transform: rotateX(180deg);
      `);
      break;
    case "set8":
      result.bgStr = `
      background-color: rgba(26, 228, 255, 0.08);
      border-radius: 4px;
      &::before {
        content: "";
        width: 2px;
        height: 70%;
        background-color: rgb(26, 228, 255);
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
      };
      &::after {
        content: "";
        width: 2px;
        height: 70%;
        background-color: rgba(26, 228, 255, 0.18);
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
      };
    `;
      break;
    case "set9":
      result.bgStr = `
      background-color: rgba(26, 228, 255, 0.08);
      border-radius: 4px;
      &::before {
        content: "";
        width: 3px;
        height: 3px;
        background-color: rgb(26, 228, 255);
        position: absolute;
        bottom: 5px;
        left: 5px;
        border-radius: 100%;
      };
      &::after {
        content: "";
        width: 3px;
        height: 3px;
        background-color: rgb(26, 228, 255);
        position: absolute;
        bottom: 5px;
        right: 5px;
        border-radius: 100%;
      };
    `;
      break;
    case "set10":
      result.bgStr = `
      background-color: rgba(26, 228, 255, 0.08);
      border-radius: 0px;
      &::before {
        content: "";
        width: 2px;
        height: 70%;
        background-color: rgba(26, 228, 255, 0.8);
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
      };
      &::after {
        content: "";
        width: 2px;
        height: 70%;
        background-color: rgba(26, 228, 255, 0.8);
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      };
    `;
      result.bgAttr.push(`
      width: 2px;
      height: 2px;
      background-color: rgb(2, 247, 255);
      position: absolute;
      top: 2px;
      right: 2px;
    `);
      result.bgAttr.push(`
      width: 2px;
      height: 2px;
      background-color: rgb(2, 247, 255);
      position: absolute;
      top: 2px;
      left: 2px;
    `);
      result.bgAttr.push(`
      width: 2px;
      height: 2px;
      background-color: rgb(2, 247, 255);
      position: absolute;
      bottom: 2px;
      left: 2px;
    `);
      result.bgAttr.push(`
      width: 2px;
      height: 2px;
      background-color: rgb(2, 247, 255);
      position: absolute;
      bottom: 2px;
      right: 2px;
    `);
      break;
  }
  return result;
};

export const getHeaderFooterStyle = (theme?: string) => {
  const result: any = {};
  const targetTheme: any = _.find(THEME_SETS, (s) => s.value === theme) || {};
  result.header = {
    left: {},
    center: {
      bgUrl: targetTheme.url,
      className: "h-full w-full",
      bgStyle: ``,
    },
    right: {},
  };
  return result;
};

export default { SET_THEMES, THEME_SETS };
