/* eslint-disable @typescript-eslint/no-restricted-imports */
import * as React from "react";

import BaseControl, { ControlData, ControlProps } from "./BaseControl";
import { ButtonGroup, TooltipComponent } from "design-system-old";
import { boxShadowOptions } from "constants/ThemeConstants";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import styled from "styled-components";
import {
  DSEventDetail,
  DSEventTypes,
  DS_EVENT,
  emitInteractionAnalyticsEvent,
} from "utils/AppsmithUtils";
import { getTextTheme } from "widgets/TextScreenWidget/constants";
export interface BoxShadowOptionsControlProps extends ControlProps {
  propertyValue: string | undefined;
  controlSize?: string;
  optionType?: string;
}

// Text sizes in theming
export const OPTION_SIZES: any = {
  xs: "3",
  sm: "3.5",
  base: "4",
  md: "5",
  lg: "6",
  xl: "7",
  "2xl": "8",
  "3xl": "9",
};

const TextWrapSpan = styled.div<any>`
  ${(props) => props.fontStyle};
`;

const options = Object.keys(boxShadowOptions).map((optionKey) => ({
  icon: (
    <TooltipComponent
      content={optionKey}
      key={optionKey}
      openOnTargetFocus={false}
    >
      <div
        className="flex items-center justify-center w-5 h-5 bg-white"
        style={{ boxShadow: boxShadowOptions[optionKey] }}
      >
        {boxShadowOptions[optionKey] === "none" && (
          <CloseLineIcon className="text-gray-700" />
        )}
      </div>
    </TooltipComponent>
  ),
  value: boxShadowOptions[optionKey],
}));

const optionsValues = new Set(Object.values(boxShadowOptions));

const CardGroup = (props: any) => {
  const { selectTheme, value, options, size, type } = props;
  const _height: any = `h-${OPTION_SIZES[size || "base"]}`;

  if (type === "font") {
    return (
      <div className="flex gap-x-6 gap-y-2 my-2 flex-wrap ">
        {options.map((it: any) => {
          const textwrapTheme = getTextTheme(it.value).wrapStr;
          const textTheme = getTextTheme(it.value).textStr;
          return (
            <TextWrapSpan
              key={it.value}
              onClick={() => selectTheme(it)}
              fontStyle={textwrapTheme}
              className={`w-2/5 text-center cursor-pointer bg-stone-200 border hover:border-teal-500 rounded m-1  ${
                value === it.value ? "border-teal-500" : ""
              }`}
            >
              <TextWrapSpan fontStyle={textTheme} className="bg-stone-200">
                文本主题
              </TextWrapSpan>
              {/* <div className="text-center">风格{it.value}</div> */}
            </TextWrapSpan>
          );
        })}
      </div>
    );
  }
  return (
    <div className="flex gap-x-6 gap-y-2 my-2 flex-wrap ">
      {options.map((it: any) => {
        const style = {
          background: `no-repeat url('${it.url}')`,
          backgroundSize: `${it.isWholeBg ? "100% 100%" : "100% 100%"}`,
        };
        return (
          <div
            key={it.value}
            className="w-[45%] bg-slate-800"
            onClick={() => selectTheme(it)}
          >
            <div
              className={`cursor-pointer border rounded hover:border-teal-500 ${_height} p-2 ${
                value === it.value ? "border-teal-500" : ""
              }`}
              style={style}
            />
            {/* <div className="text-center">风格{it.value}</div> */}
          </div>
        );
      })}
    </div>
  );
};

class BoxShadowOptionsControl extends BaseControl<BoxShadowOptionsControlProps> {
  componentRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.componentRef.current?.addEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  componentWillUnmount() {
    this.componentRef.current?.removeEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  handleAdsEvent = (e: CustomEvent<DSEventDetail>) => {
    if (
      e.detail.component === "ButtonGroup" &&
      e.detail.event === DSEventTypes.KEYPRESS
    ) {
      emitInteractionAnalyticsEvent(this.componentRef.current, {
        key: e.detail.meta.key,
      });
      e.stopPropagation();
    }
  };

  static getControlType() {
    return "IMAGE_CARD_OPTIONS";
  }

  public render() {
    return (
      <CardGroup
        value={this.props.propertyValue}
        size={this.props.controlSize}
        type={this.props.optionType}
        selectTheme={({ value }: any) => {
          this.updateProperty(this.props.propertyName, value);
        }}
        options={this.props.options}
      />
    );
  }

  static canDisplayValueInUI(config: ControlData, value: any): boolean {
    return optionsValues.has(value);
  }
}

export default BoxShadowOptionsControl;
