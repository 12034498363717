import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { Icon } from "@blueprintjs/core";
import styled from "styled-components";
import { LayoutDirection } from "layoutSystems/common/utils/constants";

enum Weeks {
  "天",
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
}

const TextWrap = styled.div<any>`
  color: ${(props) => props.color || "#fff"};
  font-size: ${(props) => props.fontSize};
`;

function RealtimeClockComponent(props: RealtimeClockComponentProps) {
  const [current, setCurrent] = useState<any>(new Date());
  const timer = useRef<any>();
  const {
    showDate,
    dateTextSize,
    timeTextSize,
    timeTextColor,
    dateTextColor,
    showTime,
    direction, // 横向
    showClockIcon, // 展示时钟图标
    showWeek,
    align,
    iconColor,
    dateFormatter,
    timeFormatter,
  } = props;
  const setClock = () => {
    timer.current = setInterval(() => {
      setCurrent(new Date());
    }, 1000);
  };

  const cancelClock = () => {
    clearInterval(timer.current);
  };

  useEffect(() => {
    setClock();
    return () => {
      cancelClock();
    };
  }, []);
  // 横向
  const isHorizontal = direction === LayoutDirection.Horizontal;
  let flexalign = "justify-center";
  if (align === "center") {
    flexalign = "justify-center";
  }
  if (align === "left") {
    flexalign = "justify-start";
  }
  if (align === "right") {
    flexalign = "justify-end";
  }
  return (
    <div className={`flex ${flexalign} gap-2`}>
      <div className={`${showClockIcon ? "flex items-center" : "hidden"}`}>
        <Icon
          className={`icon`}
          color={iconColor || "#4B4848"}
          icon="time"
          iconSize={14}
        />
      </div>
      <div
        className={`flex ${isHorizontal ? "flex-row" : "flex-col"} ${
          isHorizontal ? "items-center gap-2 " : "gap-0.5"
        }`}
      >
        {showDate ? (
          <TextWrap color={dateTextColor} fontSize={dateTextSize}>
            {dayjs(current).format(dateFormatter)}
          </TextWrap>
        ) : null}
        {showTime ? (
          <TextWrap color={timeTextColor} fontSize={timeTextSize}>
            {dayjs(current).format(timeFormatter)}
          </TextWrap>
        ) : null}
        {showWeek ? (
          <TextWrap color={timeTextColor} fontSize={timeTextSize}>
            星期{Weeks[dayjs(current).day()]}
          </TextWrap>
        ) : null}
      </div>
    </div>
  );
}

export interface RealtimeClockComponentProps {
  showDate: boolean;
  dateTextSize: string;
  timeTextSize: string;
  timeTextColor: string;
  dateTextColor: string;
  showTime: boolean;
  direction: string; // 横向
  showClockIcon: boolean; // 展示时钟图标
  showWeek: boolean;
  hoursSet: number;
  iconColor: string;
  dateFormatter: string;
  timeFormatter: string;
  align: string;
}

export default RealtimeClockComponent;
