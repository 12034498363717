export enum OverflowTypes {
  SCROLL = "SCROLL",
  TRUNCATE = "TRUNCATE",
  NONE = "NONE",
}

export const FONT_STYLES = [
  {
    value: "set1",
    url: "https://s3.bmp.ovh/imgs/2023/06/16/7853ab29706371af.jpg",
  },
  // {
  //   value: "set2",
  //   url: "https://s3.bmp.ovh/imgs/2023/06/16/b917c81733eee2a3.jpg",
  // },
  {
    value: "set3",
    url: "https://s3.bmp.ovh/imgs/2023/06/16/ac4662d557010c0d.jpg",
  },
  {
    value: "set4",
    url: "https://s3.bmp.ovh/imgs/2023/06/16/f31e65fbbe1d2d3d.jpg",
  },
  {
    value: "set5",
    url: "https://s3.bmp.ovh/imgs/2023/06/16/f31e65fbbe1d2d3d.jpg",
  },
];
interface themeStrProps {
  wrapStr?: string;
  textStr?: string;
}

export const getTextTheme = (theme?: string) => {
  const result: themeStrProps = {};
  switch (theme) {
    case "set1":
    case "set2":
    default:
      result.wrapStr = `
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
        color: transparent;
        background-image: linear-gradient(0deg, rgb(126, 230, 255) 1%, rgb(255, 255, 255) 46%, rgb(255, 255, 255) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`;
      result.textStr = `
        pointer-events: auto;
        display: flex;
        align-items: center;
        cursor: default;
        background-color: transparent;
        border: none;
        border-radius: 0px;
        justify-content: flex-start;
        writing-mode: horizontal-tb;
        letter-spacing: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;`;
      break;
    case "set3":
      result.wrapStr = "";
      result.textStr = "font-family: douyu";
      break;
    case "set4":
      result.wrapStr = "";
      result.textStr = "font-family: guan";
      break;
    case "set5":
      result.wrapStr = "";
      result.textStr = "font-family: pblack";
      break;
  }
  return result;
};
