import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
  Alignment,
  Button,
  Icon,
  Menu,
  MenuItem as BlueprintMenuItem,
  Classes as BlueprintClasses,
} from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import type { IconName } from "@blueprintjs/icons";
import tinycolor from "tinycolor2";

import { darkenActive, darkenHover } from "constants/DefaultTheme";
import type { ButtonPlacement, ButtonVariant } from "components/constants";
import { ButtonVariantTypes } from "components/constants";
import {
  getCustomBackgroundColor,
  getCustomBorderColor,
  getCustomHoverColor,
  getComplementaryGrayscaleColor,
  getCustomJustifyContent,
  getAlignText,
  WidgetContainerDiff,
  lightenColor,
  calculateHoverColor,
} from "widgets/WidgetUtils";
import type { RenderMode } from "constants/WidgetConstants";
import { DragContainer } from "widgets/ButtonWidget/component/DragContainer";
import { THEMEING_TEXT_SIZES } from "constants/ThemeConstants";
import type {
  ConfigureMenuItems,
  MenuButtonComponentProps,
  MenuItem,
  MenuItems,
  MenuItemsSource,
  PopoverContentProps,
} from "../constants";
import type { ThemeProp } from "WidgetProvider/constants";
import { Button as AntdButton, ConfigProvider, Dropdown } from "antd";
import AntdIcon from "components/common/AntdIcon";
import { addOverlayEffect } from "pages/utils";
import { isEmpty } from "lodash";
import _ from "lodash";
import { getIconParams } from "utils/widgetIcon";

const PopoverStyles = createGlobalStyle<{
  parentWidth: number;
  menuDropDownWidth: number;
  id: string;
  borderRadius: string;
}>`
  .menu-button-popover, .${BlueprintClasses.MINIMAL}.menu-button-popover.${
    Classes.POPOVER2
  } {
    background: none;
    box-shadow: 0 6px 20px 0px rgba(0, 0, 0, 0.15) !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    border-radius: ${({ borderRadius }) =>
      borderRadius >= THEMEING_TEXT_SIZES.lg ? `0.375rem` : borderRadius};
    overflow-y: auto;
    max-height: 384px;
  }

  .menu-button-popover .${BlueprintClasses.MENU_ITEM} {
    padding: 9px 12px;
    border-radius: 0;
  }

  .menu-button-popover-target {
    height: 100%;
  }

  ${({ id, menuDropDownWidth, parentWidth }) => `
  .menu-button-width-${id} {

    max-width: ${
      menuDropDownWidth > parentWidth
        ? `${menuDropDownWidth}px`
        : `${parentWidth}px`
    } !important;
    min-width: ${
      parentWidth > menuDropDownWidth ? parentWidth : menuDropDownWidth
    }px !important;
  }
`}
`;
type MenuPlacement =
  | "bottom"
  | "bottomLeft"
  | "bottomRight"
  | "top"
  | "topLeft"
  | "topRight";
export interface BaseStyleProps {
  backgroundColor?: string;
  borderRadius?: string;
  boxShadow?: string;
  buttonColor?: string;
  buttonVariant?: ButtonVariant;
  isCompact?: boolean;
  textColor?: string;
  placement?: ButtonPlacement;
}

const BaseButton = styled(Button)<ThemeProp & BaseStyleProps>`
  height: 100%;
  background-image: none !important;
  font-weight: ${(props) => props.theme.fontWeights[2]};
  outline: none;
  padding: 0px 10px;
  overflow: hidden;
  border: 1.2px solid #ebebeb;
  border-radius: 0;
  box-shadow: none !important;

  ${({ buttonColor, buttonVariant, theme }) => `
      background: ${
        getCustomBackgroundColor(buttonVariant, buttonColor) !== "none"
          ? getCustomBackgroundColor(buttonVariant, buttonColor)
          : buttonVariant === ButtonVariantTypes.PRIMARY
            ? theme.colors.button.primary.primary.bgColor
            : "none"
      } !important;

    &:hover, &:active, &:focus {
      background: ${
        getCustomHoverColor(theme, buttonVariant, buttonColor) !== "none"
          ? getCustomHoverColor(theme, buttonVariant, buttonColor)
          : buttonVariant === ButtonVariantTypes.SECONDARY
            ? theme.colors.button.primary.secondary.hoverColor
            : buttonVariant === ButtonVariantTypes.TERTIARY
              ? theme.colors.button.primary.tertiary.hoverColor
              : theme.colors.button.primary.primary.hoverColor
      } !important;
    }

    &:disabled {
      border: ${
        buttonVariant === ButtonVariantTypes.SECONDARY
          ? "1px solid var(--wds-color-border-disabled)"
          : "none"
      } !important;
      background: ${
        buttonVariant !== ButtonVariantTypes.TERTIARY
          ? "var(--wds-color-bg-disabled)"
          : "transparent"
      } !important;
      color: var(--wds-color-text-disabled) !important;

      span {
        color: var(--wds-color-text-disabled) !important;
      }
    }

    border: ${
      getCustomBorderColor(buttonVariant, buttonColor) !== "none"
        ? `1px solid ${getCustomBorderColor(buttonVariant, buttonColor)}`
        : buttonVariant === ButtonVariantTypes.SECONDARY
          ? `1px solid ${theme.colors.button.primary.secondary.borderColor}`
          : "none"
    } !important;

    & > span {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: normal;

      color: ${
        buttonVariant === ButtonVariantTypes.PRIMARY
          ? getComplementaryGrayscaleColor(buttonColor)
          : getCustomBackgroundColor(
                ButtonVariantTypes.PRIMARY,
                buttonColor,
              ) !== "none"
            ? getCustomBackgroundColor(ButtonVariantTypes.PRIMARY, buttonColor)
            : `${theme.colors.button.primary.secondary.textColor}`
      } !important;
    }
  `}

  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => boxShadow} !important;
  ${({ placement }) =>
    placement
      ? `
      justify-content: ${getCustomJustifyContent(placement)};
      & > span.bp3-button-text {
        flex: unset !important;
      }
    `
      : ""}
`;

const BaseMenuItem = styled(BlueprintMenuItem)<ThemeProp & BaseStyleProps>`
  font-family: var(--wds-font-family);
  display: flex;
  align-items: center;
  ${({ backgroundColor, theme }) =>
    backgroundColor
      ? `
      background-color: ${backgroundColor};
      &:hover {
        background-color: ${darkenHover(backgroundColor)};
      }
      &:active {
        background-color: ${darkenActive(backgroundColor)};
      }
  `
      : `
    background: none !important
      &:hover {
        background-color: ${tinycolor(
          theme.colors.button.primary.primary.textColor,
        )
          .darken()
          .toString()};
      }
      &:active {
        background-color: ${tinycolor(
          theme.colors.button.primary.primary.textColor,
        )
          .darken()
          .toString()};
      }
    `}
  ${({ textColor }) =>
    textColor &&
    `
      color: ${textColor};
  `}
  ${({ isCompact }) =>
    isCompact &&
    `
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      font-size: 12px;import { type } from '../../../selectors/propertyPaneSelectors';

  `}
`;

const StyledMenu = styled(Menu)<{
  backgroundColor?: string;
}>`
  padding: 0;
  min-width: 0px;
  overflow: hidden;

  ${BlueprintClasses.MENU_ITEM}:hover {
    background-color: ${({ backgroundColor }) => lightenColor(backgroundColor)};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  .ant-btn-icon {
    display: inline-block;
    width: 100%;
  }
`;

export interface PopoverTargetButtonProps {
  borderRadius?: string;
  boxShadow?: string;
  buttonColor?: string;
  buttonVariant?: ButtonVariant;
  iconName?: any;
  iconAlign?: Alignment;
  shouldFitContent: boolean;
  isDisabled?: boolean;
  label?: string;
  placement?: ButtonPlacement;
  renderMode?: RenderMode;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
  backgroundColor?: string;
  menuItemsSource: MenuItemsSource;
  configureMenuItems: ConfigureMenuItems;
  sourceData?: Array<Record<string, unknown>>;
  getVisibleItems: () => Array<MenuItem>;
  isCompact?: boolean;
  menuItems: MenuItems;
  onItemClicked: (onClick: string | undefined, index: number) => void;
  menuPlacement?: string;
}

function PopoverTargetButton(props: PopoverTargetButtonProps) {
  const {
    borderRadius,
    boxShadow,
    buttonColor,
    buttonVariant,
    iconAlign,
    iconName,
    isDisabled,
    label,
    maxWidth,
    minHeight,
    minWidth,
    placement,
    renderMode,
    shouldFitContent,
    backgroundColor,
    onItemClicked,
    menuItems,
    menuPlacement,
    getVisibleItems,
  } = props;
  const isRightAlign = iconAlign === Alignment.RIGHT;

  const visibleItems = getVisibleItems();

  const AntdButtonStyleTypes: Record<any, string> = {
    PRIMARY: "primary",
    SECONDARY: "default",
    TERTIARY: "text",
    LINK: "link",
  };

  const iconObj = getIconParams(iconName);
  // iconName === "" || iconName === undefined
  //   ? { iconName: "", type: "antd", category: "tips" }
  //   : _.isString(iconName)
  //     ? JSON.parse(iconName)
  //     : iconName;

  const items = isEmpty(visibleItems)
    ? Object.values(menuItems).map((it) => {
        return {
          key: it.id,
          label: <div>{it.label}</div>,
          backgroundColor: it.backgroundColor,
          disabled: it.isDisabled,
          isVisible: it.isVisible,
          iconName: it.iconName,
          textColor: it.textColor,
          iconColor: it.iconColor,
          iconAlign: it.iconAlign,
          onClick: it.onClick,
          index: it.index,
          hoverBackgroundColor: it.hoverBackgroundColor,
          hoverTextColor: it.hoverTextColor,
        };
      })
    : visibleItems.map((it) => {
        return {
          key: it.id,
          label: <div>{it.label}</div>,
          backgroundColor: it.backgroundColor,
          disabled: it.isDisabled,
          isVisible: it.isVisible,
          iconName: it.iconName,
          textColor: it.textColor,
          iconColor: it.iconColor,
          iconAlign: it.iconAlign,
          onClick: it.onClick,
          index: it.index,
          hoverBackgroundColor: it.hoverBackgroundColor,
          hoverTextColor: it.hoverTextColor,
        };
      });

  const contentStyle: React.CSSProperties = {};

  const darkenBackgroundColor = (color: string, amount: number) => {
    const darken = (c: number) => Math.max(c - amount * 255, 0);
    const colorValue = color.slice(1); // Remove '#'
    const num = parseInt(colorValue, 16);
    const r = darken((num >> 16) & 255);
    const g = darken((num >> 8) & 255);
    const b = darken(num & 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const StyledText = styled.pre<{
    disabled?: boolean;
    iconName?: string;
    iconAlign?: string;
    textColor?: string;
    hoverTextColor?: string;
  }>`
    color: ${({ disabled, textColor }) =>
      disabled ? "rgba(0, 0, 0, 0.25)" : textColor};
    margin-left: ${({ iconName, iconAlign }) =>
      iconName && iconAlign === "left" ? "8px" : 0};
    margin-right: ${({ iconName, iconAlign }) =>
      iconName && iconAlign === "right" ? "8px" : 0};
    &:hover {
      // color: ${(props) => props.hoverTextColor} !important;
    }
  `;

  const CustomMenuItem = styled.div<{
    backgroundColor?: string;
    textColor?: string;
    key: string;
    disabled?: boolean;
    isVisible?: boolean;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
  }>`
    background-color: ${(props) =>
      !props.disabled ? props.backgroundColor : "transparent"};
    padding: 8px 10px !important;
    border-radius: 4px;
    transition: background-color 0.3s;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    &:hover {
      background-color: ${(props) =>
        !props.disabled
          ? (props.hoverBackgroundColor && props.backgroundColor) ||
            props.hoverBackgroundColor
            ? props.hoverBackgroundColor
            : props.backgroundColor
              ? darkenBackgroundColor(props.backgroundColor, 0.1)
              : "rgba(0, 0, 0, 0.04)"
          : "transparent"};
      border-radius: 4px;
      ${StyledText} {
        color: ${(props) =>
          !props.disabled
            ? props.hoverTextColor
            : "rgba(0, 0, 0, 0.25)"} !important;
      }
    }
  `;

  const StyledButtonContent = styled.div<{
    iconAlign: string;
    placement?: ButtonPlacement;
  }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: ${({ placement }) => getCustomJustifyContent(placement)};
    flex-direction: ${({ iconAlign }) =>
      iconAlign === Alignment.RIGHT ? "row-reverse" : "row"};
    padding: 8px;
  `;

  const renderLabelWithIcon = (
    label?: any,
    iconName?: any,
    iconColor?: string,
    iconAlign?: string,
    onClick?: string | undefined,
    key?: string,
    disabled?: boolean,
    textColor?: string,
    index?: number | undefined,
    hoverTextColor?: string,
  ) => {
    const iconObj = getIconParams(iconName);
    // iconName === "" || iconName === undefined
    //   ? { iconName: "", type: "antd", category: "direction" }
    //   : _.isString(iconName)
    //     ? JSON.parse(iconName)
    //     : iconName;
    const iconElement =
      iconObj.type === "appsmith" ? (
        <Icon
          icon={iconObj.iconName}
          style={{
            color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
          }}
        />
      ) : (
        <AntdIcon
          type={iconObj.iconName ?? ""}
          style={{
            color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
            fontSize: "18px",
          }}
        />
      );

    if (iconObj.iconName) {
      return iconAlign === Alignment.RIGHT ? (
        <div
          className="flex items-center justify-between"
          onClick={() => {
            onItemClicked(onClick, index as number);
          }}
        >
          <StyledText
            disabled={disabled}
            iconName={iconObj.iconName}
            iconAlign={iconAlign || "left"}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
            onClick={() => {
              onItemClicked(onClick, index as number);
            }}
          >
            {label}
          </StyledText>
          {iconElement}
        </div>
      ) : (
        <div
          className="flex items-center"
          onClick={() => {
            onItemClicked(onClick, index as number);
          }}
        >
          {iconElement}
          <StyledText
            disabled={disabled}
            iconName={iconObj.iconName}
            iconAlign={iconAlign || "left"}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
          >
            {label}
          </StyledText>
        </div>
      );
    }

    return (
      <div
        onClick={() => {
          onItemClicked(onClick, index as number);
        }}
      >
        <StyledText
          disabled={disabled}
          iconName={iconObj.iconName}
          iconAlign={iconAlign || "left"}
          textColor={textColor}
          hoverTextColor={hoverTextColor}
        >
          {label}
        </StyledText>
      </div>
    );
  };

  return (
    <ButtonContainer>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: buttonColor,
            colorLink: buttonColor,
            colorBgTextActive: addOverlayEffect(
              calculateHoverColor(buttonColor),
              0.5,
            ),
            colorTextLightSolid:
              buttonVariant === ButtonVariantTypes.PRIMARY
                ? getComplementaryGrayscaleColor(buttonColor)
                : addOverlayEffect(calculateHoverColor(buttonColor), 0.5),
            colorText:
              buttonVariant === ButtonVariantTypes.TERTIARY
                ? addOverlayEffect(calculateHoverColor(buttonColor), 0.2)
                : buttonVariant === ButtonVariantTypes.LINK
                  ? buttonColor
                  : "initial",
          },
          components: {
            Button: {
              fontWeight: 600,
              textHoverBg: addOverlayEffect(
                calculateHoverColor(buttonColor),
                0.8,
              ),
              contentFontSize: 15,
            },
          },
        }}
      >
        <Dropdown
          menu={{ items }}
          placement={menuPlacement as MenuPlacement}
          trigger={["click"]}
          // overlayStyle={{ backgroundColor: "#fff" }}
          disabled={isDisabled}
          dropdownRender={(menu) => {
            return (
              <div className="ant-dropdown-menu" style={contentStyle}>
                {items.map((i) => {
                  if (i.isVisible) {
                    return (
                      <CustomMenuItem
                        key={i.key}
                        disabled={i.disabled}
                        backgroundColor={i.backgroundColor}
                        textColor={i.textColor}
                        isVisible={i.isVisible}
                        hoverBackgroundColor={i.hoverBackgroundColor}
                        hoverTextColor={i.hoverTextColor}
                      >
                        {renderLabelWithIcon(
                          i.label,
                          i.iconName,
                          i.iconColor,
                          i.iconAlign,
                          i.onClick,
                          i.key,
                          i.disabled,
                          i.textColor,
                          i.index,
                          i.hoverTextColor,
                        )}
                      </CustomMenuItem>
                    );
                  }
                })}
              </div>
            );
          }}
        >
          <AntdButton
            disabled={isDisabled}
            type={
              AntdButtonStyleTypes[
                buttonVariant === undefined ? "PRIMARY" : buttonVariant
              ] as
                | "link"
                | "dashed"
                | "text"
                | "default"
                | "primary"
                | undefined
            }
            style={{
              width: "100%",
              height: "100%",
              borderRadius: `${borderRadius}`,
              boxShadow: `${boxShadow}`,
            }}
            autoInsertSpace={false}
            icon={
              <StyledButtonContent
                iconAlign={iconAlign || "left"}
                placement={placement}
              >
                <>
                  {/* {iconName && (
                    <AntdIcon style={{ fontSize: "18px" }} type={iconName} />
                  )} */}
                  {iconObj.type === "appsmith" ? (
                    <Icon icon={iconObj.iconName} />
                  ) : (
                    <AntdIcon
                      type={iconObj.iconName ?? ""}
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  )}
                  {!!label && (
                    <pre
                      // onClick={()=> onItemClicked(onClick, index)}
                      style={{
                        marginLeft:
                          iconObj.iconName && (iconAlign || "left") === "left"
                            ? "8px"
                            : 0,
                        marginRight:
                          iconObj.iconName && (iconAlign || "left") === "right"
                            ? "8px"
                            : 0,
                      }}
                    >
                      {label}
                    </pre>
                  )}
                </>
              </StyledButtonContent>
            }
          ></AntdButton>
        </Dropdown>
      </ConfigProvider>
    </ButtonContainer>
  );
}

function MenuButtonComponent(props: MenuButtonComponentProps) {
  const {
    borderRadius,
    boxShadow,
    configureMenuItems,
    getVisibleItems,
    iconAlign,
    iconName,
    isCompact,
    isDisabled,
    label,
    maxWidth,
    menuColor,
    menuDropDownWidth,
    menuItems,
    menuItemsSource,
    menuVariant,
    minHeight,
    minWidth,
    onItemClicked,
    placement,
    renderMode,
    shouldFitContent,
    sourceData,
    widgetId,
    width,
    menuPlacement,
  } = props;

  return (
    <>
      <PopoverTargetButton
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        buttonColor={menuColor}
        buttonVariant={menuVariant}
        iconAlign={iconAlign}
        iconName={iconName}
        isDisabled={isDisabled}
        label={label}
        maxWidth={maxWidth}
        minHeight={minHeight}
        minWidth={minWidth}
        placement={placement}
        renderMode={renderMode}
        shouldFitContent={shouldFitContent}
        backgroundColor={menuColor}
        configureMenuItems={configureMenuItems}
        getVisibleItems={getVisibleItems}
        isCompact={isCompact}
        menuItems={menuItems}
        menuItemsSource={menuItemsSource}
        onItemClicked={onItemClicked}
        sourceData={sourceData}
        menuPlacement={menuPlacement}
      />
    </>
  );
}

export default MenuButtonComponent;
