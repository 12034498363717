import React, { useRef, useEffect } from "react";
import { Tree } from "antd";
import type { TreeProps } from "antd/es/tree";
import type { Alignment } from "@blueprintjs/core";
import type { TextSize } from "constants/WidgetConstants";
import type { TreeOption } from "../widget";
import { TreeContainer } from "./index.style";
import type { LabelPosition } from "components/constants";
import LabelWithTooltip from "widgets/components/LabelWithTooltip";
import { ConfigProvider } from "antd";
import _get from "lodash/get";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";

type Key = string | number;

const { DirectoryTree } = Tree;
const StyledDirectoryTree = styled(DirectoryTree)<{
  primaryColor: string;
  showLine: boolean;
}>`
  ${({ showLine, primaryColor }) =>
    !showLine
      ? `
      .ant-tree.ant-tree-directory >
      .ant-tree-treenode-selected:hover::before,
      .ant-tree-treenode-selected::before {
        background-color: transparent !important;
      }

      .ant-tree-treenode-selected .ant-tree-switcher{
        color: rgba(0,0,0,.88) !important;
      }

      .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: ${tinycolor(primaryColor).lighten(35).toString()} !important;
        color: rgba(0,0,0,.88) !important;
      }
      .ant-tree-node-content-wrapper {
        border-radius: 6px !important;
      } `
      : ``}
`;
function TreeComponent({
  checkable,
  accentColor,
  borderRadius,
  boxShadow,
  blockNode,
  compactMode,
  componentHeight,
  defaultCheckedKeys,
  dynamicHeight,
  // animateLoading,
  expandAll,
  isDisabled,
  showDirectoryIcon,
  isRequired,
  labelAlignment,
  labelPosition,
  labelStyle,
  labelText,
  labelTextColor,
  labelTextSize,
  labelWidth,
  onCheck,
  onExpandHandle,
  onSelectHandle,
  options,
  showColon,
  showLine,
}: TreeComponentProps) {
  const treeRef = useRef(null);
  const selectedTheme = useSelector(getSelectedAppTheme);

  const onCheckHandle: TreeProps["onCheck"] = (checked) => {
    onCheck(checked);
  };

  const primaryColor = _get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const TreeComp = showDirectoryIcon && !showLine ? StyledDirectoryTree : Tree;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryColor,
        },
        components: {
          Tree: {
            // nodeSelectedBg: primaryColor,
          },
        },
      }}
    >
      <TreeContainer
        accentColor={accentColor}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        compactMode={compactMode}
        componentHeight={componentHeight}
        dynamicHeight={dynamicHeight}
        labelPosition={labelPosition}
      >
        {labelText && (
          <LabelWithTooltip
            alignment={labelAlignment}
            className={`tree-select-label`}
            color={labelTextColor}
            compact={compactMode}
            cyHelpTextClassName="tree-select-tooltip"
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            isRequired={isRequired}
            position={labelPosition}
            showColon={showColon}
            text={labelText}
            width={labelWidth}
          />
        )}
        <TreeComp
          primaryColor={primaryColor}
          checkable={checkable}
          showLine={showLine ? { showLeafIcon: true } : false}
          className="my-tree"
          defaultCheckedKeys={defaultCheckedKeys}
          defaultExpandAll={expandAll}
          disabled={isDisabled}
          fieldNames={{ title: "label", key: "value", children: "children" }}
          onCheck={onCheckHandle}
          onExpand={onExpandHandle}
          onSelect={onSelectHandle}
          ref={treeRef}
          treeData={options}
          blockNode={blockNode}
          // {...switchIconProps}
        />
      </TreeContainer>
    </ConfigProvider>
  );
}

export interface TreeComponentProps extends TreeProps {
  options?: TreeOption[];
  defaultCheckedKeys: string[];
  labelText: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  compactMode: boolean;
  labelWidth?: number;
  // animateLoading: boolean;
  isDisabled: boolean;
  isRequired?: boolean;
  borderRadius: string;
  boxShadow?: string;
  accentColor: string;
  expandAll: boolean;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  componentWidth?: number;
  componentHeight: number;
  dynamicHeight: string;
  onCheck: (checked: Key[] | { checked: Key[]; halfChecked: Key[] }) => void;
  onExpandHandle:
    | ((
        expandedKeys: Key[],
        info: { node: any; expanded: boolean; nativeEvent: MouseEvent },
      ) => void)
    | undefined;
  onSelectHandle: (
    selectedKeys: Key[],
    info: {
      selected: boolean;
      selectedNodes: any;
      node: any;
      nativeEvent: MouseEvent;
    },
  ) => void;
  showColon?: boolean;
  showLine?: boolean;
  checkable?: boolean;
  showDirectoryIcon?: boolean;
}

export default TreeComponent;
