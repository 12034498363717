import React from "react";
import { WaterLevelPond } from "@jiaminghi/data-view-react";

function HydrographComponent(props: HydrographComponentProps) {
  const config = {
    data: props.data,
    shape: props.shape,
    colors: props.colors,
    waveNum: props.waveNum,
    waveHeight: props.waveHeight,
    waveOpacity: props.waveOpacity,
    formatter: props.formatter,
  };
  return (
    <WaterLevelPond config={config} style={{ width: "100%", height: "100%" }} />
  );
}

export interface HydrographComponentProps {
  data: any;
  shape: string;
  colors?: string;
  waveNum: number;
  waveHeight: number;
  waveOpacity: number;
  formatter: string;
}

export default HydrographComponent;
