import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";
import { COLOR_SET } from "constants/ThemeConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import CapsuleChartBarComponent from "../component";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";

class CapsuleChartBarWidget extends BaseWidget<
  CapsuleChartBarWidgetProps,
  WidgetState
> {
  static type = "CAPSULECHARTBAR_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "胶囊柱图", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "CapsuleBar",
      rows: 12,
      columns: 12,
      version: 1,
      data: [
        {
          name: "南阳",
          value: 167,
        },
        {
          name: "周口",
          value: 123,
        },
        {
          name: "漯河",
          value: 98,
        },
        {
          name: "郑州",
          value: 75,
        },
        {
          name: "西峡",
          value: 66,
        },
      ],
      colors: "set0",
      unit: "单位",
      showValue: true,
      showXAxisLabel: true,
      fontColor: "#ffffff",
      xAxisFontColor: "#ffffff",
      yAxisFontColor: "#ffffff",
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "图表数据源",
            propertyName: "data",
            label: "数据源",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "value": 123, "name": "value1" }]',
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    required: true,
                    allowedKeys: [
                      {
                        name: "name",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "value",
                        type: ValidationTypes.NUMBER,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                    ],
                  },
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "showValue",
            label: "显示数字",
            helpText: "是否显示数字",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "showXAxisLabel",
            label: "显示横坐标",
            helpText: "是否显示横坐标",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "设置柱图单位",
            propertyName: "unit",
            label: "单位",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入单位的文本内容",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "风格",
            propertyName: "colors",
            label: "风格",
            controlType: "COLOR_SET_PICKER",
            defaultValue: "set0",
            isBindProperty: true,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "坐标轴",
        children: [
          {
            propertyName: "fontSize",
            label: "字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontColor",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "xAxisFontColor",
            label: "X轴字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "yAxisFontColor",
            label: "Y轴字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const { colors, data, showValue, showXAxisLabel, xAxisFontColor, yAxisFontColor, unit, fontColor, fontSize } = this.props;
    const ColorSet = COLOR_SET.find((c) => c.name === colors)?.colors;
    return (
      <CapsuleChartBarComponent
        colors={ColorSet}
        data={data}
        showValue={showValue}
        showXAxisLabel={showXAxisLabel}
        unit={unit}
        fontColor={fontColor}
        fontSize={fontSize}
        xAxisFontColor={xAxisFontColor}
        yAxisFontColor={yAxisFontColor}
      />
    );
  }
}

export interface CapsuleChartBarWidgetProps extends WidgetProps {
  data: any;
  showValue: boolean;
  showXAxisLabel: boolean;
  unit: any;
  colors: any;
  fontColor: string;
  fontSize: string;
  xAxisFontColor: string;
  yAxisFontColor: string
}

export default CapsuleChartBarWidget;
