import { Classes, Switch } from "@blueprintjs/core";
import { LabelPosition, LABEL_DEFAULT_GAP } from "components/constants";
import { BlueprintControlTransform } from "constants/DefaultTheme";
import React from "react";
import styled from "styled-components";
import type { ComponentProps } from "widgets/BaseComponent";
import { AlignWidgetTypes } from "WidgetProvider/constants";
import { Colors } from "constants/Colors";
import { FontStyleTypes } from "constants/WidgetConstants";
import { darkenColor } from "widgets/WidgetUtils";
import { ConfigProvider, Switch as AntdSwitch, Tooltip } from "antd";
import AntdIcon from "components/common/AntdIcon";
import { StyledRequeriedIcon } from "widgets/components/LabelWithTooltip";

export interface AntdSwitchComponentProps extends ComponentProps {
  label: string;
  isSwitchedOn: boolean;
  onChange: (isSwitchedOn: boolean) => void;
  isLoading: boolean;
  alignWidget: AlignWidgetTypes;
  labelPosition: LabelPosition;
  accentColor: string;
  inputRef?: (ref: HTMLInputElement | null) => any;
  labelTextColor?: string;
  labelTextSize?: string;
  labelStyle?: string;
  isDynamicHeightEnabled?: boolean;
  minHeight?: number;
  isLabelInline?: boolean;
  switchSize: "default" | "small";
  labelTooltip?: string;
  isRequired?: boolean;
}

const SwitchLabel = styled.div<{
  disabled?: boolean;
  alignment: AlignWidgetTypes;
  labelTextColor?: string;
  labelTextSize?: string;
  labelStyle?: string;
  isDynamicHeightEnabled?: boolean;
  isLabelInline?: boolean;
  showColon?: boolean;
  labelPosition?: string;
}>`
  width: 100%;
  display: inline-block;
  text-align: ${({ alignment }) => alignment.toLowerCase()};
  ${({ disabled, labelStyle, labelTextColor, labelTextSize }) => `
  color: ${disabled ? Colors.GREY_8 : labelTextColor || "inherit"};
  font-size: ${labelTextSize ?? "inherit"};
  font-weight: ${labelStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "normal"};
  font-style: ${
    labelStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : "normal"
  };
  `}
  margin-left: ${({ labelPosition }) => (labelPosition !== "Left" ? "8px" : 0)};
  margin-right: ${({ labelPosition }) =>
    labelPosition !== "Right" ? "8px" : 0};
  ${({ isDynamicHeightEnabled }) =>
    isDynamicHeightEnabled ? "&& { word-break: break-all; }" : ""};

  ${({ isLabelInline }) =>
    isLabelInline &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `}
  ${({ isLabelInline, showColon }) =>
    showColon &&
    `&::after {
      content:":";
      margin-inline-start: 2px;
      margin-inline-end: 8px;
      margin-bottom: ${!isLabelInline ? LABEL_DEFAULT_GAP : 0};
    }`}
`;

export const AntdStyledSwitch = styled(AntdSwitch)<{
  $accentColor: string;
  inline?: boolean;
}>`
  &.${Classes.CONTROL} {
    & input:checked ~ .${Classes.CONTROL_INDICATOR} {
      background: ${({ $accentColor }) => `${$accentColor}`} !important;
      border: 1px solid ${({ $accentColor }) => `${$accentColor}`} !important;
    }
    margin: 0px;

    &:hover input:checked:not(:disabled) ~ .bp3-control-indicator,
    input:checked:not(:disabled):focus ~ .bp3-control-indicator {
      background: ${({ $accentColor }) =>
        `${darkenColor($accentColor)}`} !important;
      border: 1px solid ${({ $accentColor }) => `${darkenColor($accentColor)}`} !important;
    }
  }

  &.${Classes.SWITCH} {
    ${({ inline }) => (!!inline ? "" : "width: 100%;")}
    & input:not(:disabled):active:checked ~ .${Classes.CONTROL_INDICATOR} {
      background: ${({ $accentColor }) => `${$accentColor}`} !important;
    }
  }
`;

export const SwitchContainer = styled.div<{
  valid?: boolean;
  isSwitchedOn?: boolean;
}>`
  border: 1px solid transparent;
  ${({ theme, valid, isSwitchedOn }) =>
    !isSwitchedOn &&
    valid &&
    `
    border: 1px solid ${theme.colors.error};
  `}
`;

function AntdSwitchComponent({
  accentColor,
  alignWidget = AlignWidgetTypes.LEFT,
  inputRef,
  isDisabled,
  isDynamicHeightEnabled,
  isLabelInline,
  isLoading,
  isSwitchedOn,
  label,
  labelPosition,
  labelStyle,
  labelTextColor,
  labelTextSize,
  minHeight,
  onChange,
  showColon,
  switchSize,
  labelTooltip,
  isRequired,
}: AntdSwitchComponentProps): JSX.Element {
  const switchAlignClass =
    labelPosition === LabelPosition.Right ? "left" : "right";

  const SwitchLabelContent = () => (
    <SwitchLabel
      alignment={alignWidget}
      // className="t--switch-widget-label"
      disabled={isDisabled}
      isDynamicHeightEnabled={isDynamicHeightEnabled}
      isLabelInline={isLabelInline}
      labelStyle={labelStyle}
      labelTextColor={labelTextColor}
      labelTextSize={labelTextSize}
      showColon={showColon}
      labelPosition={labelPosition}
    >
      {isRequired && (
        <StyledRequeriedIcon compact={false}>*</StyledRequeriedIcon>
      )}
      <span style={{ verticalAlign: "middle" }}>{label}</span>
      {labelTooltip && (
        <Tooltip placement="top" title={labelTooltip}>
          <AntdIcon
            type={"icon-question-circle"}
            style={{
              cursor: "help",
              marginLeft: "4px",
              fontSize: "16px",
              verticalAlign: "sub",
            }}
          />
        </Tooltip>
      )}
    </SwitchLabel>
  );
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: accentColor,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {switchAlignClass === "left" ? (
          <>
            <SwitchContainer valid={isRequired} isSwitchedOn={isSwitchedOn}>
              <AntdStyledSwitch
                $accentColor={accentColor}
                checked={isSwitchedOn}
                size={switchSize}
                className={
                  isLoading
                    ? `${Classes.SKELETON} t--switch-widget-loading`
                    : `${
                        isSwitchedOn
                          ? "t--switch-widget-active"
                          : "t--switch-widget-inactive"
                      }`
                }
                disabled={isDisabled}
                onChange={() => onChange(!isSwitchedOn)}
              />
            </SwitchContainer>

            <SwitchLabelContent />
          </>
        ) : (
          <>
            <SwitchLabelContent />
            <SwitchContainer valid={isRequired} isSwitchedOn={isSwitchedOn}>
              <AntdStyledSwitch
                $accentColor={accentColor}
                checked={isSwitchedOn}
                size={switchSize}
                className={
                  isLoading
                    ? `${Classes.SKELETON} t--switch-widget-loading`
                    : `${
                        isSwitchedOn
                          ? "t--switch-widget-active"
                          : "t--switch-widget-inactive"
                      }`
                }
                disabled={isDisabled}
                onChange={() => onChange(!isSwitchedOn)}
              />
            </SwitchContainer>
          </>
        )}
      </div>
    </ConfigProvider>
  );
}

export default AntdSwitchComponent;
