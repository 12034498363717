import React from "react";
import { Avatar } from "antd";
import styled from "styled-components";

import { isEmptyString } from "./utils";
import { Icon } from "@blueprintjs/core";
import { FontStyleTypes } from "constants/WidgetConstants";
import _ from "lodash";
import AntdIcon from "components/common/AntdIcon";
import { getIconParams } from "utils/widgetIcon";

type RowDirection = "Left" | "Right" | undefined;

const Wrapper = styled.div<{
  textPosition?: RowDirection;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 7px;
  flex-direction: ${({ textPosition }) =>
    textPosition === "Right" ? "row" : "row-reverse"};
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div<{
  textAlignment?: RowDirection;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ textAlignment }) =>
    textAlignment === "Right" ? "flex-end" : "flex-start"};
`;

const LabelWrapper = styled.div<{
  labelVisible?: boolean;
  labelTextColor?: string;
  labelFontStyle?: string;
}>`
  display: ${({ labelVisible }) => (labelVisible ? "block" : "none")};
  font-weight: 700;
  font-style: ${({ labelFontStyle }) =>
    labelFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""};
  font-weight: ${({ labelFontStyle }) =>
    labelFontStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "normal"};
  color: ${({ labelTextColor }) => labelTextColor};
`;

const TitleWrapper = styled.div<{
  titleVisible?: boolean;
  titleTextColor?: string;
  titleFontStyle?: string;
}>`
  display: ${({ titleVisible }) => (titleVisible ? "block" : "none")};
  color: #d4d4d8;
  font-weight: 500;
  font-style: ${({ titleFontStyle }) =>
    titleFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""};
  font-weight: ${({ titleFontStyle }) =>
    titleFontStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "normal"};
  color: ${({ titleTextColor }) => titleTextColor};
`;

function AvatarIcon({ icon, type }: any) {
  if (type === "appsmith") {
    return <Icon icon={icon} />;
  } else {
    return <AntdIcon type={icon ?? ""} style={{ fontSize: `18px` }} />;
  }
}

function AvatarComponent(props: AvatarComponentProps) {
  const {
    avatarType,
    backgroundColor,
    borderRadius,
    boxShadow,
    defaultAvatarUrl,
    icon,
    imageSrc,
    label,
    labelFontStyle,
    labelTextColor,
    labelVisible,
    onAvatarClick,
    onTextClick,
    size,
    textAlignment,
    textPosition,
    title,
    titleFontStyle,
    titleTextColor,
    titleVisible,
  } = props;
  const iconObj = getIconParams(icon);
    // icon === "" || icon === undefined
    //   ? { iconName: "", type: "antd", category: "tips" }
    //   : _.isString(icon)
    //     ? JSON.parse(icon)
    //     : icon;
  return (
    <Wrapper textPosition={textPosition}>
      <Avatar
        onClick={() => onAvatarClick && onAvatarClick()}
        size={
          isEmptyString(size) ? 32 : Number(size) > 300 ? 300 : Number(size)
        }
        src={
          avatarType === "icon" ? (
            <AvatarIcon
              icon={isEmptyString(iconObj.iconName) ? "plus" : iconObj.iconName}
              type={iconObj.type}
            />
          ) : isEmptyString(imageSrc) ? (
            defaultAvatarUrl
          ) : (
            imageSrc
          )
        }
        style={{ borderRadius, boxShadow, backgroundColor }}
      />
      <TextWrapper
        onClick={() => onTextClick && onTextClick()}
        textAlignment={textAlignment}
      >
        <LabelWrapper
          labelFontStyle={labelFontStyle}
          labelTextColor={labelTextColor}
          labelVisible={labelVisible}
        >
          {label}
        </LabelWrapper>
        <TitleWrapper
          titleFontStyle={titleFontStyle}
          titleTextColor={titleTextColor}
          titleVisible={titleVisible}
        >
          {title}
        </TitleWrapper>
      </TextWrapper>
    </Wrapper>
  );
}

export interface AvatarComponentProps {
  imageSrc?: string;
  icon?: string;
  avatarType?: "icon" | "image";
  defaultAvatarUrl?: string;
  labelFontStyle?: string;
  borderRadius?: string;
  boxShadow?: string;
  backgroundColor?: string;
  onAvatarClick?: () => void;
  onTextClick?: () => void;
  size?: number;
  label?: string;
  labelVisible?: boolean;
  title?: string;
  titleVisible?: boolean;
  titleTextColor?: string;
  titleFontStyle?: string;
  labelTextColor?: string;
  textPosition?: "Left" | "Right";
  textAlignment?: "Left" | "Right";
}

export default AvatarComponent;
