const iconType = [
  {
    iconName: "folder-add",
    iconType: "icon-folder-add",
  },
  {
    iconName: "right-square",
    iconType: "icon-right-square",
  },
];

// const ICON_NAMES: string[] = [
//   "icon-folder-add",
//   "icon-right-square",
//   "icon-plus",
//   "icon-reload",
//   "icon-search",
//   "icon-appstore-add",
//   "icon-alert",
//   "icon-caret-up",
//   "icon-caret-right",
//   "icon-caret-left",
//   "icon-caret-down",
//   "icon-check",
//   "icon-folder-open",
//   "icon-snippets",
//   "icon-question-circle",
//   "icon-file-add",
//   "icon-delete",
//   "icon-check-circle",
//   "icon-camera",
//   "icon-arrow-up",
//   "icon-down",
//   "icon-compress",
//   "icon-exclamation-circle",
//   "icon-heart",
//   "icon-plus-square",
//   "icon-woman",
//   "icon-team",
//   "icon-arrow-left",
//   "icon-arrow-right",
//   "icon-branches",
//   "icon-ellipsis",
//   "icon-exclamation",
//   "icon-font-size",
//   "icon-warning",
//   "icon-user-switch",
//   "icon-usergroup-delete",
//   "icon-usergroup-add",
//   "icon-setting",
//   "icon-form",
//   "icon-file-pdf",
//   "icon-minus-circle",
//   "icon-delete",
//   "icon-heart",
//   "icon-plus-circle",
// ];

const ICON_NAMES = {
  direction: [
    {
      iconName: "NONE",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-step-backward1",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-step-forward",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-fast-backward",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-fast-forward",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-shrink",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-arrows-alt",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-up",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-down",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-caret-up",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-caret-down",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-caret-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-caret-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-up-circle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-down-circle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-left-circle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-right-circle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-double-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-double-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-vertical-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-vertical-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-vertical-align-top",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-vertical-align-middle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-vertical-align-bottom",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-forward",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-backward",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-rollback",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-enter",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-retweet",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-swap",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-swap-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-swap-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-arrow-up",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-arrow-down",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-arrow-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-arrow-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-play-circle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-up-square",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-down-square1",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-left-square",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-right-square",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-login",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-logout",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-menu-fold",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-menu-unfold",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-bottom",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-horizontal",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-inner",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-outer",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-top",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-border-verticle",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-pic-center",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-pic-left",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-pic-right",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-radius-bottomleft1",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-radius-bottomright",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-radius-upleft",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-radius-upright",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-fullscreen-exit1",
      type: "antd",
      category: "direction",
    },
    {
      iconName: "icon-fullscreen",
      type: "antd",
      category: "direction",
    },
  ],
  tips: [
    {
      iconName: "NONE",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-question",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-plus",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-plus-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-pause",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-pause-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-minus",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-minus-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-plus-square",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-minus-square1",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-info",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-info-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-exclamation",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-exclamation-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-close-outline",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-close-circle1",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-close-square",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-check",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-check-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-check-square1",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-clock-circle",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-warning",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-issues-close",
      type: "antd",
      category: "tips",
    },
    {
      iconName: "icon-stop",
      type: "antd",
      category: "tips",
    },
  ],
  edit: [
    {
      iconName: "NONE",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-edit",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-form",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-copy",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-scissor",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-delete",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-snippets",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-diff",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-highlight",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-align-center",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-align-left",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-align-right",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-bg-colors",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-bold",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-italic",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-underline",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-strikethrough",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-redo",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-undo",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-zoom-in",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-zoom-out",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-font-colors",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-font-size",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-line-height",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-dash",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-small-dash",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-sort-ascending1",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-sort-descending1",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-drag",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-ordered-list",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-unordered-list",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-radius-setting",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-column-width1",
      type: "antd",
      category: "edit",
    },
    {
      iconName: "icon-column-height1",
      type: "antd",
      category: "edit",
    },
  ],
  data: [
    {
      iconName: "NONE",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-area-chart",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-pie-chart",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-bar-chart",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-dot-chart",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-radar-chart",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-heat-map",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-fall",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-rise",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-stock",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-box-plot",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-fund",
      type: "antd",
      category: "data",
    },
    {
      iconName: "icon-sliders",
      type: "antd",
      category: "data",
    },
  ],
  logo: [
    {
      iconName: "NONE",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-android",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-apple",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-windows",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-ie",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-chrome",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-github",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-aliwangwang1",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-dingding",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-weibo-square1",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-weibo-circle",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-taobao-circle",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-html5",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-TwitterOutlined",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-wechat",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-whats-app",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-youtube",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-alipay-circle",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-taobao-outlined",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-DingtalkOutlined",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-skype",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-QqOutlined",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-medium-workmark",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-gitlab",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-medium",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-linkedin",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-google-plus",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-dropbox",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-facebook",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-codepen",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-code-sandbox",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-amazon",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-google",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-codepen-circle1",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-alipay",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-ant-design",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-ant-cloud",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-aliyun",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-zhihu",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-slack",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-slack-square",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-behance",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-behance-square1",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-dribbble",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-yuque",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-alibaba",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-yahoo",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-reddit",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-sketch",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-wechat_work",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-openai-fill",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-discord",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-bilibili-line",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-pinterestpinterest31",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-tiktok",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-spotify",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-twitch",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-linux",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-java",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-socialjavascript",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-outline-python",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-ruby",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-kubernetes",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-docker",
      type: "antd",
      category: "logo",
    },
    {
      iconName: "icon-baidu",
      type: "antd",
      category: "logo",
    },
  ],
  website: [
    {
      iconName: "NONE",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-account-book",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-aim",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-alert",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-apartment",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-api",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-appstore-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-appstore",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-audio",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-audio-muted",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-audit",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-bank",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-barcode",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-bars",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-bell",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-block",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-book",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-border",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-borderless-table",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-branches",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-bug",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-build",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-bulb",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-calculator",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-calendar",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-camera",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-car",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-carry-out",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-ci-circle",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-ci",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-clear",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cloud-download1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cloud",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cloud-server",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cloud-sync",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cloud-upload1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-cluster",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-code",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-coffee",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-comment",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-compass",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-compress",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-console-sql",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-contacts",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-container",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-control",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-copyright",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-credit-card",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-crown",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-customer-service",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-dashboard",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-database",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-delete-column",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-delete-row",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-delivered-procedure",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-deployment-unit",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-desktop",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-disconnect",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-dislike",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-dollar",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-download",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-ellipsis",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-environment",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-euro",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-euro-circle",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-exception",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-expand-alt",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-expand",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-experiment",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-export",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-eye",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-eye-invisible",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-field-binary",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-field-number",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-field-string",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-field-time",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-done",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-excel",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-exclamation",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-gif",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-image",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-jpg",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-markdown1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-pdf",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-ppt",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-protect",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-search",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-sync",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-text",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-unknown",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-word",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-file-zip",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-filter",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-fire",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-flag",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-folder-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-folder",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-folder-view",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-folder-open",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-fork",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-format-painter1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-frown",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-function",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-fund-projection-screen1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-fund-view",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-funnel-plot",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-gateway",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-gif",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-gift",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-global",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-gold",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-group",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-hdd",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-heart",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-history",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-holder",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-home",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-hourglass",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-idcard",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-import1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-inbox",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-insert-row-above1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-insert-row-below1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-insert-row-left",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-insert-row-right",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-insurance",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-interaction",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-key",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-laptop",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-layout",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-like",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-line",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-link",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-loading-3-quarters",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-loading",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-lock",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-mac-command",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-mail",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-man",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-medicine-box",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-meh",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-menu",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-merge-cells",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-git-merge-line",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-message",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-mobile",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-money-collect",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-monitor",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-moon",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-more",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-muted",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-node-collapse",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-node-expand1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-node-index",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-notification",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-number",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-one-to-one",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-paper-clip",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-partition",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-pay-circle",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-percentage",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-phone",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-picture",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-play-square",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-pound-circle",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-poweroff",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-printer",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-product",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-profile",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-project",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-property-safety",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-pull-request",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-pushpin",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-qrcode",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-read",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-reconciliation",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-red-envelope",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-pound",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-reload",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-rest",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-robot",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-rocket",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-rotate-left",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-rotate-right",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-safety-certificate",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-safety",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-save",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-scan",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-schedule",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-search",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-security-scan",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-select",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-send",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-setting",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-shake",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-share-alt",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-shop",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-shopping-cart",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-shopping",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-signature",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-sisternode",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-skin",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-smile",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-solution",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-sound",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-split-cells",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-star",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-subnode",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-sun",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-switcher",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-sync",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-table",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-tablet",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-tag",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-tags",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-team",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-thunderbolt",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-to-top",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-tool",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-trademark-circle1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-trademark",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-transaction",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-translation",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-trophy",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-ungroup",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-unlock",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-truck",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-upload",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-usb",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-user-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-user-delete",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-user",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-user-switch",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-usergroup-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-usergroup-delete",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-verified",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-video-camera-add",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-video-camera1",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-wallet",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-wifi",
      type: "antd",
      category: "website",
    },
    {
      iconName: "icon-woman",
      type: "antd",
      category: "website",
    },
  ],
};

const NONE = "NONE";
// const ANTD_ICON_NAMES = [NONE, ...ICON_NAMES];
const ANTD_ICON_NAMES = { ...ICON_NAMES };
export default ICON_NAMES;
export { NONE, ANTD_ICON_NAMES };
